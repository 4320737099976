import { Button, Image } from "@src/components/atoms";
import { TradeInOptionProps } from "./trade-in-option.props";
import "./trade-in-option.scss";
import clsx from "clsx";
import badgeImage from "../../../shared/assets/img/trade-in-option-badge-image.png";

export const TradeInOption = ({
  hasBadgeImage,
  isActive,
  radioSection,
  radio,
  radioLabel,
  radioDiscription,
  content,
  actionArea,
}: TradeInOptionProps) => {
  return (
    <>
      <div
        className={clsx({
          "trade-in-option": true,
          "trade-in-option--with-badge": hasBadgeImage,
          "trade-in-option--active": isActive,
        })}
      >
        {hasBadgeImage && (
          <div className="trade-in-option__badge-image">
            <Image src={badgeImage} alt="image" aspectRatio="9:16" />
          </div>
        )}
        {radioSection && (
          <>
            {radioSection}
            <div className="trade-in-option__radio">
              {radio && <>{radio}</>}

              <div className="trade-in-option__radio-text">
                <label className="text--cta-1">{radioLabel}</label>
                <p className="text--body-2">{radioDiscription}</p>
              </div>
            </div>
          </>
        )}
        {content && <div className="trade-in-option__content">{content}</div>}
        {actionArea && (
          <div className="trade-in-option__actions">{actionArea}</div>
        )}
      </div>
    </>
  );
};

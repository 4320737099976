import { useTheme } from '@mui/material'
import { Stack, SelectionButton } from '../../../components'
import { Skeleton, Typography } from '@ntpkunity/controls'
import { FC, useEffect, useContext, useState } from 'react'
import { IContractTerm } from "interfaces"
import { AppContext } from '@app/context-provider'
import { usePaymentContext, ActionType, useOrderContext, OrderActionType } from 'pages'
import { updateContractTerm } from 'pages/(order-management)/order/order-context-provider'

export const Term: FC<{ terms: IContractTerm[], setupDataLoading: boolean, setLastSelectedTerm: any }> = ({ terms, setupDataLoading, setLastSelectedTerm }) => {
  const theme = useTheme()
  const { state: { order: orderState }, dispatch: orderDispatch } = useOrderContext()
  const [selectedTerm, setSelectedTerm] = useState(orderState.contract_term)
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders

  useEffect(() => {
      setSelectedTerm(orderState.contract_term)
  }, [orderState.contract_term])

  return (
    <Stack marginTopXs={3} className='scroll-horizontal'>
      <Typography theme={theme} component={'p'} variant='subtitle1' marginBottom={'12px'}>{PLACEHOLDERS.PAYMENT_TERMS_TEXT}</Typography>
      {setupDataLoading ? <Skeleton theme={theme} width={'100%'} height={40} /> :
        <SelectionButton
        buttonItems={terms
          .filter(term => term?.finance_type?.includes(orderState.finance_type))
          .map(term => ({
            title: `${term.term} ${PLACEHOLDERS.MONTHS_TEXT}`,
            bgLight: true,
            isSelected: term.term === selectedTerm,
            onClick: () => {
              setLastSelectedTerm(selectedTerm);
              setSelectedTerm(term.term);
              orderDispatch(updateContractTerm(term.term));
            }
          }))
        }
      />
      }
    </Stack>
  )
}

import * as yup from "yup";

export const basicValidator = yup
  .object({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    // mobile_phone: yup.string().required("Mobile Phone Number is required"),
    ssn: yup
      .string()
      .required("Social Security Number is required")
      .matches(/^\d{9}$/, "SSN must be exactly 9 digits"),
  })
  .required();

import { Collapse, CollapseProps } from "antd";
import clsx from "clsx";
import "../../../styles/components/_accordion.scss";
import { ReactNode } from "react";

export const Accordion = ({
  onChange,
  items = [],
  defaultActiveKey = ["1"],
  bordered = false,
  expandIconPosition = "end",
  size,
  ghost = true,
  accordion,
  collapsible,
  fullWidth = false,
  expandIcon = (panelProps) => (
    <div
      className="bmw-icon bmw-icon--before data-icon"
      data-icon="arrow_chevron_down"
    />
  ),
}: {
  onChange?: (key: string[]) => void;
  items: CollapseProps["items"];
  defaultActiveKey?: string[] | string | number[] | number;
  bordered?: boolean;
  expandIconPosition?: "start" | "end";
  size?: "middle" | "small";
  ghost?: boolean;
  accordion?: boolean;
  expandIcon?: (panelProps) => ReactNode;
  collapsible?: "header" | "icon" | "disabled";
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={clsx({
        "accordion-wrapper": true,
        "accordion--start-expand": expandIconPosition === "start",
        "accordion--full-width": fullWidth === true,
      })}
    >
      <Collapse
        items={items}
        defaultActiveKey={defaultActiveKey}
        onChange={onChange}
        bordered={bordered}
        expandIconPosition={expandIconPosition}
        size={size}
        ghost={ghost}
        accordion={accordion}
        expandIcon={expandIcon}
        collapsible={collapsible}
      />
    </div>
  );
};

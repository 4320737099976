import { useAppContext } from "@app/context-provider";
import { CompletedSteps, StepperProgress } from "@components";
import {OrderStatus, Tabs} from "@constants";
import { IOrderFnI, IOrderOption } from "@interfaces";
import { useTheme } from "@mui/material";
import { Box, Grid, Typography } from "@ntpkunity/controls"
import { useOrderContext } from "@pages";
import { useMemo } from "react";
import {calculateAdjustedTradeInAmount, generateTabOutput} from "../../helpers/methods";

export const Stepper = ({ currency }: { currency: string }) => {
    const theme = useTheme();
    const { state: { order } } = useOrderContext()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders;
    const hideCompletedSteps = [OrderStatus.APPROVED, OrderStatus.DECLINED, OrderStatus.CANCELLED].includes(order?.status as any)

    const generateOrderText = (status) => {
        let steps = [];
        const defaultSteps = [
            {
                title: PLACEHOLDERS.SUBMIT_ORDER_SUBMIT_YOUR_ORDER,
                subtitle: PLACEHOLDERS.SUBMIT_ORDER_CHECK_AND_SUBMIT
            },
            {
                title: PLACEHOLDERS.SUBMIT_ORDER_ORDER_RECEIVED,
                subtitle: PLACEHOLDERS.SUBMIT_ORDER_REVIEW_AND_CONFIRM
            },
            {
                title: PLACEHOLDERS.SUBMIT_ORDER_FINALIZE_PURCHASE,
                subtitle: PLACEHOLDERS.SUBMIT_ORDER_READY_TO_PURCHASE
            },
            {
                title: PLACEHOLDERS.SUBMIT_ORDER_RECEIVE_VEHICLE,
                subtitle: PLACEHOLDERS.SUBMIT_ORDER_PROCESS_PAYMENT_AND_APPOINTMENT
            }
        ];

        switch (status) {
            case OrderStatus.COMPLETED:
                steps = defaultSteps.map(step => ({ ...step, active: false, completed: true }));
                steps[steps.length - 1].active = true;
                steps[steps.length - 1].completed = false;
                break;
            case OrderStatus.APPROVED:
                steps = defaultSteps.map((step, index) => ({
                    ...step,
                    active: index === 2,
                    completed: index <= 1,
                }));
                break;
            case OrderStatus.CONDITIONED:
                steps = defaultSteps.map((step, index) => ({
                    ...step,
                    active: index === 0
                }));
                break;
            case OrderStatus.DECLINED:
                steps = []
            default:
                steps = defaultSteps.map((step, index) => ({
                    ...step,
                    active: index === 1,
                    completed: index === 0,
                }));
                break;
        }
        return { steps };
    }

    const { steps } = useMemo(() => generateOrderText(order?.status), [order?.status]);

    const getFniTotalPrice = () => {
        return (
            order?.order_fnI
                ?.reduce((total: number, item: IOrderFnI) => {
                    return total + item.applied_price;
                }, 0)
                .toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }) || 0
        );
    };

    const getAddsOnTotalPrice = () => {
        return (
            order?.order_options?.reduce((total: number, item: IOrderOption) => {
                // Corrected line: Accessing the applied_price directly from the item
                return total + (item.applied_price !== undefined && !isNaN(item.applied_price) ? item.applied_price : 0);
            }, 0)
                .toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }) || 0
        );
    };

    return (
        <Grid theme={theme} item xs={12} md={6}>
            {!!steps?.length && (
                <Box theme={theme} p={{ xs: "40px 16px", md: "0" }}>
                    <Typography
                        theme={theme}
                        variant="h4"
                        component={"h4"}
                        mb={3}
                    >
                        {PLACEHOLDERS.SUBMIT_ORDER_WHATS_NEXT}
                    </Typography>
                    <StepperProgress stepItems={steps} />
                </Box>
            )}
            {!hideCompletedSteps && (
                <Box
                    theme={theme}
                    mt={{ md: 8, xs: 3 }}
                    p={{ xs: "40px 16px", md: "0" }}
                >
                    <Typography
                        theme={theme}
                        variant="h4"
                        component={"h4"}
                        mb={3}
                    >
                        {PLACEHOLDERS.SUBMIT_ORDER_COMPLETED_STEPS}
                    </Typography>
                    <CompletedSteps
                        stepItems={[
                            {
                                title: PLACEHOLDERS.SUBMIT_ORDER_PAYMENT,
                                subtitle: `${currency}${order?.due_at_signing} / ${order?.contract_term} ${PLACEHOLDERS.SUBMIT_ORDER_MONTH} / ${order?.apr}% ${PLACEHOLDERS.APR_TEXT}`, success: true,
                            },
                            {
                                title: PLACEHOLDERS.SUBMIT_ORDER_F_AND_I_PRODUCTS,
                                subtitle: `${order?.order_fnI?.length} ${PLACEHOLDERS.SUBMIT_ORDER_PRODUCTS_ADDED} / ${currency}${getFniTotalPrice()}`,
                                success: order?.order_fnI?.length > 0,
                                error: order?.order_fnI?.length == 0,
                            },
                            {
                                title: PLACEHOLDERS.SUBMIT_ORDER_ADD_ONS,
                                subtitle: `${order?.order_options?.length} ${PLACEHOLDERS.SUBMIT_ORDER_PRODUCTS_ADDED} / ${currency}${getAddsOnTotalPrice()}`,
                                success: order?.order_options?.length > 0,
                                error: order?.order_options?.length == 0,
                            },
                            {
                                title: PLACEHOLDERS.SUBMIT_ORDER_TRADE_IN,
                                subtitle:  generateTabOutput(
                                    Tabs.TRADE_IN,
                                    !!order?.order_tradein
                                      ? {
                                        status: "Added",
                                        totalAmount: calculateAdjustedTradeInAmount(
                                          order,
                                          appState.default_currency_symbol
                                        ),
                                      }
                                      : PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_NONE_SELECTED_SUB_TITLE,
                                    appState?.default_currency_symbol,
                                    !!order?.order_tradein
                                  ),
                                success: order?.order_tradein,
                                error: !order?.order_tradein,
                            },
                            {
                                title: PLACEHOLDERS.SUBMIT_ORDER_INSURANCE,
                                subtitle: PLACEHOLDERS.SUBMIT_ORDER_NONE_ADDED,
                                success: order?.insurance,
                                error: !order?.insurance,
                            },
                            {
                                title: PLACEHOLDERS.SUBMIT_ORDER_SCHEDULE,
                                subtitle: order?.appointment_details ? PLACEHOLDERS.SUBMIT_ORDER_ENCLOSED_TRANSIT_DELIVERY : PLACEHOLDERS.SUBMIT_ORDER_NONE_ADDED,
                                success: order?.appointment_details,
                                error: !order?.appointment_details
                            },
                            {
                                title: PLACEHOLDERS.SUBMIT_ORDER_FINANCING,
                                subtitle: PLACEHOLDERS.SUBMIT_ORDER_CREDIT_APP_SUBMITTED,
                                success: true,
                            },
                        ]}
                    />
                </Box>
            )}
        </Grid>
    )
}
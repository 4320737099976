import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { FAVICON, THEME_BUILDER_CSS } from "shared/config/constants";
import { LayoutWithSideNav } from "../layout";
import {
  Icon,
  Grid,
  DuoTab,
  Box,
  Button,
  Typography,
  Dialog,
} from "@ntpkunity/controls";
import {
  PageHeader,
  ConfirmationDialog,
  ThemeBuilder,
  DrawerWrap,
} from "@sharedComponents";
import Logo from "shared/assets/images/index-logo.svg";
import { IAddress, ICharts, ICountry, ISelectItemList } from "Interface";
import { useForm } from "react-hook-form";
import {
  UseMutation_UpdateCompanyProfile,
  UseMutation_AddCompanyProfile,
  UseQuery_GetAll_CURRENCIES,
  UseQuery_GetAll_USA_States,
  UseQuery_GetAll_Countries,
  UseQuery_GetAll_Radius,
  UseQuery_getLenderIntegrations,
  UseQuery_getDocumentsByCompanyId,
  UseMutation_CheckDuplicateslugName,
  UseMutation_UpdateCompanyPrice,
  UseQuery_getAllCharts,
  useMutation_CheckS3Credentials,
  UseQuery_getThemeBuilderbyEmail,
} from "services";
import { useSetupsStore } from "Stores/SetupStore/Store";
import {
  CompanyProfile,
  CompanyAddress,
  Language,
  Documents,
  CompanyPricing,
  LenderSelectionRules,
} from "modules";
import { usePrompt } from "utilities/usePrompt";
import { CompanyModel } from "models";
import {
  AddressType,
  QueryKeys,
  DialogMessages,
  ValidationMessages,
  PermissionTypes,
} from "Enums";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { setStyleSheet, setFavicon, setLogo } from "favicon";
import { Providers, IntegrationType } from "Enums/IntegrationTypesAndProviders";
import {
  logout,
  useHasPermissions,
  indexPermissions,
} from "@ntpkunity/controls-ums";

const Company: FC = () => {
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();
  const { openDrawerSt } = state;
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [failurePopupState, setFailurePopupState] = useState<boolean>(false);
  const [companyFieldState, setCompanyFieldState] = useState<boolean>(false);
  const { mutate: CheckDuplicateSlug, isLoading: isUniqueSlug } =
    UseMutation_CheckDuplicateslugName();
  const [applyInvoiceValidation, setApplyInvoiceValidation] =
    useState<boolean>(false);
  const [applyOfficeAddressValidation, setApplyOfficeAddressValidation] =
    useState<boolean>(false);
  const [applyGaragingAddressValidation, setApplyGaragingAddressValidation] =
    useState<boolean>(false);
  const [defaultCurrency, setDefaultCurrency] =
    useState<Array<ISelectItemList>>();
  const [isAddressValidationActive, setIsAddressValidationActive] =
    useState<boolean>(false);
  const [mailingAddressState, setMailingAddressState] =
    useState<boolean>(false);
  const [invoiceingAddressState, setInvoiceingAddressState] =
    useState<boolean>(false);
  const [officeAddressState, setOfficeAddressState] = useState<boolean>(false);
  const [verifiedAddressName, setVerifiedAddressName] = useState<string>("");
  const [companyCountries, setCompanyCountries] = useState<Array<ICountry>>([]);
  const [verifiedAddress, setyVerifiedAddress] = useState<IAddress>();
  const [open, setOpen] = useState(false);
  const [lenderFieldState, setLenderFieldState] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>(null);

  const form = useForm();
  const {
    control,
    handleSubmit,
    formState,
    getValues,
    setValue,
    reset,
    setError,
    trigger,
    clearErrors,
  } = form;
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const { data } = UseQuery_getDocumentsByCompanyId();
  const [files, setFiles] = useState([]);
  const [newuploadedfiles, setNewUploadedFiles] = useState([]);
  const [deleteFiles, setDeletedFiles] = useState([]);
  const queryClient = useQueryClient();
  const [emailStatus, setEmailStatus] = useState<string>("");

  const UserEmailData = JSON.parse(localStorage?.getItem("settings"));
  const user_email = UserEmailData?.email || "";

  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  UseQuery_getAllCharts();
  const charts: Array<ICharts> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CHARTS
  );
  useEffect(() => {
    if (localStorage.getItem("settings") != null) {
      var access_token_local = JSON.parse(localStorage.getItem("settings"));
      localStorage.setItem("api-key", access_token_local?.access_token);
      if (
        access_token_local?.role?.name == "Index Dealer Admin User" ||
        access_token_local?.role?.name == "Broker Dealer Admin" ||
        access_token_local?.role?.name == "Dealer Admin"
      ) {
        setUserEmail(access_token_local?.email);
      }
    }
  }, []);

  const settings: any = localStorage?.getItem("settings");
  const parsedSettingsObject = JSON.parse(settings);

  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  useEffect(() => {
    checkS3Credentials(parsedSettingsObject?.tenant_id);
  }, []);

  const { data: themeData } = UseQuery_getThemeBuilderbyEmail(userEmail);
  const { data: lenderintegrations } = UseQuery_getLenderIntegrations();
  const [fontFiles, setFontFiles] = useState(themeData?.font_file?.files || []);

  function isIntegrationActive(integration_type, provider_name) {
    let is_active = false;
    const integration = lenderintegrations?.find(
      (integration) => integration.integration_type === integration_type
    );

    integration?.providers?.map((provider) => {
      if (provider?.provider_name == provider_name) {
        is_active = provider?.is_active;
      }
    });

    return is_active;
  }
  useEffect(() => {
    const fetchData = async () => {
      if (themeData) {
        const colors = await handleButtonClick(themeData?.stylesheet);
        if (colors) {
          setValue("theme.primary_color", colors.primaryColor);
          setValue("theme.secondary_color", colors.secondaryColor);
        }
        setValue("theme.favicon", themeData?.favicon);
        setValue("theme.logo", themeData?.logo);
        setFavicon(themeData?.favicon?.location);
        setLogo(themeData?.logo?.location);

        if (Array.isArray(themeData?.font_file?.files)) {
          const fontFilesFromDB = themeData.font_file.files.map((file) => ({
            name: file.key,
            key: file.key,
          }));
          setFontFiles(fontFilesFromDB);
        } else {
          setFontFiles([]);
        }
      } else {
        setThemeDefaultValues();
      }
    };

    fetchData();
  }, [userEmail, themeData]);

  useEffect(() => {
    if (lenderintegrations) {
      setIsAddressValidationActive(
        isIntegrationActive(IntegrationType.LOCATION_SERVICE, Providers.GOOGLE)
      );
    }
  }, [lenderintegrations]);

  useEffect(() => {
    if (companyProfile) {
      setUserEmail(companyProfile.email);
    }
  }, [companyProfile]);

  useEffect(() => {
    if (data) {
      setFiles(data);
    }
  }, [data]);
  useEffect(() => {
    checkS3Credentials(parsedSettingsObject?.tenant_id);
  }, []);
  UseQuery_GetAll_CURRENCIES();
  UseQuery_GetAll_USA_States();
  UseQuery_GetAll_Countries();
  UseQuery_GetAll_Radius();

  const setThemeDefaultValues: any = () => {
    setValue("theme.favicon", defaultThemeValues?.favicon);
    setValue("theme.logo", defaultThemeValues?.logo);
    setValue("theme.primary_color", defaultThemeValues?.primary_color);
    setValue("theme.secondary_color", defaultThemeValues?.secondary_color);
    setFavicon(defaultThemeValues.favicon?.location);
    setLogo(defaultThemeValues.logo?.location);
  };
  // const ThemeBuilderData: any = queryClient.getQueryData([
  //   QueryKeys.GET_THEME_BUILDER,
  //   companyProfile?.email,
  // ]);
  const addressStates: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_USA_STATES
  );
  const allCountries: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_COUNTRIES
  );
  const [selectedDefaultCurrencyId, setSelectedDefaultCurrencyId] =
    useState<number>(companyProfile?.default_currency);

  const defaultThemeValues = {
    primary_color: "3952F5",
    secondary_color: "D8DAE5",
    favicon: {
      location: `${process.env.DMS_BUCKET_BASE_URL + FAVICON}`,
    },
    logo: { location: Logo },
  };

  useEffect(() => {
    if (
      formState.dirtyFields?.MailingAddress ||
      formState.dirtyFields?.OfficeAddress ||
      formState.dirtyFields?.GaragingAddress ||
      formState.dirtyFields?.InvoicingAddress ||
      formState.dirtyFields?.theme
    ) {
      setCompanyFieldState(true);
    }
  }, [formState]);

  function setFormValue(name: string, address: IAddress) {
    setValue(
      name,
      {
        id: address?.id ? address?.id : undefined,
        address_line_1: address?.address_line_1 ? address?.address_line_1 : "",
        address_line_2: address?.address_line_2 ? address?.address_line_2 : "",
        city: address?.city ? address?.city : "",
        contact_number: address?.contact_number ? address?.contact_number : "",
        county: address?.county ? address?.county : "",
        zip_code: address?.zip_code ? address?.zip_code : "",
        state_id: address?.state_id ? address?.state_id : "",
        state_name: address?.state_name ? address?.state_name : "",
        field5: address?.field5 ? address?.field5 : "",
        field6: address?.field6 ? address?.field6 : "",
        verified: address?.verified ? address?.verified : false,
        address_type: address?.address_type ? address?.address_type : "",
      },
      { shouldValidate: false, shouldDirty: false }
    );
  }
  useEffect(() => {
    setSelectedDefaultCurrencyId(companyProfile?.default_currency);
    let company: CompanyModel = new CompanyModel();
    company.company_profile = companyProfile;
    company.company_addresses = companyProfile?.addresses;
    company.theme = companyProfile?.theme;
    if (companyProfile) {
      setUserEmail(companyProfile?.email);
    }
    if (themeData) {
      setValue("theme.favicon", themeData?.favicon);
      setValue("theme.logo", themeData?.logo);
      setValue("theme.font_file", themeData?.font_file);
      setValue("theme", themeData);
    } else {
      setThemeDefaultValues();
    }
    setFormValue(
      "MailingAddress",
      company.company_addresses?.find(
        (x) => x.address_type == AddressType.MAILING
      )
    );
    setFormValue(
      "OfficeAddress",
      company.company_addresses?.find(
        (x) => x.address_type == AddressType.OFFICE
      )
    );
    setFormValue(
      "InvoicingAddress",
      company.company_addresses?.find(
        (x) => x.address_type == AddressType.INVOICE
      )
    );
    setFormValue(
      "GaragingAddress",
      company.company_addresses?.find(
        (x) => x.address_type == AddressType.GARAGING
      )
    );

    let val = company?.company_profile?.preferred_currency_ids
      ?.split(",")
      ?.map(Number);

    setValue("CompanyProfile", company?.company_profile, {
      shouldDirty: false,
      shouldTouch: false,
    });
    setValue("CompanyProfile.preferred_currency_ids", val);
    setValue("pricing_program", company?.company_profile?.pricing_program);

    if (
      !companyProfile?.configured_rates ||
      companyProfile.configured_rates.length === 0
    ) {
      // Set default values when the array is empty
      setValue("finance_downPayment", 0);
      setValue("finacnce_rvBalloon", 0);
      setValue("finance_customerRate", 0);
      setValue("finance_mindownpaymentrate", 0);
      setValue("finance_maxdownpaymentrate", 0);
      setValue("lease_downPayment", 0);
      setValue("lease_rvBalloon", 0);
      setValue("lease_customerRate", 0);
      setValue("lease_mindownpaymentrate", 0);
      setValue("lease_maxdownpaymentrate", 0);
    } else {
      companyProfile.configured_rates.map((rate) => {
        if (rate.finance_type === "Finance") {
          setValue("finance_downPayment", rate?.down_payment_rate ?? "");
          setValue("finacnce_rvBalloon", rate?.rv_balloon_rate ?? "");
          setValue("finance_customerRate", rate?.customer_rate ?? "");
          setValue(
            "finance_mindownpaymentrate",
            rate?.min_down_payment_rate ?? ""
          );
          setValue(
            "finance_maxdownpaymentrate",
            rate?.max_down_payment_rate ?? ""
          );
        } else {
          setValue("lease_downPayment", rate?.down_payment_rate);
          setValue("lease_rvBalloon", rate?.rv_balloon_rate);
          setValue("lease_customerRate", rate?.customer_rate);
          setValue(
            "lease_mindownpaymentrate",
            rate?.min_down_payment_rate ?? ""
          );
          setValue(
            "lease_maxdownpaymentrate",
            rate?.max_down_payment_rate ?? ""
          );
        }
      });
    }

    const selectedCommisionChart = charts?.find(
      (x) =>
        x.id === companyProfile?.configured_charts?.[0]?.commission_chart_id
    );
    const selectedInterestChart = charts?.find(
      (x) =>
        x.id === companyProfile?.configured_charts?.[0]?.interest_rate_chart_id
    );
    const selectedRVBalloonChart = charts?.find(
      (x) =>
        x.id ===
        companyProfile?.configured_charts?.[0]?.rv_balloon_rate_chart_id
    );
    const selectedFeesChart = charts?.find(
      (x) => x.id === companyProfile?.configured_charts?.[0]?.fees_chart_id
    );

    setValue(
      "commission_chart_id",
      selectedCommisionChart?.chart_name
        ? {
            label: selectedCommisionChart?.chart_name ?? "",
            id: selectedCommisionChart?.id ?? "",
          }
        : null
    );
    setValue(
      "interest_rate_chart_id",
      selectedInterestChart?.chart_name
        ? {
            label: selectedInterestChart?.chart_name ?? "",
            id: selectedInterestChart?.id ?? "",
          }
        : null
    );
    setValue(
      "rv_balloon_rate_chart_id",
      selectedRVBalloonChart?.chart_name
        ? {
            label: selectedRVBalloonChart?.chart_name,
            id: selectedRVBalloonChart?.id,
          }
        : null
    );
    setValue(
      "fees_chart_id",
      selectedFeesChart?.chart_name
        ? {
            label: selectedFeesChart?.chart_name ?? "",
            id: selectedFeesChart?.id ?? "",
          }
        : null
    );
    setValue(
      "CompanyProfile.default_currency",
      company?.company_profile?.default_currency
    );
    setValue(
      "CompanyProfile.credit_decision_expiry_in_days",
      company?.company_profile?.credit_decision_expiry_in_days || null
    );
    setValue(
      "CompanyProfile.time_zone",
      company?.company_profile?.time_zone || ""
    );
    setValue(
      "CompanyProfile.day_light_saving",
      company?.company_profile
        ? company?.company_profile?.day_light_saving
        : true
    );
    setValue(
      "CompanyProfile.pre_qualification_expiry_in_days",
      company?.company_profile?.pre_qualification_expiry_in_days || null
    );
    setValue(
      "CompanyProfile.company_code",
      company?.company_profile?.company_code || null
    );
    setValue(
      "CompanyProfile.contact_person_email",
      company?.company_profile?.contact_person_email || null
    );
    setValue(
      "CompanyProfile.contact_person_name",
      company?.company_profile?.contact_person_name || null
    );
    setValue(
      "CompanyProfile.contact_person_phone_number",
      company?.company_profile?.contact_person_phone_number || null
    );
    setEmailStatus(company?.company_profile?.email_status);

    setValue("MailingAddress.address_type", AddressType.MAILING);
    setValue("InvoicingAddress.address_type", AddressType.INVOICE);
    setValue("OfficeAddress.address_type", AddressType.OFFICE);
    setValue("GaragingAddress.address_type", AddressType.GARAGING);

    if (themeData == null) {
      setThemeDefaultValues();
    } else {
      setValue("theme", themeData);
    }
  }, [companyProfile, charts, themeData]);
  const handleButtonClick = async (stylesheetlink: any) => {
    const s3FileLink = stylesheetlink;

    const response = await fetch(s3FileLink, {
      mode: "cors",
      method: "GET",
    });

    const cssContent = await response.text();
    const primaryColorRegex = /--primary:\s*#?([a-fA-F0-9]+)\b/;
    const secondaryColorRegex = /--secondary:\s*#?([a-fA-F0-9]+)\b/;

    // Extract primary and secondary color values from the CSS string
    const primaryColorMatch = cssContent.match(primaryColorRegex);
    const secondaryColorMatch = cssContent.match(secondaryColorRegex);
    // const matches = cssContent.match(colorRegex);
    const primaryColor = primaryColorMatch ? primaryColorMatch[1].trim() : null;
    const secondaryColor = secondaryColorMatch
      ? secondaryColorMatch[1].trim()
      : null;

    setValue("theme.primary_color", primaryColor);
    setValue("theme.secondary_color", secondaryColor);
    if (!primaryColor && !secondaryColor) {
      setValue("theme.primary_color", defaultThemeValues.primary_color);
      setValue("theme.secondary_color", defaultThemeValues.secondary_color);
    }
    return { primaryColor: primaryColor, secondaryColor: secondaryColor };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (themeData) {
        const colors = await handleButtonClick(themeData?.stylesheet);
        if (colors) {
          setValue("theme.primary_color", colors.primaryColor);
          setValue("theme.secondary_color", colors.secondaryColor);
        }
      } else {
        setThemeDefaultValues();
      }
    };

    fetchData();
  }, [themeData]);
  async function convertCssToBase64(
    primaryColor: string,
    secondaryColor: string
  ) {
    try {
      const response = await fetch(
        `${process.env.DMS_BUCKET_BASE_URL + THEME_BUILDER_CSS}`,
        {
          mode: "cors",
          method: "GET",
        }
      );

      const cssContent = await response.text();
      const modifiedCss = modifyCssContent(
        cssContent,
        primaryColor,
        secondaryColor
      );

      const blob = new Blob([modifiedCss], { type: "text/css" });

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            const base64Css = btoa(event.target.result as string);
            resolve(base64Css);
          } else {
            reject(new Error("Error converting CSS to base64."));
          }
        };
        reader.readAsBinaryString(blob);
      });
    } catch (error: any) {
      throw new Error("Error fetching or updating CSS file: " + error.message);
    }
  }

  function modifyCssContent(
    cssContent: any,
    primaryColor: string,
    secondaryColor: string
  ) {
    const modifiedCss = cssContent
      .replace(/--primary:\s*(.*?);/, `--primary: #${primaryColor};`)
      .replace(/--secondary:\s*(.*?);/, `--secondary: #${secondaryColor};`);

    return modifiedCss;
  }
  const { mutate: UpdateCompanyProfile, isLoading: updateLoader } =
    UseMutation_UpdateCompanyProfile();
  const { mutate: updateCompanyProgramPrice } =
    UseMutation_UpdateCompanyPrice();
  const { mutate: addCompanyProfile } = UseMutation_AddCompanyProfile();
  async function onSave(data) {
    if (isUniqueSlug) {
      return;
    }
    let errorStates = false;
    if (data.theme) {
      data.theme.tenant_id = JSON.parse(
        localStorage.getItem("settings")
      )?.tenant_id;
      const base64Css = await convertCssToBase64(
        data.theme.primary_color,
        data.theme.secondary_color
      );
      data.theme.stylesheet = base64Css;

      if (data?.theme?.favicon_url) {
        data.theme.favicon = {
          url: data.theme.favicon_url.url,
          key: data.theme.favicon_url.key,
        };
        delete data.theme.favicon_url;
      } else if (data?.theme?.favicon?.location || data?.theme?.favicon?.url) {
        data.theme.favicon = {
          location: data.theme.favicon.location || data.theme.favicon.url,
          key: data.theme.favicon.key || null
        };
      }

      if (data?.theme?.logo_url) {
        data.theme.logo = {
          url: data.theme.logo_url.url,
          key: data.theme.logo_url.key,
        };
        delete data.theme.logo_url;
      } else if (data?.theme?.logo?.location || data?.theme?.logo?.url) {
        data.theme.logo = {
          location: data.theme.logo.location || data.theme.logo.url,
          key: data.theme.logo.key || null
        };
      }

      if (Array.isArray(fontFiles)) {
        data.theme.font_file = {
          files: fontFiles.map((file) => ({
            key: file.key,
          })),
        };
      }
    }
    if (
      !data.MailingAddress.address_line_1 ||
      !data.MailingAddress.city ||
      data.MailingAddress.country_id
    ) {
      if (!data.MailingAddress.address_line_1) {
        setError("MailingAddress.address_line_1", {
          message: ValidationMessages.ADDRESS_LINE_1,
        });
        errorStates = true;
      }
      if (!data.MailingAddress.city) {
        setError("MailingAddress.city", {
          message: ValidationMessages.CITY_REQUIRED,
        });
        errorStates = true;
      }
      if (data.MailingAddress.country_id) {
        const countryCode = companyCountries.find(
          (x) => x.id == data.MailingAddress.country_id.id
        ).country_code;
        if (
          (countryCode == "US" || countryCode == "CA") &&
          (!data.MailingAddress.state_id || !data.MailingAddress.zip_code)
        ) {
          if (!data.MailingAddress.state_id) {
            setError("MailingAddress.state_id", {
              message: ValidationMessages.STATE_REQUIRED,
            });
            errorStates = true;
          }
          if (!data.MailingAddress.zip_code) {
            setError("MailingAddress.zip_code", {
              message: ValidationMessages.ZIP_CODE_REQUIRED,
            });
            errorStates = true;
          }
        }
      }
    }
    if (data.theme) {
      if (
        !data.theme.primary_color ||
        !data.theme.secondary_color ||
        !data.theme.logo ||
        !data.theme.favicon
      ) {
        if (!data.theme.logo) {
          setError("theme.logo", {
            message: "Logo is Required",
          });
          errorStates = true;
        }
        if (!data.theme.favicon) {
          setError("theme.favicon", {
            message: "Favicon is Required",
          });
          errorStates = true;
        }
        if (!data.theme.favicon) {
          setError("theme.favicon", {
            message: "Favicon is Required",
          });
          errorStates = true;
        }
        if (!data.theme.primary_color) {
          setError("theme.primary_color", {
            message: "Primary Color is Required",
          });
          errorStates = true;
        }
        if (!data.theme.secondary_color) {
          setError("theme.secondary_color", {
            message: "Secondary Color is Required",
          });
          errorStates = true;
        }
      }
    }
    if (!s3Response) {
      data.theme = null;
    }
    if (errorStates) {
      return;
    }
    const documentKeys = newuploadedfiles.map((file) => file.key);
    let addrArr = [];
    let company: CompanyModel = new CompanyModel();
    company.company_profile = {
      ...data?.CompanyProfile,
      min_months_stay_at_address:
        data?.CompanyProfile?.min_months_stay_at_address == ""
          ? null
          : data?.CompanyProfile?.min_months_stay_at_address,
      slug:
        data.CompanyProfile.slug === "" ? undefined : data.CompanyProfile.slug,
      pricing_program: data?.pricing_program,
    };
    company.company_profile.preferred_currency_ids =
      company.company_profile?.preferred_currency_ids?.toString();
    company.company_profile.minimum_financed_amount =
      +company.company_profile.minimum_financed_amount;
    company.theme = data?.theme;
    setEmailStatus(company.company_profile.email_status);
    company.theme.id = company.theme?.id ?? 0;
    company.theme.user_email = company.company_profile?.email;
    company.company_documents = { key: documentKeys, user_email: user_email };
    company.deleted_documents = deleteFiles;
    let configured_charts = {};
    let configured_rates = {};
    if (data.pricing_program?.includes("Standard Charts")) {
      configured_charts = [
        {
          interest_rate_chart_id:
            data.interest_rate_chart_id?.id !== ""
              ? data.interest_rate_chart_id?.id
              : null,
          commission_chart_id:
            data.commission_chart_id?.id !== ""
              ? data.commission_chart_id?.id
              : null,
          rv_balloon_rate_chart_id:
            data.rv_balloon_rate_chart_id?.id !== ""
              ? data.rv_balloon_rate_chart_id?.id
              : null,
          fees_chart_id:
            data.fees_chart_id?.id !== "" ? data.fees_chart_id?.id : null,
        },
      ];
    }
    if (data.pricing_program?.includes("Standard Rates")) {
      configured_rates = [
        {
          finance_type: "Finance",
          customer_rate:
            data.finance_customerRate !== "" ? data.finance_customerRate : 0,
          down_payment_rate:
            data.finance_downPayment !== "" ? data.finance_downPayment : 0,
          rv_balloon_rate:
            data.finacnce_rvBalloon !== "" ? data.finacnce_rvBalloon : 0,
          min_down_payment_rate:
            data.finance_mindownpaymentrate !== ""
              ? data.finance_mindownpaymentrate
              : 0,
          max_down_payment_rate:
            data.finance_maxdownpaymentrate !== ""
              ? data.finance_maxdownpaymentrate
              : 0,
        },
        {
          finance_type: "Lease",
          customer_rate:
            data.lease_customerRate !== "" ? data.lease_customerRate : 0,
          down_payment_rate:
            data.lease_downPayment !== "" ? data.lease_downPayment : 0,
          rv_balloon_rate:
            data.lease_rvBalloon !== "" ? data.lease_rvBalloon : 0,
          min_down_payment_rate:
            data.lease_mindownpaymentrate !== ""
              ? data.lease_mindownpaymentrate
              : 0,
          max_down_payment_rate:
            data.lease_maxdownpaymentrate !== ""
              ? data.lease_maxdownpaymentrate
              : 0,
        },
      ];
    }
    if (
      data?.MailingAddress?.address_line_1 != undefined &&
      data?.MailingAddress?.address_line_1 != ""
    ) {
      data.MailingAddress.company_id = company?.company_profile?.id;
      data.MailingAddress.address_type = AddressType.MAILING;
      data.MailingAddress.verified = data.MailingAddress.verified
        ? data.MailingAddress.verified
        : false;
      data.MailingAddress.country_id = data.MailingAddress.country_id?.id;
      data.MailingAddress.state_id = data.MailingAddress.state_id
        ? data.MailingAddress.state_id
        : null;
      if (!data.MailingAddress.state_name) {
        data.MailingAddress.state_name = data.MailingAddress.state_id
          ? addressStates?.find((x) => x.id == data.MailingAddress.state_id)
              ?.name
          : "";
      }
      addrArr?.push(data.MailingAddress);
    }
    if (
      data?.GaragingAddress?.address_line_1 != undefined &&
      data?.GaragingAddress?.address_line_1 != ""
    ) {
      data.GaragingAddress.company_id = company?.company_profile?.id;
      data.GaragingAddress.address_type = AddressType.GARAGING;
      data.GaragingAddress.verified = data.GaragingAddress.verified
        ? data.GaragingAddress.verified
        : false;
      data.GaragingAddress.country_id = data.GaragingAddress?.country_id?.id;
      data.GaragingAddress.state_id = data.GaragingAddress.state_id
        ? data.GaragingAddress.state_id
        : null;
      if (!data.GaragingAddress.state_name) {
        data.GaragingAddress.state_name = data.GaragingAddress.state_id
          ? addressStates?.find((x) => x.id == data.GaragingAddress.state_id)
              ?.name
          : "";
      }
      addrArr.push(data.GaragingAddress);
    }
    if (
      data?.InvoicingAddress?.address_line_1 != undefined &&
      data?.InvoicingAddress?.address_line_1 != ""
    ) {
      data.InvoicingAddress.company_id = company?.company_profile?.id;
      data.InvoicingAddress.address_type = AddressType.INVOICE;
      data.InvoicingAddress.verified = data.InvoicingAddress.verified
        ? data.InvoicingAddress.verified
        : false;
      data.InvoicingAddress.country_id = data.InvoicingAddress?.country_id?.id;
      data.InvoicingAddress.state_id = data.InvoicingAddress.state_id
        ? data.InvoicingAddress.state_id
        : null;
      if (!data.InvoicingAddress.state_name) {
        data.InvoicingAddress.state_name = data.InvoicingAddress.state_id
          ? addressStates?.find((x) => x.id == data.InvoicingAddress.state_id)
              ?.name
          : "";
      }
      addrArr.push(data.InvoicingAddress);
    }
    if (
      data?.OfficeAddress?.address_line_1 != undefined &&
      data?.OfficeAddress?.address_line_1 != ""
    ) {
      data.OfficeAddress.company_id = company?.company_profile?.id;
      data.OfficeAddress.address_type = AddressType.OFFICE;
      data.OfficeAddress.verified = data.OfficeAddress.verified
        ? data.OfficeAddress.verified
        : false;
      data.OfficeAddress.country_id = data.OfficeAddress?.country_id?.id;
      data.OfficeAddress.state_id = data.OfficeAddress.state_id
        ? data.OfficeAddress.state_id
        : null;
      if (!data.OfficeAddress.state_name) {
        data.OfficeAddress.state_name = data.OfficeAddress.state_id
          ? addressStates?.find((x) => x.id == data.OfficeAddress.state_id)
              ?.name
          : "";
      }
      addrArr.push(data.OfficeAddress);
    }
    if (addrArr?.length > 0) {
      company.company_addresses = addrArr;
    }
    if (company?.company_addresses?.length > 0) {
      if (company.company_profile.id > 0) {
        UpdateCompanyProfile(company, {
          onSuccess: (response: any) => {
            if (response.theme) {
              setStyleSheet(response?.data?.theme?.stylesheet);
              setFavicon(response?.data.theme?.favicon?.location);
              setLogo(response?.data.theme?.logo?.location);
            }
            if (response.theme == null) {
              setFavicon(defaultThemeValues.favicon?.location);
              setLogo(defaultThemeValues.logo?.location);
            }
            queryClient.invalidateQueries([
              QueryKeys.GET_THEME_BUILDER,
              response?.data?.theme.user_email,
            ]);
          },
        });
        const priceProgramBody = {
          company_id: company?.company_profile?.id,
          configured_charts: Array.isArray(configured_charts)
            ? configured_charts
            : undefined,
          configured_rates: Array.isArray(configured_rates)
            ? configured_rates
            : undefined,
        };
        updateCompanyProgramPrice(priceProgramBody, {
          onSuccess: () => {
            reset(data);
          },
        });
      } else {
        addCompanyProfile(company);
      }
      delete formState.dirtyFields?.MailingAddress;
      delete formState.dirtyFields?.OfficeAddress;
      delete formState.dirtyFields?.GaragingAddress;
      delete formState.dirtyFields?.InvoicingAddress;
      delete formState.dirtyFields?.theme;
      setCompanyFieldState(false);
      setDeletedFiles([]);
      setNewUploadedFiles([]);
    }
  }
  const resetMailingAddress = () => {
    setValue("MailingAddress.address_line_1", "");
    setValue("MailingAddress.address_line_2", "");
    setValue("MailingAddress.city", "");
    setValue("MailingAddress.contact_number", "");
    setValue("MailingAddress.county", "");
    setValue("MailingAddress.zip_code", "");
    setValue("MailingAddress.state_id", "");
    setValue("MailingAddress.verified", false);
  };

  const tabs = [
    {
      title: (
        <Box
          className={
            Object.keys(formState.errors).includes("CompanyProfile")
              ? "error"
              : ""
          }
          theme={theme}
        >
          Profile
        </Box>
      ),
      content: (
        <CompanyProfile
          control={control}
          setValue={setValue}
          errors={formState.errors}
          setCompanyFieldState={setCompanyFieldState}
          defaultCurrency={defaultCurrency}
          setDefaultCurrency={setDefaultCurrency}
          selectedDefaultCurrencyId={selectedDefaultCurrencyId}
          setSelectedDefaultCurrencyId={setSelectedDefaultCurrencyId}
          getValues={getValues}
          trigger={trigger}
          setError={setError}
          clearErrors={clearErrors}
          CheckDuplicateSlug={CheckDuplicateSlug}
          isUniqueSlug={isUniqueSlug}
          setCompanyCountries={setCompanyCountries}
          emailStatus={emailStatus}
          resetMailingAddress={() => resetMailingAddress()}
        />
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(formState.errors).includes("MailingAddress")
              ? "error"
              : ""
          }
          theme={theme}
        >
          Address
        </Box>
      ),

      content: (
        <CompanyAddress
          resetMailingAddress={() => resetMailingAddress()}
          control={control}
          errors={formState.errors}
          setValue={setValue}
          getValues={getValues}
          applyInvoiceValidation={applyInvoiceValidation}
          applyOfficeAddressValidation={applyOfficeAddressValidation}
          setApplyInvoiceValidation={setApplyInvoiceValidation}
          setApplyOfficeAddressValidation={setApplyOfficeAddressValidation}
          applyGaragingAddressValidation={applyGaragingAddressValidation}
          setApplyGaragingAddressValidation={setApplyGaragingAddressValidation}
          companyCountries={companyCountries}
          setFormValue={setFormValue}
          isAddressValidationActive={isAddressValidationActive}
          mailingAddressState={mailingAddressState}
          setMailingAddressState={setMailingAddressState}
          invoiceingAddressState={invoiceingAddressState}
          setInvoiceingAddressState={setInvoiceingAddressState}
          officeAddressState={officeAddressState}
          setOfficeAddressState={setOfficeAddressState}
          verifyAddress={(type) => verifyAddress(type)}
        />
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(formState.errors).includes("theme") ? "error" : ""
          }
          theme={theme}
        >
          Personalization
        </Box>
      ),
      content: (
        <ThemeBuilder
          form={form}
          theme={theme}
          fontFiles={fontFiles}
          setFontFiles={setFontFiles}
          value={defaultThemeValues}
          s3Response={s3Response}
          showFontFileControl={true}
        />
      ),
    },
    ...(useHasPermissions(indexPermissions.MENU.LANGUAGE, PermissionTypes.VIEW)
      ? [
          {
            title: "Language",
            content: (
              <>
                {" "}
                <Language />
              </>
            ),
          },
        ]
      : []),
    {
      title: <Box theme={theme}>Documents</Box>,
      content: (
        <Documents
          form={form}
          theme={theme}
          files={files}
          setFiles={setFiles}
          newuploadedfiles={newuploadedfiles}
          setNewUploadedFiles={setNewUploadedFiles}
          setCompanyFieldState={setCompanyFieldState}
          companyFieldState={companyFieldState}
          setDeletedFiles={setDeletedFiles}
          deleteFiles={deleteFiles}
          s3response={s3Response}
        />
      ),
    },
    {
      title: <Box theme={theme}>Pricing</Box>,
      content: (
        <CompanyPricing
          form={form}
          setCompanyFieldState={setCompanyFieldState}
        />
      ),
    },
    ...(useHasPermissions(
      indexPermissions.MENU.COMPANY_LENDER_SELECTION_RULES,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Lender Selection Rules",
            content: <LenderSelectionRules />,
          },
        ]
      : []),
  ];
  const verifyAddress = (type) => {
    let address: IAddress = null;
    switch (type) {
      case AddressType.MAILING:
        setVerifiedAddressName("MailingAddress");
        address = getValues("MailingAddress");
        break;
      case AddressType.INVOICE:
        setVerifiedAddressName("InvoicingAddress");
        address = getValues("InvoicingAddress");
        break;
      case AddressType.OFFICE:
        setVerifiedAddressName("OfficeAddress");
        address = getValues("OfficeAddress");
        break;
      case AddressType.GARAGING:
        setVerifiedAddressName("GaragingAddress");
        address = getValues("GaragingAddress");
        break;
      default:
        break;
    }
    let data = [
      address.address_line_1,
      address.address_line_2,
      address.city,
      address.state_name,
      address?.zip_code,
    ]
      .filter(Boolean)
      .join(", ");
  };

  const confirmDialog = (): void => {
    setValue(verifiedAddressName, {
      id: verifiedAddress?.id ? verifiedAddress?.id : undefined,
      address_line_1: verifiedAddress?.address_line_1
        ? verifiedAddress?.address_line_1
        : "",
      city: verifiedAddress?.city ? verifiedAddress?.city : "",
      county: verifiedAddress?.county ? verifiedAddress?.county : "",
      zip_code: verifiedAddress?.zip_code ? verifiedAddress?.zip_code : "",
      address_line_2: verifiedAddress?.address_line_2
        ? verifiedAddress?.address_line_2
        : "",
      contact_number: verifiedAddress?.contact_number
        ? verifiedAddress?.contact_number
        : "",
      verified: true,
      address_type: verifiedAddress?.address_type,
      country_id: verifiedAddress?.country_id,
      state_id: addressStates?.find((obj) => {
        return obj.name === verifiedAddress?.state_name;
      })?.id,
      state_name: verifiedAddress?.state_name
        ? verifiedAddress?.state_name
        : "",
      field5: verifiedAddress?.field5 ? verifiedAddress?.field5 : "",
      field6: verifiedAddress?.field6 ? verifiedAddress?.field6 : "",
    });

    if (
      verifiedAddress?.address_line_1 === "" ||
      verifiedAddress?.city === "" ||
      verifiedAddress?.state_name === "" ||
      verifiedAddress?.zip_code === ""
    ) {
      setValue(verifiedAddressName + ".verified", false);
      verifiedAddress.verified = false;
    }

    trigger(verifiedAddressName);

    setAddressState(verifiedAddress);
    setOpen(false);
    setLenderFieldState(true);
  };

  const setAddressState = (verifiedAddress: IAddress): void => {
    if (AddressType.MAILING === verifiedAddress.address_type) {
      verifiedAddress.verified
        ? setMailingAddressState(false)
        : setMailingAddressState(true);
    } else if (AddressType.OFFICE === verifiedAddress.address_type) {
      verifiedAddress.verified
        ? setOfficeAddressState(false)
        : setOfficeAddressState(true);
    } else if (AddressType.INVOICE === verifiedAddress.address_type) {
      verifiedAddress.verified
        ? setInvoiceingAddressState(false)
        : setInvoiceingAddressState(true);
    }
  };
  const onClose = () => {
    setFailurePopupState(false);
  };
  useEffect(() => {
    if (companyProfile?.countries) {
      let arr: Array<ICountry> = [];
      for (let i = 0; i < companyProfile?.countries.length; i++) {
        if (allCountries)
          arr.push(
            allCountries?.find((x) => x.id == companyProfile?.countries[i])
          );
      }
      setCompanyCountries(arr);
      setValue("CompanyProfile.countries", companyProfile?.countries);
    }
  }, [allCountries, companyProfile]);

  const onSaveConfirm = (e: any) => {
    handleSubmit(onSave)();
    setLeavePageState(false);
    setCompanyFieldState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };

  usePrompt(
    "Leave screen?",
    companyFieldState,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      setCompanyFieldState(false);
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <DrawerWrap open={openDrawerSt}>
        <LayoutWithSideNav theme={theme}>
          <Grid theme={theme} container rowSpacing={2}>
            <Grid theme={theme} item xs={12} />
            <PageHeader
              theme={theme}
              className="main-page-header border-bottom"
            >
              <Grid theme={theme} container spacing={2}>
                <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
                  <Typography
                    variant="h3"
                    component="h3"
                    sx={{ fontSize: 36 }}
                    rowGap={5}
                    mt={1}
                    data-testid="heading"
                    theme={theme}
                  >
                    Company Profile
                  </Typography>
                </Grid>
                <Grid
                  theme={theme}
                  item
                  xs={12}
                  lg={5}
                  sm={12}
                  md={6}
                  textAlign="right"
                >
                  {form.formState.isDirty || companyFieldState ? (
                    <Button
                      theme={theme}
                      onClick={handleSubmit(onSave)}
                      primary
                      text="Save Changes"
                      disabled={Object.keys(form.formState.errors).length > 0}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </PageHeader>

            <Grid theme={theme} item xs={12} my={-2} sx={{ mb: 5 }}>
              {/* <DuoTabWrap theme={theme} className="tab-form"> */}
              <DuoTab
                theme={theme}
                varient={"underline"}
                items={tabs}
                defaultTabIndex={0}
              />
              {/* </DuoTabWrap> */}
            </Grid>
          </Grid>
          <Grid theme={theme} item xs={12} />
          {failurePopupState && (
            <Dialog
              size="xs"
              variant="confirmation"
              open={failurePopupState}
              noFooter={false}
              onCloseDialog={onClose}
              theme={theme}
              customFooter={
                <Button
                  primary
                  text={"Close"}
                  fullWidth={true}
                  style={{ paddingRight: "82px", paddingLeft: "82px" }}
                  onClick={onClose}
                />
              }
            >
              <Box theme={theme} className="dialog-icon">
                <Icon name="DialogAlertIcon" />
              </Box>
              <Box theme={theme}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  An Error Ocurred
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                  Something went wrong
                </Typography>
              </Box>
            </Dialog>
          )}
        </LayoutWithSideNav>
      </DrawerWrap>
    </>
  );
};

export default Company;

import { useAppContext } from "@src/context/app-context";
import { useEffect } from "react";
import { useGetOrderInfo, useGetCompanyDomain } from "./order";
import {
  setOrderInfo,
  loadTenantConfigurations,
} from "@src/context/app-actions";
import { useLocation, useNavigate } from "react-router-dom";
import { removeNullValues } from "@src/helpers/appHelper";
const extractOrderParams = (url) => {
  const dealRefRegex = /(?:checkout|finance-offer)\/deal\/([^/]+)/;
  const dealRefMatch = url.match(dealRefRegex);

  return {
    dealRefNumber: dealRefMatch ? dealRefMatch[1] : null,
  };
};

export const useUrlParamsForOrderInfo = () => {
  const {
    state: {
      order,
      configuration: { urlOrigin },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { mutate: getOrderInfo } = useGetOrderInfo();
  const { mutate: companyData } = useGetCompanyDomain();
  const location = useLocation(); // Access the current location object (URL)
  const navigate = useNavigate();
  useEffect(() => {
    // Extract VIN and order number from the current URL
    const { dealRefNumber } = extractOrderParams(location.pathname);
    if (!dealRefNumber) {
      return; // Do nothing if dealRefNumber is not present in the URL
    }

    // Check if order info for the current dealRefNumber is already in the state
    if (order && order.reference_number === dealRefNumber) {
      return; // Skip the API call if order info is already available
    }
    companyData(
      {
        origin_domain: urlOrigin,
      },
      {
        onSuccess: (data) => {
          appDispatch(loadTenantConfigurations(data));
        },
      }
    );
    getOrderInfo(dealRefNumber, {
      onSuccess: (response) => {
        appDispatch(setOrderInfo({ ...order, ...removeNullValues(response) }));
      },
      onError: (error) => {
        console.error("error getting order information:", error);
      },
    });
  }, [location.pathname]);
};

import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const DocumentEditorWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.document-editor-wrap": {
    ".tox-tinymce": {
      height: "560px !important",
      width: '100% !important'
    },

    ".bookmark-drawer": {
      ".custom-drawer": {
        width: "100%",
        ".MuiDrawer-paper": {
          position: "initial",
          width: "100%",
          height: "560px",
          boxShadow: "0 0 0 transparent",
          ".drawer-header": {
            padding: 16,
            ".u-drawer-title": {
              display: "inline-block",
              width: "100%",
              ".u-drawer-close": {
                position: "initial",
                float: "right",
              },
            },
          },
          ".drawer-content-wrap": {
            ".drawer-body": {
              padding: 24,
              ".search-bookmark": {
                ".u-form-group": {
                  marginBottom: 24,
                  ".MuiInputBase-root": {
                    fieldset: {
                      borderRadius: 32
                    }
                  }
                }
              }
            },
            ".drawer-footer": {
              display: "none",
            },
          },
        },
      },
    },
    ".sort-filter":{
      justifyContent: 'space-between',
      ".MuiTypography-root": {
          whiteSpace: "nowrap"
        },
      ".u-form-group": {
        width: 'auto',
        ".u-form-control": {
          ".MuiSelect-multiple": {
            maxWidth: 50
          }
        }
      }
    }, 
    ".u-form-group .u-form-control.u-select": {
      height: 20,
      ".MuiSelect-select": {
        fontWeight: theme.customVariables.fontWeightSemiBold,
        color: theme.palette.grey[900],
      },
      ".MuiOutlinedInput-notchedOutline": {
        border: "0 solid transparent",
      },
    },
    ".tags": {
      ".btn": {
        backgroundColor: "#F5F5F7",
        borderRadius: 24,
        color: theme.palette.grey[600],
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightRegular,
        float: "left",
        margin: "0 16px 16px 0",
        padding: "8px 12px 8px 12px",
        "&.active": {
          backgroundColor: theme.palette.grey[900],
          color: theme.palette.common.white,
        },
      },
    },
  },
}));

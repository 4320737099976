import { IncomeInfo } from "@src/types/customer";
import { camelCase } from "lodash";
export const cleanIncomeInfo = (income_info: IncomeInfo) => {
  const {
    employment_type,
    name,
    phone,
    employed_since,
    occupation,
    annual_employment_income,
    annual_other_income,
    source_of_other_income,
  } = income_info;

  return {
    employment_type,
    name,
    phone,
    employed_since,
    occupation,
    annual_employment_income,
    annual_other_income,
    source_of_other_income,
  };
};

export const filterCustomerData = ({
  first_name,
  middle_name,
  last_name,
  ssn,
  customer_type,
  date_of_birth,
  mobile_number,
  home_number,
  preferred_contact,
  reference_id,
  identifier,
  email,
  customer_addresses,
  income_info,
  payments_info,
  license_info,
}) => {
  const addressByType = customer_addresses.reduce((acc, address) => {
    const {
      address_type,
      created_at,
      created_by,
      updated_at,
      updated_by,
      deleted_at,
      deleted_by,
      entity_type,
      ...rest
    } = address;
    if (address_type) {
      acc[camelCase(address_type)] = {
        ...rest,
        entity_type: entity_type ?? null,
      };
    }
    return acc;
  }, {});
  return {
    first_name,
    middle_name,
    last_name,
    customer_type,
    date_of_birth,
    ssn,
    mobile_number,
    home_number,
    preferred_contact,
    reference_id,
    identifier,
    email,
    license_info,
    payments_info,
    ...(customer_addresses && { customer_addresses: addressByType }),
    ...(income_info && { income_info: cleanIncomeInfo(income_info) }),
  };
};

export const formatUSPhoneNumberWithCountryCode = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  if (!phoneNumberString) {
    return "";
  }
  if (cleaned.startsWith("1") && cleaned.length === 11) {
    const match = cleaned.match(/^(1)(.{3})(.{3})(.{4})$/);
    if (match) {
      return (
        "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4]
      );
    }
  } else {
    const match = cleaned.match(/^(.{3})(.{3})(.{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
  }
  return phoneNumberString;
};

export function collectionIntoSelectOptions(options, labelKey, valueKey) {
  return options.map((option) => ({
    label: option[labelKey], // Mapping dynamic label key
    value: option[valueKey], // Mapping dynamic value key
  }));
}
export const removeNullValues = (obj) => {
  // Create a new object to store non-null values
  const result = {};

  // Iterate through each key in the object
  for (let key in obj) {
    // Check if the value is an object and recurse on it
    if (obj[key] && typeof obj[key] === "object") {
      result[key] = removeNullValues(obj[key]);
    } else if (obj[key] !== null) {
      // Only keep non-null values
      result[key] = obj[key];
    }
  }

  return result;
};
export function getMappedOptionValue(
  options: any[],
  selectedStateName: string
) {
  // Find the option whose label matches the selected state_name
  const selectedOption = options.find(
    (option) => option.label === selectedStateName
  );
  // If a match is found, return the value of the selected option
  return selectedOption ? selectedOption.value : undefined;
}

export const getMoveInDate = (moveInDuration: string) => {
  let date = "";
  if (moveInDuration && moveInDuration?.toString()?.length > 3) {
    const month = parseInt(moveInDuration?.toString().slice(0, 2));
    let year = parseInt(moveInDuration?.toString().slice(2, 6));
    date = `${year}-${month}-${1}`;
    return date;
  }
  return date;
};

export const setMoveInDate = (moveInDate: string) => {
  let monthYear = "";
  if (moveInDate?.length > 0) {
    if (
      parseInt(moveInDate?.toString()?.slice(0, 4)) > new Date().getFullYear()
    ) {
    }
    monthYear =
      moveInDate?.toString()?.slice(5, 7) + moveInDate?.toString()?.slice(0, 4);
  }
  return monthYear;
};

export const Months = (move_in_duration) => {
  const year = parseInt(move_in_duration.slice(2, 6), 10);
  const month = parseInt(move_in_duration.slice(0, 2), 10) - 1;
  const parsedDate = new Date(year, month, 1);
  const currentDate = new Date();
  const totalMonths =
    (currentDate.getFullYear() - parsedDate.getFullYear()) * 12 +
    currentDate.getMonth() -
    parsedDate.getMonth();
  return totalMonths;
};

export function calculateDateDifference(dateInput) {
  // Get the current date
  const currentDate = new Date();

  // Convert the input date string to a Date object
  const inputDate = new Date(dateInput);

  // Calculate the difference in years
  let years = currentDate.getFullYear() - inputDate.getFullYear();
  let months = currentDate.getMonth() - inputDate.getMonth();
  let days = currentDate.getDate() - inputDate.getDate();

  // Adjust for months
  if (months < 0) {
    years--;
    months += 12;
  }

  // Adjust for days
  if (days < 0) {
    months--;
    // Get the number of days in the previous month
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      0
    );
    days += prevMonth.getDate();
  }

  // Return the result in "X year(s), Y month(s)" format
  return `${years} year(s), ${months} month(s), ${days} day(s)`;
}

export const formatCurrency = (value) => {
  if (value === "" || value == null) return ""; // Prevent empty string from causing formatting errors
  const numberValue = parseFloat(
    Number(value) ? value : value.replace(/[^0-9.-]+/g, "") || 0
  );
  return numberValue.toLocaleString("en-US", {
    style: "currency",
    currency: sessionStorage.getItem("defaultCurrency") ?? "USD",
  });
};

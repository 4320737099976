import { Radio } from "@src/components/atoms";
import "./radio-selector.scss";

export const RadioSelector = () => {
  return (
    <div className="radio-card">
      <div className="radio-card__options">
        <div className="radio-card__option">
          <Radio
            label="For Myself"
            checked={true}
            name="vehicle"
            id="option1"
            htmlFor="option1"
          />
          <div className="radio-card__option__radio-wrapper__divider" />
          <p className="radio-card__option__radio-wrapper__description text--body-2">
            You are the sole person responsible for this account and vehicle.
          </p>
        </div>
        <div className="radio-card__option">
          <Radio
            label="For Myself &amp; Co-applicant"
            name="vehicle"
            id="option2"
            htmlFor="option2"
          />
          <div className="radio-card__option__radio-wrapper__divider" />
          <p className="radio-card__option__radio-wrapper__description text--body-2">
            You and a co-buyer will share financial responsibility.
          </p>
        </div>
      </div>
    </div>
  );
};

import clsx from "clsx";
import { DatePicker as AntDatepikcer } from "antd";
import { DatepickerProps } from "./date-picker.props";
import dayjs from "dayjs";

export const Datepicker = ({
  className,
  id,
  label,
  name,
  value,
  placeholder,
  open,
  onChange,
  status,
  error,
  disabled,
  showInfoIcon,
  prevIcon,
  nextIcon,
  helpText,
  format = "YYYY-MM-DD",
  ...rest
}: DatepickerProps) => {
  const formattedValue = value ? dayjs(value) : null;
  const handleChange = (date: any, dateString: string) => {
    if (onChange) {
      onChange(dayjs(date).format("YYYY-MM-DD"), dateString); // Ensure onChange expects formatted dateString
    }
  };
  return (
    <div className="date-picker-wrap">
      {label && (
        <label
          className={clsx({
            "input-label text--label text-secondary": true,
          })}
        >
          <span>{label}</span>
          {showInfoIcon && (
            <div
              className="input-label-icon"
              role="img"
              aria-label="Icon for information"
              data-icon="information"
            />
          )}
        </label>
      )}
      <AntDatepikcer
        className={clsx(className, {
          "input--input-error": status === "error",
        })}
        onChange={handleChange}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        id={id}
        format={format}
        allowClear={false}
        value={formattedValue}
        open={open}
        suffixIcon={
          <div
            className="bmw-icon bmw-icon--before data-icon"
            data-icon="calendar"
          />
        }
        prevIcon=<div
          className="bmw-icon bmw-icon--before data-icon"
          data-icon={prevIcon}
        />
        nextIcon=<div
          className="bmw-icon bmw-icon--before data-icon"
          data-icon={nextIcon}
        />
        getPopupContainer={(trigger) => document.body}
      />
      {helpText && (
        <label className="help-text text-secondary text--disclaimer">
          {helpText}
        </label>
      )}
      {error && error.message && (
        <label className="error-text text-error text--disclaimer">
          {error.message}
        </label>
      )}
    </div>
  );
};

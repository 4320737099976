import { useEffect } from "react";
import { Accordion } from "../../utils/accordion/accordion";
import { Address } from "../address/address";
import { Tooltip, Checkbox, Button } from "@src/components/atoms";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { useAppContext } from "@src/context/app-context";
import { useCustomerUpdate } from "@src/hooks";
import { updateCustomer } from "@src/context/app-actions";
import { filterCustomerData, Months } from "@src/helpers/appHelper";
import { yupResolver } from "@hookform/resolvers/yup";
import { addressValidator } from "@src/validators";
import "./current-and-past-addresses.scss";
import { CustomerAddresses } from "@src/types/customer";
import dayjs from "dayjs";
import { Col } from "antd";
export const CurrentAndPastAddresses = ({
  stepTwoDataReceivedEvent,
}: {
  stepTwoDataReceivedEvent: () => void;
}) => {
  const {
    state: {
      user: { customer },
      tenant: { min_months_stay_at_address },
      dealer: { dealer_code },
      states,
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { customer_addresses, reference_id } = customer;
  const { control, handleSubmit, watch, setValue } = useForm<any>({
    resolver: yupResolver(addressValidator),
    defaultValues: {
      previous: customer_addresses.previous,
      mailing: customer_addresses.mailing,
      billing: customer_addresses.billing,
      garaging: customer_addresses.garaging,
      billingSameAsMailing: !!customer_addresses.billing?.entity_type,
      garagingSameAsMailing: !!customer_addresses.garaging?.entity_type,
    },
  });

  const { mutate: updateCustomerData, isPending } = useCustomerUpdate({
    reference_id: reference_id,
    dealer_code: dealer_code,
  });

  const billingSameAsMailing = watch("billingSameAsMailing");
  const garagingSameAsMailing = watch("garagingSameAsMailing");
  const previous = watch("previous");
  const garaging = watch("garaging");
  const billing = watch("billing");

  const generateAddress = (type: string, isSame: boolean, address: any) => {
    if (isSame) {
      return { address_type: type, entity_type: "Mailing" };
    }
    return { address_type: type, ...address, entity_type: null };
  };
  // Commenting for now
  // useEffect(() => {
  //   const move_in_duration = watch("mailing")?.move_in_duration;
  //    if(move_in_duration  && min_months_stay_at_address){
  //       const months = Months(move_in_duration)
  //       setValue('billing', { ...mailingWithoutMoveInDate });
  //       //setPrevious(months < LenderConfigurations?.data?.min_months_stay_at_address)
  //   }
  // }, [watch("mailing").move_in_duration]);

  const onSubmit: SubmitHandler<any> = (values) => {
    const customerAddresses = [
      {
        address_type: "Mailing",
        ...values.mailing,
        // Commenting for now
        move_in_date: dayjs(values.mailing.move_in_date).format("YYYY-MM-DD"),
        //move_in_date: values.mailing.move_in_date ? getMoveInDate(values.mailing.move_in_date) : null,
      },
      generateAddress("Billing", billingSameAsMailing, values.billing),
      generateAddress("Garaging", garagingSameAsMailing, values.garaging),
    ];

    updateCustomerData(
      {
        customer_addresses: customerAddresses,
        type: "",
      },
      {
        onSuccess: (data) => {
          const customerData = filterCustomerData(data);
          const { financial_profile } = customer;

          appDispatch(
            updateCustomer({
              ...customerData,
              financial_profile: financial_profile,
            })
          );
          stepTwoDataReceivedEvent();
        },
        onError: (error) => {
          console.error("Error fetching customer:", error);
        },
      }
    );
  };
  return (
    <div className="checkout">
      <div>
        <div className="checkout-form-header checkout-form-header--padding">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            02
          </h3>
          <h3 className="checkout-title text--subsection-2">Addresses.</h3>
        </div>
        <div>
          <div className="checkout-info-wrapper">
            <p className="checkout-subtitle text--body-2">
              Please provide your current permanent address.
              {/* <i className="checkout-info-icon" />
              <Tooltip /> */}
            </p>
            <p className="checkout-info text--disclaimer">
              <i className="checkout-title-icon" />
              If you've lived at this address for less than 11 months, you'll
              need to enter your previous address as well.
            </p>
          </div>
        </div>
      </div>
      <div className="checkout-initial-form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="checkout-form">
          <Address type="mailing" control={control} stateOptions={states} />

          <div className="checkout-form-spacing checkout-column-wrapper">
            <Controller
              name="mailing.move_in_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Datepicker
                  id="move_in_date"
                  placeholder="Choose"
                  label="Move in date"
                  error={error}
                  format="MMM DD, YYYY"
                  {...field}
                />
              )}
            />
          </div>
          {!billingSameAsMailing && <hr className="checkout-hr" />}

          {/*<MoveDateAddress />*/}
          {billing && (
            <div className={billingSameAsMailing ? "billing-address-wrap" : ""}>
              <div className="checkout-billing-description">
                Please Provide your billing address
              </div>
              <Controller
                name="billingSameAsMailing"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Checkbox
                    id={"provdeBillingChk"}
                    checked={value}
                    label="Same as current (mailing) address"
                    htmlFor="provdeBillingChk"
                    {...rest}
                  />
                )}
              />
              {!billingSameAsMailing && (
                <Address
                  type="billing"
                  control={control}
                  stateOptions={states}
                />
              )}
            </div>
          )}
          {!garagingSameAsMailing && <hr className="checkout-hr" />}

          {garaging && (
            <div className="garging-address-wrap">
              <div className="checkout-billing-description">
                Please Provide your garaging address
              </div>
              <Controller
                name="garagingSameAsMailing"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Checkbox
                    id="provdeMailingChk"
                    label="Same as current (mailing) address"
                    checked={value}
                    htmlFor="provdeMailingChk"
                    {...rest}
                  />
                )}
              />
              {!garagingSameAsMailing && (
                <Address
                  type="garaging"
                  control={control}
                  stateOptions={states}
                />
              )}
            </div>
          )}
          {!(billing || garaging) && (
            <p className="text--body-2 different-billing-garging">
              If your garaging or billing address is different from your
              permanent address, please provide them below.
              <i className="checkout-info-icon" /> <Tooltip />
            </p>
          )}
          <div className="checkout-accordion-container">
            <Accordion
              billingAddress={billing}
              garagingAddress={garaging}
              addBillingAddressHandler={() => {
                const { move_in_date, ...mailingWithoutMoveInDate } =
                  customer_addresses.mailing;
                setValue("billing", { ...mailingWithoutMoveInDate });
              }}
              addGaragingAddressHandler={() => {
                const { move_in_date, ...mailingWithoutMoveInDate } =
                  customer_addresses.mailing;
                setValue("garaging", { ...mailingWithoutMoveInDate });
              }}
            />
          </div>

          <div className="form-wrapper form-wrapper--button-top-spacer">
            <Button
              type="default"
              isLoading={isPending}
              htmlType="submit"
              text="Save & continue"
              fixed
            />
          </div>
        </form>
      </div>
    </div>
  );
};

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PhoneInputProps } from "./phone-input.props";
import "../../../styles/components/_phone-input.scss";
import clsx from "clsx";
import { useState } from "react";
import { validatePhoneNumber } from "@src/helpers";

export const Phone = ({
  country,
  value: initialValue,
  onChange,
  placeholder,
  countryCodeEditable = false,
  disabled,
  enableSearch,
  disableSearchIcon,
  inputProps,
  label,
  showInfoIcon,
  helpText,
  formState,
  status,
  trigger,
  setPhoneNumberError,
}: PhoneInputProps) => {
  const isValidPhoneNumber = (inputNumber, country, countries) => {
    const phoneLength = Math.ceil(
      (
        countries.filter(
          (val: any) => val.dialCode === country.dialCode
        )[0] as any
      )?.format.length / 2
    );
    const phoneNumberError = validatePhoneNumber(
      inputNumber,
      country,
      phoneLength,
      formState?.isDirty
    );

    setPhoneNumberError && setPhoneNumberError(phoneNumberError);
    return phoneNumberError;
  };

  const isValid = formState?.isDirty
    ? (inputNumber, country, countries) =>
        isValidPhoneNumber(inputNumber, country, countries)
    : undefined;

  const handlePhoneChange = (newValue) => {
    trigger && trigger();
    if (onChange) {
      onChange(newValue); // Trigger external onChange handler
    }
  };

  return (
    <div
      className={clsx({
        "phone-input-wrap": true,
        "phone-input-disabled": disabled,
        "phone-input-invalid": status === "error",
      })}
    >
      {label && (
        <label
          className={clsx({
            "phone-label text--label text-secondary": true,
          })}
        >
          <span>{label}</span>
          {showInfoIcon && (
            <div
              className="phone-label-icon"
              role="img"
              aria-label="Icon for information"
              data-icon="information"
            />
          )}
        </label>
      )}
      <PhoneInput
        country={country}
        value={initialValue}
        onChange={handlePhoneChange}
        placeholder={placeholder}
        disabled={disabled}
        countryCodeEditable={countryCodeEditable}
        enableSearch={enableSearch}
        disableSearchIcon={disableSearchIcon}
        isValid={isValid}
        specialLabel=""
        inputProps={inputProps}
      />
      {helpText && (
        <label className="help-text text-secondary text--disclaimer">
          {helpText}
        </label>
      )}
      {formState?.error && formState?.error.message && (
        <label className="error-text text-error text--disclaimer">
          {formState.error.message}
        </label>
      )}
    </div>
  );
};

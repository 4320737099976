import { memo } from "react";
import { Step } from "@src/components/atoms/step/step.component";
import { RadioSelector, PersonalDetails } from "@src/components/molecules";
import { useForm, SubmitHandler } from "react-hook-form";
import "./vehicle-ownership.style.scss";
import { BasicCustomerInfo } from "@src/types/customer";
import { useCustomerUpdate } from "@src/hooks/customer";
import { useAppContext } from "@src/context/app-context";
import { filterCustomerData } from "@src/helpers/appHelper";
import { updateCustomer } from "@src/context/app-actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicValidator } from "@src/validators";

const VehicleOwnership = memo(
  ({
    stepOneDataReceivedEvent,
    customerInfo,
  }: {
    customerInfo: Omit<BasicCustomerInfo, "email"> & { updated_by: string };
    stepOneDataReceivedEvent: () => void;
  }) => {
    const {
      control,
      handleSubmit,
      trigger,
      formState: { errors },
    } = useForm<any>({
      resolver: yupResolver(basicValidator),
      defaultValues: customerInfo,
    });
    const { state: appState, dispatch: appDispatch } = useAppContext();
    const { mutate: updateCustomerData, isPending } = useCustomerUpdate({
      reference_id: appState.user.customer.reference_id,
      dealer_code: appState.dealer.dealer_code,
    });

    const onSubmit: SubmitHandler<BasicCustomerInfo> = (values) => {
      updateCustomerData(
        {
          customer_profile: values,
          type: "",
        },
        {
          onSuccess: (data) => {
            const customerData = filterCustomerData(data);
            const { financial_profile } = appState.user.customer;

            appDispatch(
              updateCustomer({
                ...customerData,
                financial_profile: financial_profile,
              })
            );
            stepOneDataReceivedEvent();
          },
          onError: (error) => {
            console.error("Error fetching customer:", error);
          },
        }
      );
    };

    return (
      <div className="who-i-am">
        <div className="who-i-am section-wrapper">
          <Step />
          <RadioSelector />
        </div>
        <PersonalDetails
          formControl={control}
          showSSNValue={!!customerInfo.ssn}
          isPending={isPending}
          trigger={trigger}
          handleSubmit={handleSubmit(onSubmit)}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if `customerInfo` has changed
    return prevProps.customerInfo === nextProps.customerInfo;
  }
);

export { VehicleOwnership };

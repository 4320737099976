import { Col, Row } from "antd";
import "./incentives-and-discounts.scss";
import {
  FixedVehicleCard,
  VehicleCard,
  WelcomeBanner,
} from "@src/components/molecules";
import { IncentivesListCard } from "@src/components/molecules/incentives-list-card/incentives-list-card.component";
import { Button, Checkbox, Drawer, FeedbackLabel } from "@src/components/atoms";

import Image1 from "../../../../shared/assets/img/incentives-discounts-list-img1.png";
import Image2 from "../../../../shared/assets/img/incentives-discounts-list-img2.png";
import Image3 from "../../../../shared/assets/img/incentives-discounts-list-img3.png";
import Image4 from "../../../../shared/assets/img/incentives-discounts-list-img4.png";
import Image5 from "../../../../shared/assets/img/incentives-discounts-list-img5.png";
import { FullDetailsDrawerContent } from "@src/components/molecules/incentives-and-discounts/full-details-drawer-content/full-details-drawer-content";
import { InsentivesAndDiscountsDrawerFooter } from "@src/components/molecules/incentives-and-discounts/insentives-and-discounts-drawer-footer/insentives-and-discounts-drawer-footer.component";
import { useState } from "react";
import { UpdatePaymentTermsDrawerContent } from "@src/components/molecules/incentives-and-discounts/update-payment-terms-drawer-content/update-payment-terms-drawer-content";
import { FinanceLeasePricingDrawer } from "@src/components/molecules/incentives-and-discounts/finance-lease-pricing-drawer/finance-lease-pricing-drawer.component";

export const IncentivesAndDiscounts = () => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Function to toggle the drawer visibility
  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };
  const toggleFLPricingDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setIsDrawerOpen(false);
  };
  return (
    <>
      <WelcomeBanner
        hasCloseIcon={true}
        title={"Find even more savings."}
        decription={
          "Plus, unlock personalized pricing and save your progress automatically if you sign in."
        }
        actionArea={
          <>
            <Button text="Sign in" type="default" htmlType="button" />
            <Button text="Register" htmlType="button" />
          </>
        }
      />
      <div className="incentives-and-discounts-wrap">
        <div className="bmw-container">
          <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
            <Col xs={24} sm={24} md={24} lg={16}>
              <div className="incentives-discounts-left-content">
                <h2 className="incentives-discounts--title text--h3">
                  Incentives & discounts
                </h2>
                <div className="incentives-discounts--list_wrap incentives-discounts--two_grid_sm">
                  <IncentivesListCard
                    image={Image1}
                    title={"New York energy incentives"}
                    subTitle={
                      "Residents of New York state may qualify for several EV energy incentives, including the Drive Clean Rebate. "
                    }
                    price={<h2 className="text--body-focus-price-1">$X,XXX</h2>}
                    className=""
                    hasCheckbox={
                      <Checkbox
                        id={"incentives-discounts--chk1"}
                        label="Select"
                        htmlFor="incentives-discounts--chk1"
                      />
                    }
                    actionsArea={
                      <Button
                        htmlType={"button"}
                        text="Full details"
                        linkButton
                        onClick={toggleFLPricingDrawer}
                      />
                    }
                  />
                  <IncentivesListCard
                    image={Image2}
                    title={"Purchase credit"}
                    subTitle={
                      "Certain BMWs might qualify for a purchase credit, reducing the final purchase price on your new or Certified Pre-Owned BMW."
                    }
                    price={<h2 className="text--body-focus-price-1">$X,XXX</h2>}
                    className=""
                    hasCheckbox={
                      <Checkbox
                        id={"incentives-discounts--chk1"}
                        label="Select"
                        htmlFor="incentives-discounts--chk1"
                      />
                    }
                    actionsArea={
                      <Button
                        htmlType={"button"}
                        text="Full details"
                        linkButton
                        onClick={toggleDrawer}
                      />
                    }
                  />
                  <IncentivesListCard
                    image={Image3}
                    title={"Loyalty credit"}
                    subTitle={
                      "Loyal current and former BMW Owners may qualify for exclusive savings."
                    }
                    price={<h2 className="text--body-focus-price-1">$X,XXX</h2>}
                    className=""
                    hasCheckbox={
                      <Checkbox
                        id={"incentives-discounts--chk1"}
                        label="Select"
                        htmlFor="incentives-discounts--chk1"
                      />
                    }
                    actionsArea={
                      <Button
                        htmlType={"button"}
                        text="Full details"
                        linkButton
                        onClick={toggleDrawer}
                      />
                    }
                  />
                  <IncentivesListCard
                    image={Image4}
                    title={"Conquest credit"}
                    subTitle={
                      "If you currently own or lease a non-BMW, you may qualify for a special Conquest credit to lease or purchase a BMW."
                    }
                    price={<h2 className="text--body-focus-price-1">$X,XXX</h2>}
                    className=""
                    isCardDisabled
                    feedbackLable={
                      <FeedbackLabel
                        text={
                          "[Incentive name] can not be combined with [incentive name]"
                        }
                        type="warning"
                      />
                    }
                    hasCheckbox={
                      <Checkbox
                        id={"incentives-discounts--chk1"}
                        label="Select"
                        htmlFor="incentives-discounts--chk1"
                      />
                    }
                    actionsArea={
                      <Button
                        htmlType={"button"}
                        text="Full details"
                        linkButton
                        onClick={toggleDrawer}
                      />
                    }
                  />
                  <IncentivesListCard
                    image={Image5}
                    title={"BMW college graduate program"}
                    subTitle={
                      "Recent graduates can celebrate with special savings on a new or Certified Pre-Owned BMW."
                    }
                    price={<h2 className="text--body-focus-price-1">$X,XXX</h2>}
                    className=""
                    hasCheckbox={
                      <Checkbox
                        id={"incentives-discounts--chk1"}
                        label="Select"
                        htmlFor="incentives-discounts--chk1"
                      />
                    }
                    actionsArea={
                      <Button
                        htmlType={"button"}
                        text="Full details"
                        linkButton
                        onClick={toggleDrawer}
                      />
                    }
                  />
                </div>

                <div className="incentives-discounts--ctas">
                  <div className="incentives-discounts--ctas_flexible">
                    <div>
                      <Button
                        htmlType={"button"}
                        text="Continue"
                        type="primary"
                        fixed
                        fullWidth
                      />
                    </div>
                    <div>
                      <Button
                        htmlType={"button"}
                        text="Sign in to save"
                        fullWidth
                      />
                    </div>
                  </div>
                  <p className="text--disclaimer text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div className="address-right-content">
                <div className="address-right-content__inner">
                  <VehicleCard cashDownInfo hasFooter />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <FixedVehicleCard />
      </div>
      <div className="incentives-and-discount-drawer">
        <Drawer
          width={336}
          open={isDrawerOpen}
          onClose={closeDrawer}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <FinanceLeasePricingDrawer />
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <small className="text--disclaimer">Est. payments</small>
                    <h2 className="text--body-focus-price-1">
                      $X,XXX
                      <span className="text--body-1">/month</span>{" "}
                    </h2>
                  </>
                }
              />
            </>
          }
        />
        <Drawer
          width={336}
          open={isDrawerVisible}
          onClose={closeDrawer}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <FullDetailsDrawerContent />
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <small className="text--disclaimer">Est. payments</small>
                    <h2 className="text--body-focus-price-1">
                      $X,XXX
                      <span className="text--body-1">/month</span>{" "}
                    </h2>
                  </>
                }
                footerActions={
                  <Button
                    htmlType={"button"}
                    text="Save & close"
                    type="default"
                    onClick={toggleDrawer}
                  />
                }
              />
            </>
          }
        />
        <Drawer
          width={336}
          open={false}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <UpdatePaymentTermsDrawerContent />
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <small className="text--disclaimer">Est. payments</small>
                    <h2 className="text--body-focus-price-1">
                      $X,XXX
                      <span className="text--body-1">/month</span>{" "}
                    </h2>
                  </>
                }
                footerActions={
                  <Button
                    htmlType={"button"}
                    text="Save & close"
                    type="default"
                  />
                }
              />
            </>
          }
        />
      </div>
    </>
  );
};

import { Button, Image } from "@src/components/atoms";
import "./vehicle-card.style.scss";
import Image_URL from "../../../shared/assets/img/vehicle-large.png";
import FinanceImage from "../../../shared/assets/img/finance.png";
import { VehicleCardProps } from "./vehicle-card.props";

export const VehicleCard = ({
  hasHeader,
  hasFooter,
  vehicleInfo = true,
  vehicleImg = true,
  vehicleFinanceImg,
  hasVehicleFinancing,
  cardPaymentInfo,
  cashDownInfo,
  cashOfferInfo,
}: VehicleCardProps) => {
  return (
    <div className="vehicle-card">
      {hasHeader && (
        <div className="vehicle-card-header text--body-2">
          <span className="vehicle-card-reservation-title">
            Vehicle reserved for
          </span>
          <span className="vehicle-card-reservation-time">00:29:46</span>
        </div>
      )}
      <div className="vehicle-card-inner-container">
        {vehicleInfo && (
          <>
            <h4 className="vehicle-card-title text--subsection-1">
              2024 330i xDrive Sedan
            </h4>
            <div className="vehicle-subtitle-container">
              <div className="vehicle-card-state-container">
                <span className="vehicle-card-state text--disclaimer">
                  At Dealership
                </span>
              </div>
              <span className="vehicle-card-vin text--disclaimer">
                VIN#: 3MW89FF00P8C84861
              </span>
            </div>
          </>
        )}
        {vehicleImg && (
          <div className="vehicle-img">
            <Image
              src={Image_URL}
              alt="Vehicle"
              aspectRatio="16:9"
              containedImage
            />
          </div>
        )}
        {vehicleFinanceImg && (
          <img
            className="vehicle-card-loyalty-image"
            src={FinanceImage}
            alt="Loyalty Card"
          />
        )}
        <div className="vehicle-card-payment-info-row">
          <span className="vehicle-card-financing-title text--body-1 vehicle-card-info vehicle-card-info-icon bmw-icon bmw-icon--after">
            Est. Financing
          </span>
          <span className="vehicle-card-financing-value text--body-focus-price-1">
            $585/month
          </span>
        </div>
        {hasVehicleFinancing && (
          <div className="vehicle-card-payment-info-row">
            <span className="vehicle-card-financing-title" />
            <div className="vehicle-card-financing-container">
              <span className="vehicle-card-payment-value text--body-focus-price vehicle-card-up-arrow bmw-icon bmw-icon--before">
                $10
              </span>
              <span className="vehicle-card-payment-value vehicle-card-offer-text text--disclaimer">
                /month
              </span>
            </div>
          </div>
        )}
        {cardPaymentInfo && (
          <>
            <div className="vehicle-card-payment-info-row">
              <span className="vehicle-card-payment-title text--body-2 vehicle-card-info vehicle-card-info-icon bmw-icon bmw-icon--after">
                MSRP
              </span>
              <span className="text--body-focus-price">$48,670</span>
            </div>
            <hr className="vehicle-card-separator" />
            <div className="vehicle-card-payment-info-row">
              <span className="vehicle-card-payment-title text--body-2">
                Cash Down
              </span>
              <span className="text--body-focus-price">$15,000</span>
            </div>
            <div className="vehicle-card-payment-info-row">
              <span className="vehicle-card-payment-title text--body-2">
                Term
              </span>
              <span className="text--body-focus-price">60 months</span>
            </div>
            <div className="vehicle-card-cta">
              <Button
                htmlType={"button"}
                text="Details"
                size="small"
                className="vehicle-card-cta"
              />
            </div>
          </>
        )}
        {/* Financing detail */}
        {cashDownInfo && (
          <div className="financing-cash-info">
            <ul>
              <li>MSRP $ 48,670</li>
              <li>60 month term</li>
              <li>$15,000 cash down</li>
            </ul>
            <Button htmlType={"button"} text="Update" />
          </div>
        )}
        {/* Cash Offer */}
        {cashOfferInfo && (
          <div className="cash-offer">
            <div className="vehicle-card-payment-info-row">
              <span className="vehicle-card-financing-title text--body-1 vehicle-card-info vehicle-card-info-icon bmw-icon bmw-icon--after">
                Cash offer
              </span>
              <span className="vehicle-card-financing-value text--body-focus-price-1">
                $XX,XXX
              </span>
            </div>
            <div>
              <Button
                htmlType={"button"}
                text="Update"
                size="small"
                className="vehicle-card-cta cash-offer__btn"
              />
            </div>
          </div>
        )}
      </div>

      {/* Footer */}
      {hasFooter && (
        <div className="vehicle-card-footer">
          <p className="vehicle-card__value text-white text--body-2">
            <span
              className="bmw-icon bmw-icon--before data-icon"
              data-icon="search_offer"
            ></span>{" "}
            APR X.XX%
          </p>
          <p className="vehicle-card__value text-white text--body-2">
            Get accurate APR %{" "}
            <span
              className="bmw-icon bmw-icon--before data-icon"
              data-icon="arrow_chevron_right"
            ></span>
          </p>
        </div>
      )}
    </div>
  );
};

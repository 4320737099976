export enum ActionType {
  LOAD_CHAT = "LOAD_CHAT",
  LOAD_TENANT_CONFIGURATIONS = "LOAD_TENANT_CONFIGURATIONS",
  LOAD_CURRENCY_CONFIGURATIONS = "LOAD_CURRENCY_CONFIGURATIONS",
  CHANGE_USER_LOGGED_IN_STATE = "CHANGE_USER_LOGGED_IN_STATE",
  UPDATE_HEADER_FOOTER = "UPDATE_HEADER_FOOTER",
  UPDATE_STATES_OPTIONS = "UPDATE_STATES_OPTIONS",
  UPDATE_CUSTOMER = "UPDATE_CUSTOMER",
  UPDATE_COLLAPSE_STATE = "UPDATE_COLLAPSE_STATE",
  UPDATE_DEALER_CODE = "UPDATE_DEALER_CODE",
  LOAD_DEFAULT_LENDER_CONFIGURATIONS = "LOAD_DEFAULT_LENDER_CONFIGURATIONS",
  UPDATE_TENANT_THEME = "UPDATE_TENANT_THEME",
  PLACEHOLDERS = "PLACEHOLDERS",
  ORDER_FRAUD_STATUS = "ORDER_FRAUD_STATUS",
  ORDER_INFO = "ORDER_INFO",
  ORDER_INSURANCE = "ORDER_INSURANCE",
  DEALER_CODE = "DEALER_CODE",
  UPDATE_ORDER_INFO = "UPDATE_ORDER_INFO",
  UPDATE_INSURANCE = "UPDATE_INSURANCE",
  UPDATE_LENDERS = "UPDATE_LENDERS",
}

// Action methods
export const loadTenantConfigurations = (configurations: object) => ({
  type: ActionType.LOAD_TENANT_CONFIGURATIONS,
  payload: configurations,
});

export const loadDefaultLenderConfigurations = (configurations: object) => ({
  type: ActionType.LOAD_DEFAULT_LENDER_CONFIGURATIONS,
  payload: configurations,
});

export const loadDefaultCurrencyConfigurations = (curreny: object) => ({
  type: ActionType.LOAD_CURRENCY_CONFIGURATIONS,
  payload: curreny,
});

export const openChatBox = (ChatOpen: boolean) => ({
  type: ActionType.LOAD_CHAT,
  payload: ChatOpen,
});
export const changeUserLoggedInState = (isLoggedIn: boolean) => ({
  type: ActionType.CHANGE_USER_LOGGED_IN_STATE,
  payload: isLoggedIn,
});
export const updateHeaderFooter = (
  icons: {},
  headerLinks: [],
  footerLinks: [],
  dealorId
) => ({
  type: ActionType.UPDATE_HEADER_FOOTER,
  payload: {
    icons: icons,
    header_links: headerLinks,
    footer_links: footerLinks,
    dealor_id: dealorId,
  },
});

export const updateCustomer = (customerRes) => ({
  type: ActionType.UPDATE_CUSTOMER,
  payload: customerRes,
});
export const updateStates = (states) => ({
  type: ActionType.UPDATE_STATES_OPTIONS,
  payload: states,
});

export const setOrderInfo = (response) => ({
  type: ActionType.UPDATE_ORDER_INFO,
  payload: response,
});

export const updateCollapseState = () => ({
  type: ActionType.UPDATE_COLLAPSE_STATE,
});

export const updateDealerCode = (dealerCode) => ({
  type: ActionType.UPDATE_DEALER_CODE,
  payload: dealerCode,
});

export const updateTenantTheme = (theme) => ({
  type: ActionType.UPDATE_TENANT_THEME,
  payload: theme,
});

export const loadPlaceholder = (configurations: object) => ({
  type: ActionType.PLACEHOLDERS,
  payload: configurations,
});

export const orderFraudStatus = (status: string) => ({
  type: ActionType.ORDER_FRAUD_STATUS,
  payload: status,
});
export const orderInsurance = (insurance: any) => ({
  type: ActionType.ORDER_INSURANCE,
  payload: insurance,
});

export const updateInsuraneProviderOptions = (insurance_company: any) => ({
  type: ActionType.UPDATE_INSURANCE,
  payload: insurance_company,
});
export const updateLenders = (lenderdata: any) => ({
  type: ActionType.UPDATE_LENDERS,
  payload: lenderdata,
});

import { IApplicationState } from "@src/types";
import { ActionType } from "./app-actions";
import { initialState } from "./app-state";

export const appReducer = (
  state: IApplicationState = initialState,
  action: { type: ActionType; payload: any }
) => {
  switch (action.type) {
    case ActionType.LOAD_TENANT_CONFIGURATIONS:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          tenantId: action.payload.id,
          slug: action.payload.slug,
          defaultRadius: action.payload.default_radius,
          mileageUnit: action.payload.mileage_unit,
          tenantName: action.payload.name,
          email: action.payload.email,
          company_id: action.payload.id,
          companyCode: action.payload.company_code,
          min_months_stay_at_address: action.payload.min_months_stay_at_address,
          currency: {
            ...state.tenant.currency,
            defaultCurrency: action.payload.default_currency,
          },
        },
      };
    case ActionType.UPDATE_ORDER_INFO: {
      return {
        ...state,
        order: action.payload,
      };
    }
    case ActionType.LOAD_CHAT:
      return {
        ...state,
        openChat: action.payload,
      };
    case ActionType.LOAD_CURRENCY_CONFIGURATIONS:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          currency: {
            ...state.tenant.currency,
            defaultCurrencySymbol: action.payload.symbol,
            defaultCurrencyCode: action.payload.code,
          },
        },
      };
    case ActionType.CHANGE_USER_LOGGED_IN_STATE:
      return {
        ...state,
        isUserLoggedIn: action.payload,
      };
    case ActionType.UPDATE_HEADER_FOOTER: {
      return {
        ...state,
        social_media_icons: action.payload.icons,
        header_links: action.payload.header_links,
        footer_links: action.payload.footer_links,
        dealer_code: action.payload.dealor_id,
        collapse_state: false,
      };
    }
    case ActionType.UPDATE_CUSTOMER: {
      return {
        ...state,
        user: { ...state.user, customer: action.payload },
      };
    }
    case ActionType.UPDATE_STATES_OPTIONS: {
      return {
        ...state,
        states: action.payload,
      };
    }
    case ActionType.UPDATE_COLLAPSE_STATE:
      return {
        ...state,
        collapse_state: !state.ui.collapseState,
      };

    case ActionType.UPDATE_DEALER_CODE:
      return {
        ...state,
        dealer: { ...state.dealer, dealer_code: action.payload },
      };

    case ActionType.UPDATE_TENANT_THEME:
      const head = document.head;
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = action?.payload?.stylesheet;
      head.appendChild(link);
      return {
        ...state,
        theme: action.payload,
      };
    case ActionType.LOAD_DEFAULT_LENDER_CONFIGURATIONS:
      return {
        ...state,
        lender: {
          ...state.lender,
          defaultLender: action.payload,
        },
      };

    case ActionType.ORDER_FRAUD_STATUS:
      return {
        ...state,
        order: {
          ...state.order,
          status: action.payload,
        },
      };
    case ActionType.ORDER_INSURANCE:
      return {
        ...state,
        order: {
          ...state.order,
          Insurance_Inforation: {
            insurer_name: action.payload.insurer_name,
            identifier: action.payload.identifier,
            agent_phone: action.payload.agent_phone,
            order_reference_id: action.payload.order_reference_id,
            created_by: action.payload.created_by,
            updated_by: action.payload.updated_by,
          },
        },
      };
    case ActionType.UPDATE_LENDERS:
      return {
        ...state,
        lender: {
          ...state.lender,
          lenders: action.payload,
        },
      };
    case ActionType.UPDATE_INSURANCE:
      return {
        ...state,
        order: {
          ...state.order,
          company_provider: action.payload,
        },
      };
    default:
      return state;
  }
};

import { Col, Row } from "antd";
import { FullScreenContentProps } from "./full-screen-content.props";
import "./full-screen-content.scss";

export const FullScreenContent = ({
  title,
  subtitle,
  content,
  actionArea,
  footer,
}: FullScreenContentProps) => {
  return (
    <div className="full-screen-content-wrap">
      <div className="bmw-container">
        <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
          <Col xs={24} sm={24} md={24} lg={16}>
            <div className="bmw-container__inner-content">
              <div className="inner-content__title">
                {title && (
                  <div className="inner-content__title-text">{title}</div>
                )}
                {subtitle && (
                  <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
                    <Col xs={24} sm={24} md={24} lg={16} xxl={18}>
                      <div className="inner-content__subtitle-text">
                        {subtitle}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
              {content && (
                <div className="inner-content__content">
                  {content}
                  {actionArea && (
                    <div className="inner-content__content--action-area">
                      {actionArea}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="bmw-container__footer">
              {footer && <div className="inner-content__footer">{footer}</div>}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

import { useTheme } from "@mui/material";
import { OrderConfirmationLayout } from "../order-confirmation-layout/order-confirmation.component";
import { IDealerProfile, IVehicle } from "@interfaces";
import { useOrderContext } from "@pages";
import { LeadKey, Navigation, OrderStatus, QueryKeys } from "@constants";
import { useAppContext } from "@app/context-provider";
import {useEffect, useMemo, useReducer, useRef, useState} from "react";
import { Stipulation } from "./stipulation.component";
import {Box, Button, CircleLoader, Snackbar} from "@ntpkunity/controls";
import { useWorkflowContext } from "@app/router-workflow";
import { useLocation, useParams} from "react-router-dom";
import { formatUSPhoneNumberWithCountryCode } from "@helpers";
import DisableLoader from "../../src/shared/assets/loader-disabled.gif";
import { mapPayload } from "helpers/lead";
import { useUpdateLead } from "hooks/lead-management";
import { useQuery } from "react-query";
import {TaggingClasses} from "../../constants/enums";
import {SnackbarUtility} from "@utilities";
import {useCreateOrderContractDocument} from "../../hooks/order-management";
import clsx from 'clsx'

export const OrderInformation = ({
  vehicle,
  setOpenPopup,
  currency,
  setPaymentBreakdownPopup,
  dealerProfile,
  handleCallButtonClick,
  handleGetDirectionsClick,
  handleDealerNameLink,
  dealerByDealerCode,
}: {
  vehicle: IVehicle;
  setOpenPopup: any;
  currency: string;
  setPaymentBreakdownPopup: any;
  dealerProfile: IDealerProfile;
  handleCallButtonClick: any;
  handleGetDirectionsClick: any;
  handleDealerNameLink: any;
  dealerByDealerCode: any;
}) => {
  const theme = useTheme();
  const param = useParams();
  const creditInfo: any = useQuery([
    QueryKeys.GET_ORDER_STIPULATION_DATA,
    param.orderId,
  ]);

  const reSubmitRef = useRef<any>(null);
  const {
    state: { order },
    loading,
  } = useOrderContext();
  const { state: appState } = useAppContext();
  const PLACEHOLDERS = appState.language.placeholders;
  const { navigateNext } = useWorkflowContext();
  const { mutate: updateLead } = useUpdateLead(appState?.slug);
  const { vin, orderId } = useParams();
  const [isReSubmitEnabled, setIsReSubmitEnabled] = useState(false);
  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)

  const generateOrderText = (status) => {
    let status_change:any
    status_change = status

    switch (status_change) {
      case OrderStatus.COMPLETED:
        return {
          heading: PLACEHOLDERS.SUBMIT_ORDER_CONGRATS_ON_PURCHASE_COMPLETION,
          description:
            PLACEHOLDERS.SUBMIT_ORDER_PROCESSING_ORDER_CONFIRM_APPOINTMENT,
        };
        case OrderStatus.EXPIRED:
          return {
            heading: PLACEHOLDERS.EXPIRED_DEAL_HEADING_TEXT,
            description:
              PLACEHOLDERS.EXPIRED_DEAL_SUBTITLE_TEXT,
          };
      case OrderStatus.CANCELLED:
        return {
          heading: PLACEHOLDERS.CANCELLED_DEAL_HEADING_TEXT,
          description: order.reason ?? PLACEHOLDERS.CANCELLED_DEAL_SUBTITLE_TEXT
        };
      case OrderStatus.APPROVED:
        return {
          heading: PLACEHOLDERS.SUBMIT_ORDER_CONGRATULATIONS,
          congrats: PLACEHOLDERS.SUBMIT_ORDER_CREDIT_APP_APPROVED,
          description:
            PLACEHOLDERS.SUBMIT_ORDER_PAPERWORK_READY_FOR_REVIEW_AND_SIGNING,
        };
      case OrderStatus.DECLINED:
        return {
          heading: PLACEHOLDERS.SUBMIT_ORDER_CREDIT_APP_DECLINED,
          description: PLACEHOLDERS.SUBMIT_ORDER_NEED_MORE_INFO_FOR_CREDIT_APP,
        };
      case OrderStatus.Withdrawn:
        return {
          heading: PLACEHOLDERS.WITHDRAWN_DEAL_HEADING_TEXT,
          description: PLACEHOLDERS.WITHDRAWN_DEAL_SUBTITLE_TEXT,
        };

      case creditInfo?.data?.length === 0 && OrderStatus.CONDITIONED:
        return {
          heading: "",
          congrats: PLACEHOLDERS.SUBMIT_ORDER_CREDIT_APP_GENERAL_CONDITIONED,
          description: PLACEHOLDERS.SUBMIT_ORDER_SPECIFIC_CONDITIONS_MESSAGE,
        };
      case OrderStatus.CONDITIONED:
        return {
          heading: "",
          congrats: PLACEHOLDERS.SUBMIT_ORDER_CREDIT_APP_CONDITIONED,
          description: PLACEHOLDERS.SUBMIT_ORDER_PAPERWORK_READY_MESSAGE,
        };
      case OrderStatus.TradeInInspection:
        return {
          heading: PLACEHOLDERS.INSPECTION_TRADE_IN_ASSETS,
          description: PLACEHOLDERS.INSPECTION_TRADE_IN_ASSETS_DESCRIPTION,
        };
      case OrderStatus.FULLY_RECEIVED :
        return {
          heading: PLACEHOLDERS.FULLY_RECEIVED_DEAL_HEADING_TEXT,
          description: PLACEHOLDERS.FULLY_RECEIVED_DEAL_SUBTITLE_TEXT,
        };
      case OrderStatus.PendingConfirmation:
        return {
          heading: PLACEHOLDERS.PENDING_CONFIRMATION_DEAL_HEADING_TEXT,
          description: PLACEHOLDERS.PENDING_CONFIRMATION_DEAL_SUBTITLE_TEXT,
        };
      case OrderStatus.SUBMITTED:
        return {
          heading: PLACEHOLDERS.SUBMITTED_DEAL_HEADING_TEXT,
          description: PLACEHOLDERS.SUBMITTED_DEAL_SUBTITLE_TEXT,
        };
      default:
        return {
          heading: PLACEHOLDERS.SUBMIT_ORDER_CONGRATS_ON_PURCHASE_START,
          description:
            PLACEHOLDERS.SUBMIT_ORDER_REVIEW_CONFIRM_CHANGES_FINALIZE_ORDER,
        };
    }
  };

  useEffect(() => {
    if (heading === PLACEHOLDERS.SUBMIT_ORDER_CONGRATS_ON_PURCHASE_START) {
      const lead = mapPayload(
        +order?.dealer_id,
        appState.slug,
        order?.reference_id,
        LeadKey.Vehicle_Detail,
        "Completed",
        LeadKey.Status
      );
      updateLead(lead);
    }
  }, []);
  const { heading, congrats, description } = useMemo(
    () => generateOrderText(order?.status),
    [order?.status, creditInfo.isSuccess]
  );

  const handleContinueOrderClick = () => {
    navigateNext(Navigation.DEAL_SUMMARY_PAGE, { vin, orderId });
  };

  const setReSubmitRef = (ref) => {
    reSubmitRef.current = ref;
  };

  const handleReSubmitOrder = () => {
    if (reSubmitRef?.current) {
      reSubmitRef.current.reSubmitOrder();
    }
  };

  return (
      <>
    <OrderConfirmationLayout
      title={order?.order_asset?.trim_description}
      subtitle={`${order?.order_asset?.year ? order?.order_asset?.year : ""} ${
        order?.order_asset?.make ? order?.order_asset?.make : ""
      } ${order?.order_asset?.model ? order?.order_asset?.model : ""}`}
      onClick={() => setOpenPopup(true)}
      productImg={order?.order_asset?.photo_urls?.[0]?.location}
      heading={heading}
      congrats={congrats}
      description={description}
      terms={
        order?.status === OrderStatus.CONDITIONED && (
          <Stipulation
            currency={currency}
            setReSubmitRef={setReSubmitRef}
            setIsReSubmitEnabled={setIsReSubmitEnabled}
            isReSubmitEnabled={isReSubmitEnabled}
          />
        )
      }
      actionButtons={
        <Box theme={theme} className="action-btns">
          {(order?.status === OrderStatus.APPROVED ||
            order?.status === OrderStatus.CONDITIONED && order?.order_submissions
          .filter(submission => submission.lender_id === order?.lender_id)
          .map(submission => submission.decision.stipulations)
          .flat()?.length !==0 ) && (
            <Button
              className={clsx({[TaggingClasses.SUBMIT_ORDER_APPROVED]:true})}
              theme={theme}
              primary
              text={
                (order?.status === OrderStatus.APPROVED )
                  ? PLACEHOLDERS.SUBMIT_ORDER_CONTINUE_ORDER
                  : PLACEHOLDERS.SUBMIT_ORDER_RE_SUBMIT_ORDER
              }
              onClick={
                (order?.status === OrderStatus.APPROVED)
                  ? handleContinueOrderClick
                  : handleReSubmitOrder
              }
              disabled={
                order?.status === OrderStatus.CONDITIONED
                  ? isReSubmitEnabled || loading
                  : false
              }
              startIcon={loading && <img src={DisableLoader} alt="Loader" />}
            />
          )}
          {!(
            order?.status === OrderStatus.CANCELLED ||
            order?.status === OrderStatus.DECLINED
          ) && (
            <Button
              theme={theme}
              secondary
              text={PLACEHOLDERS.SUBMIT_ORDER_VIEW_ORDER_SUMMARY}
              onClick={() => setPaymentBreakdownPopup(true)}
            />
          )}
        </Box>
      }
      {...(order?.status === OrderStatus.CANCELLED ||
        order?.status === OrderStatus.DECLINED
        ? {
            chatBtnText: PLACEHOLDERS.SUBMIT_ORDER_START_CHAT,
            onChatClick: () => {},
            callBtnText: formatUSPhoneNumberWithCountryCode(
              dealerProfile?.customer_support_number
            ),
            onCallClick: handleCallButtonClick,
            directionBtnText: PLACEHOLDERS.SUBMIT_ORDER_GET_DIRECTIONS,
            onDirectionClick: handleGetDirectionsClick,
            linkDesc:
              dealerProfile &&
              dealerProfile?.contact_number &&
              dealerProfile?.dealer_address
                ? `${dealerProfile.dealer_address.address_line_1}, ${dealerProfile.dealer_address.city}, ${dealerProfile.dealer_address.state_name}, ${dealerProfile.dealer_address.zip_code}, ${PLACEHOLDERS.UNITED_STATES_TEXT}${PLACEHOLDERS.DOT_TEXT}`
                : null,
            linkText: `${dealerByDealerCode?.dealer_name},  `,
            onLinkClick: () => {},
          }
        : {})}
    />
       <Snackbar
        theme={theme}
        message={["Generating Documents"].includes(snackbarState.message) ?
          <Box theme={theme} display={'flex'}>
            <Box theme={theme} width="30px">
              <CircleLoader theme={theme} size='xs' />
            </Box> {snackbarState.message}
          </Box> : snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      />
    </>
  );
};

import { Button, Image } from "@src/components/atoms";
import "./hero-section.scss";
import Vehicle from "../../../../shared/assets/img/dr-start-vehicle.png";
import { HeroContent } from "@src/components/molecules";

export const HeroSection = () => {
  return (
    <div className="dr-start-hero-section">
      <div className="bmw-container">
        <div className="dr-start-hero__inner">
          <HeroContent />
          <div className="dr-start-hero__img">
            <Image src={Vehicle} alt="image" aspectRatio="16:9" />
          </div>
        </div>
        <p className="text--label text-white dr-start-hero__link">
          By clicking build deal, I agree to the BMW Center{" "}
          <a className="text-white fw-bold no-decoration" href="/term">
            Terms
          </a>{" "}
          and{" "}
          <a className="text-white fw-bold no-decoration" href="/privacy">
            Privacy Policy
          </a>
          . Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea consequat.
        </p>
        <div className="dr-start-hero__bottom">
          <p className="text--label text-white">View additional saved deals</p>
          <Button
            htmlType={"button"}
            linkButton
            withIcon
            dataIcon="arrow_down"
            onDark
          />
        </div>
      </div>
    </div>
  );
};

import { Deals, HeroSection } from "@src/components/organisms";
import { BaseLayout } from "@src/layouts";

const DrStartPage = () => {
  return (
    <BaseLayout hasMenu={false} hasFooter={true}>
      <HeroSection />
      <Deals />
      {/* <BannerSection /> */}
    </BaseLayout>
  );
};

export default DrStartPage;

import { FinancialServiceCard } from "@src/components/molecules";
import { Button, Checkbox } from "@src/components/atoms";
import "./select-offer.scss";

export const SelectOffer = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <div className="select-offer-container">
      <div className="offer-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            01
          </h3>
          <h3 className="checkout-title text--subsection-2">Finance offer.</h3>
        </div>
      </div>
      <FinancialServiceCard />

      <div className="offer-checkbox">
        <Checkbox
          id={"chk--accept-terms"}
          label="I've read the Offer Terms &amp; Conditions. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Nunc vulputate libero et velit
            interdum, ac aliquet odio mattis."
          htmlFor="chk--accept-terms"
        />
      </div>

      <div className="offer-cta-wrapper">
        <Button
          type="default"
          id="acceptoffer"
          htmlType={"button"}
          text="Accept offer"
          fullWidth
          fixed
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import {
  DMS_BASE_URL,
  CONFIG_BASE_URL,
  DOMAIN,
  INSURANCE_PROVIDER,
  EP_ADD_INSURANCE_DETAILS,
  EP_UPDATE_INSURANCE_DETAILS,
  DEAL_INFO,
  EP_SUBMIT_ORDER,
  DEFAULT_LENDER_CONFIGURATION,
  DMS_FRAUD_CHECK,
  DEALERS,
  LENDER_MULTIPLE,
} from "../constants/routes";
import { http } from "../utilities";
import { AppError } from "single-spa";
export const useGetCompanyDomain = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, { origin_domain: string }>;
  error: string | undefined;
} => {
  const { data, mutate, error } = useMutation<
    any,
    AppError,
    { origin_domain: string }
  >({
    mutationFn: async ({ origin_domain }) => {
      const response = await http(CONFIG_BASE_URL).get(
        `${DOMAIN}/${origin_domain}`
      );
      return response.data;
    },
  });
  return { data, mutate, error: error?.message };
};

export const useGetDefaultLender = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, { company_id: any }>;
  error: string | undefined;
  isSuccess: boolean;
} => {
  const { data, isSuccess, mutate, error } = useMutation<
    any,
    AppError,
    { company_id: any }
  >({
    mutationFn: async ({ company_id }) => {
      const response = await http(CONFIG_BASE_URL).get(
        `${DEFAULT_LENDER_CONFIGURATION}`,
        {
          headers: { Company_id: company_id.toString() },
        }
      );
      return response.data;
    },
    onError: (error: AppError) => {
      console.error("Error fetching default lender:", error);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useOrderSubmit = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL).post(
        `${EP_SUBMIT_ORDER}?reference_number=${body?.referenceNumber}`,
        body
      );
    },
  });
  return { data, isPending, mutate };
};

export const useGetFraudCheck = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL).get(
        `${DMS_FRAUD_CHECK}?order_reference_id=${body?.referenceNumber}&customer_reference_id=${body.customer_reference_id}`
      );
    },
  });
  return { data, isPending, mutate };
};
export const useGetInsuranceProvider = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, { company_id: any }>;
  error: string | undefined;
  isSuccess: boolean;
} => {
  const { data, isSuccess, mutate, error } = useMutation<
    any,
    AppError,
    { company_id: any }
  >({
    mutationFn: async ({ company_id }) => {
      const response = await http(CONFIG_BASE_URL).get(
        `${INSURANCE_PROVIDER}`,
        {
          headers: { Company_id: company_id.toString() },
        }
      );
      return response.data;
    },
    onError: (error: AppError) => {
      console.error("Error fetching default lender:", error);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};
export const useGetOrderInfo = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, any>;
  error: string | undefined;
  isSuccess: boolean;
} => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError, any>({
    mutationFn: async (dealRefNumber) => {
      return await http(DMS_BASE_URL)
        .get(`${DEAL_INFO}/${dealRefNumber}`)
        .then((res) => res.data);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useGetInsurance = () => {
  const { data, isPending, mutate } = useMutation<any, any, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL).post(`${EP_ADD_INSURANCE_DETAILS}`, body);
    },
  });
  return { data, isPending, mutate };
};

export const useUpdateInsurance = () => {
  const { data, isPending, mutate } = useMutation<any, any, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL).patch(
        `${EP_UPDATE_INSURANCE_DETAILS}?reference_id=${body.reference_id}&order_reference_id=${body.order_reference_id}`,
        body
      );
    },
  });
  return { data, isPending, mutate };
};
export const useGetByDealerId = (): any => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError>({
    mutationFn: async (body: any) => {
      return await http(CONFIG_BASE_URL)
        .get(`${DEALERS}/${body.dealerId}`, {
          headers: { Company_id: body.company_id.toString() },
        })
        .then((res) => res.data);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useGetLenders = (): any => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError>({
    mutationFn: async (body: any) => {
      return await http(CONFIG_BASE_URL)
        .get(
          `${LENDER_MULTIPLE}/${body.dealerCode}?finance_type=${body.finance_type}`
        )
        .then((res) => res.data);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

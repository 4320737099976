import { useState } from "react";

import {
  Button,
  Checkbox,
  Drawer,
  Image,
  Slider,
  Input,
  Modal,
  Radio,
  Select,
  Tab,
  Tooltip,
  Switch,
  OverflowMenu,
  Carousel,
  MessageAlert,
  Popover,
  ProgressBar,
  TagLabel,
} from "../atoms";
import { Datepicker } from "../atoms/date-picker/date-picker.component";
import { Phone } from "../atoms/phone-input/phone-input.component";
import { Notification } from "../atoms/notification/notification.component";
import logoImage from "@src/shared/assets/img/logo.jpg";
import { Accordion } from "../atoms/accordion/accordion.component";
import { AutoComplete } from "../atoms/autocomplete/autocomplete.component";
import ImagePlaceholder from "../../shared/assets/img/dsx-img-ph.png";
import { Col, message, Row } from "antd";

export const PreviewComponent = () => {
  const [state, setState] = useState();
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onShowModal = () => {
    setShowModal(true);
  };
  const onCloseModal = () => {
    setShowModal(false);
  };
  const onClose = () => {
    setOpen(false);
  };
  const exampleItems = [
    {
      key: "1",
      label: "Label",
      children: "Text content",
    },
    {
      key: "2",
      label: "Label",
      children: "Text content",
    },
    {
      key: "3",
      label: "Label",
      children: "Text content",
      extra: <span className="text--body-focus-price-1">Hello</span>,
    },
  ];
  const items = [
    {
      key: "1",
      label: "Content",
      children: "Content 1",
    },
    {
      key: "2",
      label: "Content",
      children: "Content 2",
    },
    {
      key: "3",
      label: "Content",
      children: "Content 3",
    },
  ];
  const [value_1, setValue_1] = useState<string>("");

  const staticOptions = [
    { value: "Burns Bay Road" },
    { value: "Downing Street" },
    { value: "Wall Street" },
  ];

  const handleSearch = (text: string) => {};

  const handleSelect = (data: string) => {};

  const handleChange = (data: string) => {
    setValue_1(data);
  };

  const menuItems = [
    {
      key: "1",
      label: "Option",
      dataIcon: "car_front",
      disabled: false,
      checkbox: false,
    },
    {
      key: "2",
      label: "Option",
      dataIcon: "car_front",
      disabled: true,
      checkbox: false,
    },
    {
      key: "3",
      label: "Option",
      dataIcon: "car_front",
      disabled: true,
      checkbox: true,
    },
    {
      key: "4",
      label: "Option",
      dataIcon: "car_front",
      disabled: false,
      checkbox: false,
    },
    {
      key: "5",
      label: "Option",
      dataIcon: "car_front",
      disabled: false,
      checkbox: true,
    },
  ];

  const carouselImages = [
    { src: ImagePlaceholder, alt: "Slide 1" },
    { src: ImagePlaceholder, alt: "Slide 2" },
    { src: ImagePlaceholder, alt: "Slide 3" },
  ];

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setIsPopoverOpen(newOpen);
  };
  const hidePopup = () => {
    setIsPopoverOpen(false);
  };

  return (
    <div className="login-form" style={{ margin: 15 }}>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" withIcon iconName="car_front" type="success" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" withIcon iconName="car_front" type="primary" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" withIcon iconName="car_front" type="secondary" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" withIcon iconName="car_front" type="default" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" withIcon iconName="car_front" type="warning" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" withIcon iconName="car_front" type="error" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" type="success" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" type="primary" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" type="secondary" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" type="default" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" type="warning" />
      </div>
      <div style={{ margin: 5 }}>
        <TagLabel text="Label" type="error" />
      </div>
      <Input
        id={""}
        name={""}
        placeholder="Input"
        helpText="Optional helper text"
        label="Label"
        suffix={"$"}
        prefix={"00"}
        value="Hello"
        showInfoIcon
        error={{ message: "Error message" }}
      />
      <Input
        id={""}
        name={""}
        placeholder="Input"
        helpText="Optional helper text"
        label="Label"
        error={{ message: "okk " }}
        size="small"
        showInfoIcon
      />
      <div style={{ marginBottom: 16 }}>
        <ProgressBar
          percent={100}
          status="success"
          label="Label Text"
          helpText="Optional helper text"
        />
        <div style={{ marginBottom: 25 }}></div>
        <ProgressBar
          percent={30}
          label="Label Text"
          helpText="Optional helper text"
        />
        <div style={{ marginBottom: 25 }}></div>
        <ProgressBar
          percent={30}
          label="Label Text"
          helpText="Optional helper text"
          status="exception"
        />
      </div>
      <div style={{ background: "#000", padding: 16, marginBottom: 16 }}>
        <ProgressBar
          percent={10}
          label="Label Text"
          showInfo={false}
          showLabelIcon
          iconName="information"
          onDark
        />
      </div>

      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <Switch label="Label" />
        <Switch label="Label" disabled />
        <Switch label="Label" value={false} />
        <Switch label="Label" loading={true} />
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <Switch label="Label" size="small" />
        <Switch label="Label" disabled size="small" />
        <Switch label="Label" value={false} size="small" />
        <Switch label="Label" loading={true} size="small" />
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <Popover
          title="Headline"
          trigger={["click"]}
          placement="right"
          onOpenChange={handleOpenChange}
          open={isPopoverOpen}
          closeIcon="close"
          onClose={hidePopup}
          disclaimerText="Popover displays additional information or action to the user."
          actionArea={
            <div className="popover-label">
              <span className="text--cta-2">Label</span>
              <div
                className="bmw-icon bmw-icon--before data-icon"
                role="img"
                aria-label="Icon for information"
                data-icon="arrow_chevron_right"
              />
            </div>
          }
        >
          <Button type="primary" htmlType={"button"} text="Label" />
        </Popover>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button type="primary" htmlType={"button"} text="Label" fixed />
          <br />
          <Button type="primary" htmlType={"button"} text="Label" />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            fullWidth
          />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            fullWidth
          />
          <br />
        </div>
        <div>
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            fixed
            disabled
          />
          <br />
          <Button type="primary" htmlType={"button"} text="Label" disabled />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
          />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            disabled
          />
          <br />
        </div>
        <div>
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            fixed
            disabled
            isLoading
            loadingIconPosition="end"
          />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            disabled
            isLoading
          />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
            isLoading
          />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            disabled
            isLoading
          />
          <br />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button type="default" htmlType={"button"} text="Label" fixed />
          <br />
          <Button type="default" htmlType={"button"} text="Label" />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
          />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
          />
          <br />
        </div>
        <div>
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            fixed
            disabled
          />
          <br />
          <Button type="default" htmlType={"button"} text="Label" disabled />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
          />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
            disabled
          />
          <br />
        </div>
        <div>
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            fixed
            disabled
            isLoading
            loadingIconPosition="end"
          />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            disabled
            isLoading
          />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
            isLoading
          />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
            disabled
            isLoading
          />
          <br />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button htmlType={"button"} text="Label" fixed />
          <br />
          <Button htmlType={"button"} text="Label" />
          <br />
          <Button htmlType={"button"} text="Label" size="small" fixed />
          <br />
          <Button htmlType={"button"} text="Label" size="small" />
          <br />
        </div>
        <div>
          <Button htmlType={"button"} text="Label" fixed disabled />
          <br />
          <Button htmlType={"button"} text="Label" disabled />
          <br />
          <Button
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
          />
          <br />
          <Button htmlType={"button"} text="Label" size="small" disabled />
          <br />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            disabled
            isLoading
            loadingIconPosition="end"
          />
          <br />
          <Button htmlType={"button"} text="Label" disabled isLoading />
          <br />
          <Button
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
            isLoading
          />
          <br />
          <Button
            htmlType={"button"}
            text="Label"
            size="small"
            disabled
            isLoading
          />
          <br />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            linkButton
            withIcon
            dataIcon="car_front"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            isIconEnd
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon={false}
            dataIcon="car_front"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            isIconEnd
            dataIcon="car_front"
            disabled
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            size="small"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            isIconEnd
            size="small"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon={false}
            dataIcon="car_front"
            size="small"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            isIconEnd
            dataIcon="car_front"
            disabled
            size="small"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 16,
          marginBottom: 16,
          background: "#000",
          padding: 16,
        }}
      >
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            linkButton
            withIcon
            dataIcon="car_front"
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            isIconEnd
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon={false}
            dataIcon="car_front"
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            isIconEnd
            dataIcon="car_front"
            disabled
            onDark
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 16,
          marginBottom: 16,
          background: "#000",
          padding: 16,
        }}
      >
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            size="small"
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            isIconEnd
            size="small"
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon={false}
            dataIcon="car_front"
            size="small"
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            isIconEnd
            dataIcon="car_front"
            disabled
            size="small"
            onDark
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 16,
          marginBottom: 16,
          background: "#000",
          padding: 16,
        }}
      >
        <div>
          <Button htmlType={"button"} text="Label" fixed onDark />
        </div>
        <div>
          <Button htmlType={"button"} text="Label" onDark />
        </div>
        <div>
          <Button htmlType={"button"} text="Label" onDark disabled />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 16,
          marginBottom: 16,
          background: "#000",
          padding: 16,
        }}
      >
        <div>
          <Button
            htmlType={"button"}
            type="primary"
            text="Label"
            fixed
            onDark
          />
        </div>
        <div>
          <Button htmlType={"button"} type="primary" text="Label" onDark />
        </div>
        <div>
          <Button
            htmlType={"button"}
            type="primary"
            text="Label"
            onDark
            disabled
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            type="primary"
            text="Label"
            onDark
            isLoading
          />
        </div>
      </div>

      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button
            htmlType={"button"}
            type="primary"
            onlyIcon
            dataIcon="car_front"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            type="primary"
            onlyIcon
            dataIcon="car_front"
            disabled
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            type="primary"
            onlyIcon
            dataIcon="car_front"
            disabled
            isLoading
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button
            htmlType={"button"}
            type="default"
            onlyIcon
            dataIcon="car_front"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            type="default"
            onlyIcon
            dataIcon="car_front"
            disabled
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            type="default"
            onlyIcon
            dataIcon="car_front"
            disabled
            isLoading
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button htmlType={"button"} onlyIcon dataIcon="car_front" />
        </div>
        <div>
          <Button htmlType={"button"} onlyIcon dataIcon="car_front" disabled />
        </div>
        <div>
          <Button
            htmlType={"button"}
            onlyIcon
            dataIcon="car_front"
            disabled
            isLoading
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
          />
        </div>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            disabled
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            label="Label"
            showInfoIcon
            error={{ message: "" }}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            size="small"
          />
        </div>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            disabled
            size="small"
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            label="Label"
            showInfoIcon
            error={{ message: "" }}
            size="small"
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Radio label="Label" id="id_1" htmlFor="id_1" />
        </div>
        <div>
          <Radio label="Label" id="id_2" htmlFor="id_2" checked />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Radio label="Label" id="id_3" error htmlFor="id_3" />
        </div>
        <div>
          <Radio label="Label" id="id_4" error htmlFor="id_4" checked />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Radio label="Label" id="id_5" disabled htmlFor="id_5" />
        </div>
        <div>
          <Radio label="Label" id="id_6" disabled htmlFor="id_6" checked />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Checkbox id={"id-1"} label="Enabled" htmlFor="id-1" />
        </div>
        <div>
          <Checkbox id={"id-2"} label="Enabled" htmlFor="id-2" checked />
        </div>
        <div>
          <Checkbox
            id={"id-3"}
            label="Enabled"
            htmlFor="id-3"
            checked
            indeterminate
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Checkbox id={"id-4"} label="Enabled" htmlFor="id-4" error />
        </div>
        <div>
          <Checkbox id={"id-5"} label="Enabled" htmlFor="id-5" error checked />
        </div>
        <div>
          <Checkbox
            id={"id-6"}
            label="Enabled"
            htmlFor="id-6"
            error
            checked
            indeterminate
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Checkbox id={"id-1"} label="Enabled" htmlFor="id-1" disabled />
        </div>
        <div>
          <Checkbox
            id={"id-2"}
            label="Enabled"
            htmlFor="id-2"
            disabled
            checked
          />
        </div>
        <div>
          <Checkbox
            id={"id-3"}
            label="Enabled"
            htmlFor="id-3"
            disabled
            checked
            indeterminate
          />
        </div>
      </div>
      <div>
        <Select
          id={""}
          placeholder="Content"
          label="Label"
          showInfoIcon
          options={[
            { value: "1", label: "Option 1", disabled: false },
            { value: "2", label: "Option 2", disabled: true },
          ]}
          helpText="Optional helper text"
        />
      </div>
      <div>
        <Select
          id={""}
          placeholder="Content"
          label="Label"
          showInfoIcon
          disabled
          options={[
            { value: "1", label: "Option 1", disabled: false },
            { value: "2", label: "Option 2", disabled: true },
          ]}
          helpText="Optional helper text"
        />
      </div>
      <div>
        <Select
          id={""}
          placeholder="Content"
          label="Label"
          showInfoIcon
          status="error"
          options={[
            { value: "1", label: "Option 1", disabled: false },
            { value: "2", label: "Option 2", disabled: true },
          ]}
          error={{ message: "Error message" }}
        />
      </div>
      <div style={{ marginBlock: 15 }} id="hello-world">
        <Datepicker
          id="datePicker"
          name="date-picker"
          placeholder="Choose"
          label="Label"
          showInfoIcon
          helpText="Optional helper text"
          prevIcon="arrow_left"
          nextIcon="arrow_right"
        />
      </div>
      <div style={{ marginBlock: 15 }}>
        <Datepicker
          id="datePickerNew"
          name="date picker"
          placeholder="Choose"
          status="error"
        />
      </div>
      <div style={{ marginBlock: 15 }}>
        <Datepicker
          id="datePickerNew"
          name="date picker"
          placeholder="Choose"
          disabled
        />
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Phone
            country="us"
            label="Label"
            showInfoIcon
            helpText="Optional helper text"
          />
        </div>
        <div>
          <Phone
            country="us"
            label="Label"
            showInfoIcon
            helpText="Optional helper text"
            disabled
          />
        </div>
        <div>
          <Phone
            country="us"
            label="Label"
            showInfoIcon
            formState={{
              error: { message: "Optional helper text" },
            }}
            status="error"
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div style={{ flex: 1 }}>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            autoSize={{ minRows: 2, maxRows: 6 }}
            type="textarea"
            showCount
            onChange={(e: any) => {
              setState(e.target.value);
            }}
            value={state}
            maxLength={100}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            autoSize={{ minRows: 2, maxRows: 6 }}
            type="textarea"
            showCount
            onChange={(e: any) => {
              setState(e.target.value);
            }}
            value={state}
            maxLength={100}
            disabled
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div style={{ flex: 1 }}>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            autoSize={{ minRows: 2, maxRows: 6 }}
            type="textarea"
            showCount
            onChange={(e: any) => {
              setState(e.target.value);
            }}
            value={state}
            maxLength={100}
            // status="error"
            error={{ message: "hello" }}
          />
        </div>
        <div style={{ flex: 1 }}></div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <Tooltip
          text="Tooltip will show on mouse enter."
          prompt="Tooltip"
          placement="bottomRight"
          arrow={false}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Notification
          show={true}
          title="Important information"
          placement="bottomRight"
          description={
            <section className="notification-content">
              <p className="text--label">
                Notifications have a high degree of importance or urgency and
                are used to communicate with the user and provide feedback.
              </p>
              <Input
                id={""}
                name={""}
                placeholder="Input"
                label="Label"
                showInfoIcon
                error={{ message: "" }}
              />
              <div className="action-area">
                <Button
                  text="Take action"
                  htmlType="button"
                  size="small"
                  fixed
                  fullWidth
                />
                <Button
                  text="Dismiss"
                  linkButton
                  withIcon
                  size="small"
                  fixed
                  htmlType="button"
                />
              </div>
            </section>
          }
          type="error"
          showContent={true}
          fullWidth={true}
          icon={<img src={logoImage} alt="logo-img" />}
        />
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div style={{ flex: 1 }}>
          <Accordion items={exampleItems} defaultActiveKey={1} />
        </div>
        <div style={{ flex: 1 }}>
          <Accordion
            items={exampleItems}
            defaultActiveKey={1}
            ghost={false}
            accordion={true}
            expandIconPosition="start"
          />
        </div>
        <div style={{ flex: 1 }}>
          <Accordion
            items={exampleItems}
            defaultActiveKey={0}
            ghost={false}
            collapsible="disabled"
          />
        </div>
      </div>
      <Accordion
        items={exampleItems}
        defaultActiveKey={0}
        ghost={false}
        fullWidth
      />
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <Tab defaultActiveKey="1" items={items} mode="condensed" />
      </div>
      <div style={{ marginBottom: 16 }}>
        <AutoComplete
          label="Label"
          showInfoIcon={true}
          helpText="Optional helper text"
          onSearch={handleSearch}
          onSelect={handleSelect}
          onChange={handleChange}
          options={staticOptions}
          placeholder="Select an option"
          filterOption={(inputValue, option) =>
            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          gridGap: "40px",
        }}
      >
        {/* Basic Slider */}
        <Slider
          label="Label"
          min={1}
          max={13}
          step={1}
          dots={true}
          defaultValue={3}
          tooltipFormatter={(value) => `${value}`}
          onChange={() => {}}
        />
        <Slider
          label="Label"
          min={1}
          max={13}
          step={1}
          dots={false}
          defaultValue={4}
          tooltipFormatter={(value) => `${value}`}
          onChange={() => {}}
        />
        {/* Range Slider */}
        <Slider
          label="Label"
          range={true}
          min={1}
          max={13}
          step={1}
          dots={true}
          defaultValue={[2, 10]}
          tooltipFormatter={(value) => `${value}`}
          onChange={() => {}}
        />
        <Slider
          label="Label"
          range={true}
          min={1}
          max={13}
          step={1}
          dots={false}
          defaultValue={[2, 10]}
          tooltipFormatter={(value) => `${value}`}
          onChange={() => {}}
        />
        <Slider
          label="Label"
          range={true}
          min={1}
          max={16}
          step={1}
          dots={true}
          defaultValue={[2, 10]}
          tooltipFormatter={(value) => `${value}`}
          onChange={() => {}}
        />
      </div>
      <div style={{ display: "flex", gap: 16, margin: "25px 0" }}>
        <Button text="Open Drawer" htmlType="button" onClick={showDrawer} />
        <Drawer
          width={710}
          open={open}
          maskClosable={true}
          keyboard={true}
          content={<>content</>}
          footer={<>footer</>}
          onClose={onClose}
        />
      </div>
      <div
        style={{ display: "flex", gap: 16, marginBottom: 16, flexWrap: "wrap" }}
      >
        <div style={{ maxWidth: 240 }}>
          <p className="text--body-2" style={{ marginBottom: 8 }}>
            Image 16:9
          </p>
          <Image src={ImagePlaceholder} alt="image" aspectRatio="16:9" />
        </div>
        <div style={{ maxWidth: 240 }}>
          <p className="text--body-2" style={{ marginBottom: 8 }}>
            Image 1:1
          </p>
          <Image src={ImagePlaceholder} alt="image" aspectRatio="1:1" />
        </div>
        <div style={{ maxWidth: 240 }}>
          <p className="text--body-2" style={{ marginBottom: 8 }}>
            Image 3:1
          </p>
          <Image src={ImagePlaceholder} alt="image" aspectRatio="3:1" />
        </div>
        <div style={{ maxWidth: 240 }}>
          <p className="text--body-2" style={{ marginBottom: 8 }}>
            Image 16:7
          </p>
          <Image src={ImagePlaceholder} alt="image" aspectRatio="16:7" />
        </div>
        <div style={{ maxWidth: 240 }}>
          <p className="text--body-2" style={{ marginBottom: 8 }}>
            Image 2:1
          </p>
          <Image src={ImagePlaceholder} alt="image" aspectRatio="2:1" />
        </div>
        <div style={{ maxWidth: 240 }}>
          <p className="text--body-2" style={{ marginBottom: 8 }}>
            Image 3:2
          </p>
          <Image src={ImagePlaceholder} alt="image" aspectRatio="3:2" />
        </div>
        <div style={{ maxWidth: 240 }}>
          <p className="text--body-2" style={{ marginBottom: 8 }}>
            Image 4:3
          </p>
          <Image src={ImagePlaceholder} alt="image" aspectRatio="4:3" />
        </div>
        <div style={{ maxWidth: 240 }}>
          <p className="text--body-2" style={{ marginBottom: 8 }}>
            Image 3:4
          </p>
          <Image src={ImagePlaceholder} alt="image" aspectRatio="3:4" />
        </div>
        <div style={{ maxWidth: 240 }}>
          <p className="text--body-2" style={{ marginBottom: 8 }}>
            Image 2:3
          </p>
          <Image src={ImagePlaceholder} alt="image" aspectRatio="2:3" />
        </div>
        <div style={{ maxWidth: 240 }}>
          <p className="text--body-2" style={{ marginBottom: 8 }}>
            Image 9:16
          </p>
          <Image src={ImagePlaceholder} alt="image" aspectRatio="9:16" />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <Button text="Open Modal" htmlType="button" onClick={onShowModal} />
        <Modal
          size="compact"
          title={<h3>Headline</h3>}
          subtitle={
            <p className="text--body-1">
              This is example text, use the option panel to add more content.
            </p>
          }
          footerSticky={true}
          open={showModal}
          onClose={onCloseModal}
          closable
          footer={
            <>
              <div className="description-text">
                <p className="text--label">
                  Monthly instalment <sup>1</sup>{" "}
                </p>
                <p className="text--body-focus-price">494.244,00 Ft</p>
              </div>
              <div className="action-btns">
                <Button
                  htmlType="button"
                  fullWidth
                  type="primary"
                  text="Label"
                  onClick={() => {}}
                />
                <Button
                  htmlType="button"
                  fullWidth
                  text="Label"
                  onClick={onCloseModal}
                />
              </div>
            </>
          }
        >
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area</p>
          <p className="text--body-2">Content area last</p>
        </Modal>
      </div>

      <div style={{ display: "flex", gap: "30px", marginBottom: 16 }}>
        <OverflowMenu
          items={menuItems}
          position="bottomLeft"
          trigger={
            <Button
              linkButton
              withIcon
              htmlType="button"
              text="Christopher"
              dataIcon="person"
            />
          }
        />
        <OverflowMenu
          items={menuItems}
          position="topLeft"
          mode="condensed"
          trigger={
            <Button
              linkButton
              withIcon
              htmlType="button"
              text="Dropdown Menu"
              dataIcon="car_front"
            />
          }
        />
      </div>
      {/* Carousel */}
      <Row gutter={20}>
        <Col xs={24} sm={12} lg={6}>
          <Carousel
            arrows
            autoplay={false}
            autoplaySpeed={3000}
            dots
            infinite={false}
            images={carouselImages}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Carousel
            arrows={false}
            autoplay
            autoplaySpeed={2000}
            dots
            infinite
            images={carouselImages}
          />
        </Col>
      </Row>
      {/* Message Alert */}

      <div style={{ margin: "20px 0" }}>
        <p>
          <strong>Notification Message Alert</strong>
        </p>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert title="Important information" />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert type="info" title="Important information" />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert
              type="error"
              title="Important information"
              icon={<img src={logoImage} alt="logo-img" />}
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert
              type="success"
              title="Important information"
              showCloseButton={false}
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <MessageAlert
              type="info"
              title="Important information"
              subTitle="Your data has been saved successfully!"
              contentTitle="Content area"
              contentDetail="Swap with a local component that holds your content"
              buttonText="Learn More"
              onButtonClick={() => {}}
              icon={<img src={logoImage} alt="logo-img" />}
              closeOnClick={true}
              buttonFullWidth
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert
              type="error"
              title="Important information"
              subTitle="Your data has been saved successfully!"
              contentTitle="Content area"
              contentDetail="Swap with a local component that holds your content"
              closeOnClick={true}
              buttonText="Learn More"
              onButtonClick={() => {}}
              dismissButton
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert
              type="warning"
              title="Important information"
              subTitle="Your data has been saved successfully!"
              buttonText="Take action"
              onButtonClick={() => {}}
              dismissButton
              buttonFullWidth
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert
              type="success"
              title="Important information"
              subTitle="Your data has been saved successfully!"
              showCloseButton={false}
            />
          </Col>

          <Col xs={24} sm={12} lg={12}>
            <MessageAlert
              type="success"
              title="Important information"
              subTitle="Your data has been saved successfully!"
              contentTitle="Content area"
              contentDetail="Swap with a local component that holds your content"
              fullWidth
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <MessageAlert
              type="success"
              title="Important information"
              fullWidth
            />
          </Col>
        </Row>
      </div>

      <div style={{ margin: "20px 0" }}>
        <p>
          <strong>Notification Message Alert Accordion</strong>
        </p>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert
              type="info"
              viewType="accordion"
              title="Important Info"
              subTitle="Your data has been saved successfully!"
              contentTitle="Content area"
              contentDetail="Swap with a local component that holds your content"
              buttonText="Learn More"
              onButtonClick={() => {}}
              icon={<img src={logoImage} alt="logo-img" />}
              buttonFullWidth
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert
              type="error"
              viewType="accordion"
              title="Important Info"
              subTitle="Your data has been saved successfully!"
              contentTitle="Content area"
              contentDetail="Swap with a local component that holds your content"
              buttonText="Learn More"
              onButtonClick={() => {}}
              isExpanded={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert
              type="warning"
              viewType="accordion"
              title="Important Info"
              subTitle="Your data has been saved successfully!"
              buttonText="Learn More"
              onButtonClick={() => {}}
              isExpanded={true}
              dismissButton
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <MessageAlert
              type="success"
              viewType="accordion"
              title="Important Info"
              subTitle="Your data has been saved successfully!"
              buttonText="Learn More"
              onButtonClick={() => {}}
              isExpanded={true}
              dismissButton
              buttonFullWidth
            />
          </Col>

          <Col xs={24} sm={12} lg={12}>
            <MessageAlert
              type="success"
              viewType="accordion"
              title="Important information"
              subTitle="Your data has been saved successfully!"
              contentTitle="Content area"
              contentDetail="Swap with a local component that holds your content"
              fullWidth
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <MessageAlert
              type="success"
              viewType="accordion"
              title="Important information"
              subTitle="Your data has been saved successfully!"
              fullWidth
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

import {
  Button,
  FeedbackLabel,
  MessageAlert,
  Popover,
  ProgressBar,
} from "@src/components/atoms";
import "./hero-content.scss";
import { useState } from "react";
import { TagLabel } from "@src/components/atoms/tag-label/tag-label.component";

export const HeroContent = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setIsPopoverOpen(newOpen);
  };
  const hidePopup = () => {
    setIsPopoverOpen(false);
  };

  return (
    <div className="dr-start-hero__content">
      <TagLabel text="No longer avalible" type="success" />
      <p className="text--label text-white">VIN: WBA7E2C51JG741556</p>
      <h2 className="text--h2">
        [Finance/Lease] your 20XX [BMW Model Name] at{" "}
        <strong>
          $000.00/month.
          <Popover
            title="Your {BMW Financial Services} offer"
            trigger={["click"]}
            onOpenChange={handleOpenChange}
            open={isPopoverOpen}
            closeIcon="close"
            onClose={hidePopup}
            disclaimerText="Approved offer: $604 / month
          36 month finance term
          $6,000 cash due at signing"
          >
            <span
              className="bmw-icon bmw-icon--before data-icon"
              role="img"
              aria-label="Flag Label Icon"
              data-icon="information"
            />
          </Popover>
        </strong>
      </h2>
      <div className="text-with__icon">
        <span
          className="bmw-icon bmw-icon--before data-icon"
          data-icon="price_tag"
        ></span>
        <p className="text--label text-white fw-normal">
          XX month term, your price $XX,XXX, $X,XXX due at signing.
        </p>
      </div>
      <div className="text-with__icon">
        <span
          className="bmw-icon bmw-icon--before data-icon"
          data-icon="lock_locked"
        ></span>
        <p className="text--label text-white fw-normal">
          This vehicle is reserved for you for 00:00:00.
        </p>
      </div>
      <FeedbackLabel type="success" text="[Status]." />
      <ProgressBar
        percent={70}
        label="[Prior Step] Completed"
        showInfo={false}
        showLabelIcon
        iconName="checkmark"
        onDark
      />
      <MessageAlert
        type="warning"
        title="Sorry, your credit is frozen. Please resume your check out to take action."
        showCloseButton={false}
      />
      <div className="dr-start-hero__button">
        <div className="button__innner">
          <Button type="primary" htmlType={"button"} text="Browse inventory" />
          <Button
            htmlType={"button"}
            type="primary"
            text="Contact dealer"
            onDark
          />
        </div>
        <Button
          linkButton
          htmlType={"button"}
          text="Remove deal"
          withIcon
          dataIcon="trash_can"
          onDark
        />
      </div>
    </div>
  );
};

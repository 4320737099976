import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Dialog, Box, Button, Icon, Typography, Menu } from '@ntpkunity/controls'
import InventoryComponent from 'controls/inventry-control'
import { useModal } from 'react-modal-hook'
import {
  DeskingActions,
  DeskingActionTypes,
  FinanceTypes,
  useDeskingContext
} from './desking-context-provider'
import { BtnAddWrap, CustomDialogWrap, VehicleList } from '@components'
import { useGetProgramInformation, useGetVehicleData } from '@apis/dealer-configurations.service'
import { useStoreContext } from '@store/storeContext'
import { selectDefaultMileages, selectDefaultTerms } from './utils'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'
import { useSearchParams } from 'react-router-dom'

interface IInventoryPopupProps {
  hide: () => void
  vehicleDataCallback: (vehicleData: any) => void
}

interface IAddVehicleProps {
  vin: string | undefined
  index: number
}

interface IProgramProps {
  vin: string | undefined
  index: number
  term: number
  mileage: number
  rowIndex?: number
  setter?: (value: undefined) => void
}

const InventoryPopup: FC<IInventoryPopupProps> = ({ hide, vehicleDataCallback }) => {
  const theme = useTheme()

  return (
    <CustomDialogWrap theme={theme} className="custom-dialog-wrap lane-dialog-wrap s-full-width">
      <Dialog
        theme={theme}
        variant={'full-window'}
        size="xl"
        disablePortal
        open={true}
        onCloseDialog={hide}
        noFooter={true}
        children={
          <>
            <Box theme={theme} className="lane-dialog-content inventory-dialog-content">
              <Box theme={theme} className="wrap">
                <Box theme={theme} className="container scroll-container">
                  <InventoryComponent selectVinObj={vehicleDataCallback} />
                </Box>
              </Box>
            </Box>
          </>
        }
      />
    </CustomDialogWrap>
  )
}

export const useInventoryModal = (
  dispatch: React.Dispatch<DeskingActions>,
  originalVin: string | null = null,
  index: number | null = null
) => {
  const handleVehicleData = (data: any) => {
    if ((originalVin ?? '').length > 0 && typeof index === 'number' && !isNaN(index)) {
      dispatch({
        type: DeskingActionTypes.UPDATE_VEHICLE_VIN,
        payload: {
          vinUpdate: data?.vin,
          originalVin: originalVin as string,
          indexUpdate: index as number,
          vehicleData: data
        }
      })
    } else {
      dispatch({
        type: DeskingActionTypes.ADD_VEHICLE_VIN,
        payload: data
      })
    }
    hideInventoryPopup()
  }

  const [showInventoryPopup, hideInventoryPopup] = useModal(
    () => <InventoryPopup hide={hideInventoryPopup} vehicleDataCallback={handleVehicleData} />,
    []
  )

  return { showInventoryPopup, hideInventoryPopup }
}

interface IVehicleDetailProps {
  index: number
  vehicleDatail: any
  isLoading: boolean
  disableActions?: boolean
}

const VehicleDetail: FC<IVehicleDetailProps> = ({
  index,
  vehicleDatail,
  isLoading,
  disableActions = false
}) => {
  const theme = useTheme()
  const { state, dispatch } = useDeskingContext()

  const {
    vehicle: { vin, year, make, model, trim_description, stock_number, photo_urls }
  } = vehicleDatail

  const { isStipulationScreen, submission_tier, isVehicleSelectedAndOrderSaved } = state
  const _disabled =
    isVehicleSelectedAndOrderSaved || isStipulationScreen || Boolean(submission_tier) || isLoading

  const { showInventoryPopup } = useInventoryModal(dispatch, vin, index)

  return (
    <>
      <Box theme={theme} className="vl-item vertical">
        <Box theme={theme} className="vl-img-wrap" mb={1}>
          <img src={photo_urls?.[0]?.location} alt="Car" />
        </Box>
        <Box theme={theme} className="vl-details">
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className=" text-overflow"
            children={
              <b>
                {year} {make} {model} {trim_description}
              </b>
            }
          />
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children={
              <>
                VIN: <b>{vin},</b> Stock: <b>{stock_number}</b>
              </>
            }
          />
        </Box>
        <Menu
          theme={theme}
          disablePortal
          options={[
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="EditIcon" /> Edit
                </>
              ),
              optionValue: 'edit',
              disabled: _disabled
            },
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="DeleteIcon" /> Delete
                </>
              ),
              optionValue: 'delete',
              disabled: _disabled
            }
          ]}
          handleOptionClick={(_event, _key, value) => {
            switch (value) {
              case 'delete':
                dispatch({
                  type: DeskingActionTypes.DELETE_VEHICLE_VIN,
                  payload: {
                    vinDelete: vin,
                    indexDelete: index
                  }
                })
                break
              case 'edit':
                showInventoryPopup()
                break
            }
          }}
          render={(cb) => (
            <Button
              disabled={disableActions}
              theme={theme}
              defaultBtn
              className="vl-menu-btn"
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      </Box>
    </>
  )
}

export const AddVehicle: FC<IAddVehicleProps> = ({ vin, index }) => {
  const theme = useTheme()
  const {
    states: { dealDesk: globalState }
  } = useStoreContext()
  const { state, dispatch } = useDeskingContext()
  const { vehiclesData, order, finance_type, isVehicleLoading, isVehicleSelectedAndOrderSaved, vehiclesForComparison } = state
  
  const [programParams, setProgramParams] = useState<{ term: number; mileage: number }[]>([])
  const { showInventoryPopup } = useInventoryModal(dispatch)
  const { allLoading: vehiclesDataLoading } = useGetVehicleData(vin)
  const [searchParams] = useSearchParams()
  const orderReferenceId = searchParams.get('reference_id')

  const canUserEdit = useEditPermissionHook()

  useEffect(() => {
    if (Object.keys(order)?.length > 0 && isVehicleSelectedAndOrderSaved) {
      setProgramParams([
        {
          term: order?.contract_term,
          mileage: order?.allowed_usage
        }
      ])
    } else {
      const defaultTerm =
        finance_type === FinanceTypes.FINANCE
          ? globalState.dealerPreferences.default_term_finance_id
          : globalState.dealerPreferences.default_term_lease_id
      const contractTerms =
        finance_type === FinanceTypes.FINANCE
          ? globalState.contractTerms.filter((term) =>
              term.finance_type?.includes(FinanceTypes.FINANCE)
            )
          : globalState.contractTerms.filter((term) =>
              term.finance_type?.includes(FinanceTypes.LEASE)
            )
      const defaultContractTerms = selectDefaultTerms(contractTerms, defaultTerm)
      const defaultAllowedMillage = selectDefaultMileages(
        globalState.allowedMillages,
        globalState.dealerPreferences.default_mileage_id
      )

      if (defaultContractTerms?.length > 0 && defaultAllowedMillage?.length > 0) {
        const termsMileages = defaultContractTerms?.map((item, index: number) => {
          return {
            term: item?.term,
            mileage: defaultAllowedMillage?.[index]?.value
          }
        })
        const quote_params: any = state[vehiclesForComparison][finance_type].quote_params
        const vall = quote_params.map((q: any, index: number) => ({ term: q.contract_term, mileage: defaultAllowedMillage?.[index]?.value }))

        setProgramParams(!!orderReferenceId ? vall : termsMileages)
      }
    }
  }, [finance_type])

  const isVehiclesAdded =
    (vin ?? '').length > 0 &&
    Object.keys(vehiclesData.get(vin as string) ?? {}).length > 0 &&
    (vehiclesData.get(vin as string).vin ?? '').length > 0
  const isLoading = vehiclesDataLoading

  return (
    <VehicleList theme={theme} className="vehicle-list" width="100%">
      {programParams?.map((program, index) => (
        <Programs
          vin={vin}
          index={index}
          term={program.term}
          mileage={program.mileage}
          key={index}
        />
      ))}
      {!isVehiclesAdded ? (
        <BtnAddWrap theme={theme} className="btn-add-wrap">
          <Button
            title="Add Vehicle"
            theme={theme}
            fullWidth
            startIcon={<Icon name="AddCircleIcon" />}
            size="large"
            onClick={showInventoryPopup}
            text={<span>Add Vehicle</span>}
            disabled={isVehicleLoading || !canUserEdit}
          />
        </BtnAddWrap>
      ) : (
        <VehicleDetail
          index={index}
          vehicleDatail={vehiclesData.get(vin as string)}
          isLoading={isLoading}
          disableActions={!canUserEdit}
        />
      )}
    </VehicleList>
  )
}

export const Programs: FC<IProgramProps> = ({ vin, index, term, mileage, rowIndex, setter }) => {
  const {
    state: { finance_type, lender_id, submission_tier },
    dispatch
  } = useDeskingContext()
  const { states } = useStoreContext()

  const { data: programs, isLoading: programLoading } = useGetProgramInformation(
    vin,
    submission_tier,
    index,
    term,
    mileage,
    setter
  )

  useEffect(() => {
    if (typeof programLoading !== 'undefined' && !setter) {
      dispatch({
        type: DeskingActionTypes.SET_PROGRAM_LOADING,
        payload: programLoading
      })
    }
  }, [setter, programLoading])

  useEffect(() => {
    if ((programs ?? []).length > 0) {
      if (submission_tier) {
        const lender: any = states?.lendersData?.filter((item) => item.id === lender_id)
        if (lender[0] && lender[0]?.credit_tier_margins?.length > 0) {
          const creditMargin = lender[0]?.credit_tier_margins?.filter(
            (item: any) => item.tier_name === submission_tier && item.finance_type === finance_type
          )

          if (creditMargin && creditMargin[0]) {
            programs?.map((item: any) => {
              if (item?.finance_type === finance_type)
                item.final_customer_rate += creditMargin[0]?.margin_rate
            })
          }
        }
      }

      dispatch({
        type: DeskingActionTypes.ADD_PROGRAM,
        payload: {
          vin: vin as string,
          index,
          program: programs,
          term,
          mileage,
          rowIndex
        }
      })
    }
    if (typeof Programs !== 'undefined') setTimeout(() => setter?.(undefined), 3000)
  }, [programs])

  return <></>
}

import { FullScreenContent } from "@src/components/molecules";
import { Button, IconList } from "@src/components/atoms";
import { useAppContext } from "@src/context/app-context";
import { BaseLayout } from "@src/layouts";
import { Decision } from "@src/helpers";

const DealStatePage = () => {
  const { state: appState } = useAppContext();

  return (
    <BaseLayout hasMenu={false} hasFooter={false}>
      <FullScreenContent
        title={
          appState.order.status === Decision.ACCEPT ? (
            <h1 className="text-white">Application received!</h1>
          ) : (
            <h1 className="text-white">Looks like we need more information.</h1>
          )
        }
        subtitle={
          appState.order.status === Decision.ACCEPT ? (
            <p className="text--body-1 text-white">
              Your reservation timer is paused for you while we process your
              application. Once we provide finance offers, the reservation timer
              will continue at <span className="fw-bold">XX:XX.</span>
            </p>
          ) : (
            <p className="text--body-1 text-white">
              Please contact your dealer to complete your application.
            </p>
          )
        }
        content={
          <>
            <IconList
              size="big"
              onDark
              listItems={
                appState.order.status === Decision.ACCEPT
                  ? [
                      {
                        type: "checklist",
                        text: "Application received",
                      },
                      {
                        type: "loading",
                        text: "Confirming lease offer",
                      },
                    ]
                  : []
              }
            />
          </>
        }
        actionArea={
          appState.order.status === Decision.REJECT ? (
            <>
              <Button
                text="Contact dealer"
                type="primary"
                htmlType="button"
                fullWidth
              />
              <Button
                text="Cancel deal"
                linkButton
                withIcon
                dataIcon="trash_can"
                onDark
                htmlType="button"
              />
            </>
          ) : null
        }
        footer={
          appState.order.status === Decision.ACCEPT ? (
            <p className="text--label text-white">
              This process can take between 30 seconds and {"XX"} minutes. You
              will also receive an email notification from{" "}
              <span className="fw-bold">email@email.com</span> when this is
              complete.
            </p>
          ) : null
        }
      />
    </BaseLayout>
  );
};

export default DealStatePage;

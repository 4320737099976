import { Controller } from "react-hook-form";
import { Input, Select } from "@src/components/atoms";

import "./address.scss";

export const Address = ({ type, control, stateOptions }) => {
  return (
    <>
      <div className="checkout-form-spacing">
        <Controller
          name={`${type}.address_line_1`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              id={"checkout-form--streetAddress"}
              placeholder="Street address"
              error={error}
              label="Address 1"
              {...field}
            />
          )}
        />
      </div>
      <Controller
        name={`${type}.address_line_2`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            id={"checkout-form--streetAddress2"}
            error={error}
            placeholder="Street address"
            label="Address 2 (optional)"
            {...field}
          />
        )}
      />
      <div className="checkout-column-wrapper">
        <Controller
          name={`${type}.city`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              id={"checkout-form--city"}
              placeholder="City"
              error={error}
              label="City"
              {...field}
            />
          )}
        />

        <Controller
          name={`${type}.state_name`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Select
              label={"State"}
              options={stateOptions}
              error={error}
              {...field}
            />
          )}
        />
        <Controller
          name={`${type}.county`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              id="checkout-form--county"
              placeholder="County"
              label="County (optional)"
              error={error}
              {...field}
            />
          )}
        />
        <Controller
          name={`${type}.zip_code`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              id={"checkout-form--zipcode"}
              placeholder="ZIP Code"
              label="ZIP Code"
              error={error}
              {...field}
            />
          )}
        />
      </div>
    </>
  );
};

import { DealsInfo } from "@src/components/molecules";
import "./deals.scss";
import { Button } from "@src/components/atoms";

export const Deals = () => {
  return (
    <div className="deals-wrapper">
      <div className="bmw-container">
        <div className="deals-main__heading">
          <h4 className="text--h4">Your Previous deals (3)</h4>
          <p className="text--body-2">
            This list doesn’t include any saved vehicles on bmwusa.com, please
            go visit{" "}
            <a className="text-secondary no-decoration fw-bold" href="/garage">
              your garage
            </a>{" "}
            to view all your saved vehicles. The monthly payments below are
            subject to change. Resume your deal to view the latest pricing.
          </p>
        </div>
        <DealsInfo />
        <div className="deal-info__options">
          <div>
            <h6 className="text--h4">Want more options? </h6>
            <p className="text--body-2">
              Find more vehicles and personalized deals with BMW of Manhattan.
            </p>
          </div>
          <Button htmlType={"button"} text="Continue shopping" />
        </div>
        <p className="text--label text-muted">
          By clicking build deal, I agree to the BMW Center{" "}
          <a className="text-secondary no-decoration fw-bold" href="/term">
            Terms
          </a>{" "}
          and{" "}
          <a className="text-secondary no-decoration fw-bold" href="/privacy">
            Privacy Policy
          </a>
          . Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea consequat.
        </p>
      </div>
    </div>
  );
};

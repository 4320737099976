import React, { useEffect, useReducer, useState } from 'react'
import { useTheme } from '@mui/material'
import { PageWrap } from './review-order.style'
import {Box, Grid, Typography, Button, Snackbar, CircleLoader} from '@ntpkunity/controls'
import {PageHeader, StepperProgress} from '@components'
import {CreditRequestDisclaimerPopup, Insurance} from '@libraries'
import { PaymentBreakDownGridContent } from 'libraries/payment-breakdown-popup/payment-breakdown-control'
import { useAppContext } from '@app/context-provider'
import { useOrderContext } from 'pages/(order-management)/order/order-context-provider'
import { IOrder, IVehicle } from '@interfaces'
import {QueryKeys, OrderStatus, Navigation} from '@constants'
import { updateOrder } from 'pages/(order-management)/order/order-context-provider'
import { SnackbarUtility } from '@utilities'
import { useConvertQuotationToApplication, useSaveOrderRequest, useUpdateLead, useUpdateOrderRequest, useUpdateOrderStatus } from '@hooks'
import { useQueryClient } from 'react-query'
import { pagesActionType, useJourneyLayoutContext } from 'layouts/journey-layout/journey-layout-context-provider'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import {Tags, PageType, TaggingClasses, Stage, LicenseStatus, FeeTypes} from 'constants/enums';
import { user_session } from '@constants'
import {useNavigate, useParams} from 'react-router-dom'
import {useFormContext} from "../../pages/(order-management)/order/form-context-provider";
import {useAuthentication} from "../../hooks/useAuthenticationHook";
import { useOrderInfo } from 'hooks/dealer-management'
import {FEE_MONTHLY_PAYMENT_BASED} from "../../constants/fee";


export const ReviewOrderControl = () => {
  const navigate = useNavigate()
  const { authenticationStatus } = useAuthentication()
  const { state } = useOrderContext()
  const { state: appState } = useAppContext()
  const { state: pageState, dispatch: pageDispatch } = useJourneyLayoutContext()
  const { state: { order }, dispatch: orderDispatch } = useOrderContext()
  const { mutate: saveOrderRequest, isLoading: saveRequestLodaing } = useSaveOrderRequest()
  const { mutate: updateOrderRequest, isLoading: updateRequestLodaing } = useUpdateOrderRequest()
  const { mutate: convertQuotationRequest } = useConvertQuotationToApplication()
  const { mutate: updateOrderStatus } = useUpdateOrderStatus()
  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
  const PLACEHOLDERS = appState.language.placeholders
  const order_info: IOrder = state.order
  const defaultCurrency = appState.default_currency_symbol
  const theme = useTheme()
  const queryClient = useQueryClient();
  const { tenant,vin,orderId } = useParams()

  const vehicle: IVehicle = queryClient.getQueryData([QueryKeys.VEHICLE, vin]);
  const [openPopup, setOpenPopup] = useState(false);
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const { mutate: createTags } = useTags();
  const {finalSave} = useFormContext()
  const { mutate: updateLead } = useUpdateLead(appState?.slug);
  const LenderConfigurations:any = queryClient.getQueryData(QueryKeys.GET_DEFAULT_LENDER);

  const {data: order_misclenious } =  useOrderInfo( tenant ,orderId)

  useEffect(() => {
    const page_event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.FINANCING_END,
      user_id: user_profile?.user_id,
      dealer_id: +order?.dealer_id,
      vehicle_make: order?.order_asset?.make,
      vehicle_model: order?.order_asset?.model,
      order_id:order?.identifier,
      vin:order?.vin,
      page_type:PageType.Financing
      }
      createTags(page_event)
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.DISPLAY_REVIEW_ORDER,
      user_id: user_profile?.user_id,
      dealer_id: +order?.dealer_id,
      vehicle_make: order?.order_asset?.make,
      vehicle_model: order?.order_asset?.model,
      order_id:order?.identifier,
      vin:order?.vin,
      page_type:PageType.ReviewOrder
      }
      createTags(event)
  }, [])
  const handleSubmitOrderClick = () => {
    if(!order?.order_stage || order?.order_stage===Stage.QUOTATION){
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.MANDATORY_TAB_FINANCING));
      return;
    }
    const info = order_misclenious?.customer_info
    if(info?.miscellaneous_info == null &&(
      info?.customer_license[0]?.status === LicenseStatus.REJECTED ||
      info?.customer_license[0]?.status === LicenseStatus.RECAPTURE ||
      info?.customer_license[0]?.status === LicenseStatus.MANUAL_REVIEW)){
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.LICENSE_CHECK));
      return;
    }
    if (order.is_trade_in === null) {
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.TRADE_IN_CHECKED));
      return;
    }
        if (!authenticationStatus) {
        localStorage.setItem('vin', vehicle?.vin)
        navigate(Navigation.AUTHENTICATION.SIGN_IN_PAGE.replace(':tenant', appState?.slug));

    }
    const hasFinancingErrors = checkAndUpdateFinancingTabErrors();
    if (hasFinancingErrors) {
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.MANDATORY_TAB_REQUIRED_TEXT));
      setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 5000);
      return;
    }
    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVING_IN_PROGRESS))

    const { order_fni, order_fees, dealer_fees, ...payload_order } = order;
    const orderFees = order_fees?.map((fee) => {
      if (fee.calculation_type === FEE_MONTHLY_PAYMENT_BASED && fee.is_active && fee.event === FeeTypes.EOT) {
        const dealer_fee = order?.dealer_fees?.find(dealerFee =>dealerFee.id === (fee?.order_id ? fee.dealer_fee_id : fee.id))
        const multipliedValue = (order?.estimated_monthly_payment + order?.monthly_sales_use_tax) * fee?.multiplication_factor;
        fee.applied_price = dealer_fee?.default_amount;
        if (multipliedValue < dealer_fee?.default_amount) {
          fee.applied_price = parseFloat(multipliedValue.toFixed(2));
        }
      }
      return fee;
    });

    const payload = {
      ...payload_order,
      down_payment: (order?.down_payment / order?.selling_price) * 100,
      allowed_usage: order?.annual_usage,
      running_id: localStorage.getItem('running_id'),
      lender_id: order?.lender_id ?? LenderConfigurations?.data?.id,
      order_options: order?.order_options?.length > 0 ? order?.order_options : null,
      order_fnI: order?.order_fnI?.length > 0 ? order?.order_fnI : null,
      insurance: order?.insurance?.length > 0 ? order?.insurance : null,
      order_fees: orderFees
    }
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.SUBMIT_ORDER,
      user_id: user_profile?.user_id,
      dealer_id: +order?.dealer_id,
      vehicle_make: order?.order_asset?.make,
      vehicle_model: order?.order_asset?.model,
      order_id:order?.identifier,
      vin:order?.vin,
      page_type:PageType.ReviewOrder,
      data:payload
      }
      createTags(event)
      const event_end: Event = {
        session: user_session,
        slug:tenant,
        event_name:Tags.REVIEW_ORDER_END,
        user_id: user_profile?.user_id,
        page_type:PageType.ReviewOrder
        }
        createTags(event_end)
    if (order?.identifier) {
      updateOrderRequest(payload, {
        onSuccess() {
          if (order?.order_tradein) {
            finalSave()
            updateOrderStatus({
              status: OrderStatus.PendingConfirmation,
              reference_number: order?.reference_number,
              user_id: 0
            })
          }
          snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_UPDATE_SUCCESS))
          setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 3000);
          setOpenPopup(true);
        },
        onError() {
          snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_UPDATE_FAIL))
          setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 3000);
        }
      });
    } else {
      saveOrderRequest([payload], {
        onSuccess(response) {
          finalSave()
          orderDispatch(updateOrder(response[0]))
          const quotationObject = response
            .filter(data => data?.finance_type === order?.finance_type)
            .map(data => {
              return { ...data, base_rate: 0, margin: 0, applicable_rate: 0 };
            });
          convertQuotationRequest({ ...quotationObject[0], changeVehicleStatus: false }, {
            onSuccess() {
              if (response[0]?.order_tradein) {
                updateOrderStatus({
                  status: OrderStatus.PendingConfirmation,
                  reference_number: response[0]?.reference_number,
                  user_id: 0
                })
              }
              snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVE_SUCCESS))
              setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 3000);
              setOpenPopup(true);
            },
            onError() {
              snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVE_FAILED))
              setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 3000);
            }
          });
        },
        onError() {
          snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVE_FAILED))
          setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 3000);
        }
      });
    }
  }

  const checkAndUpdateFinancingTabErrors = () => {
    const childTabs = [
      { type: pagesActionType.UPDATE_EMPLOYMENT_DETAILS, data: pageState.finance.employment_details },
      { type: pagesActionType.UPDATE_LICENSE_DETAILS, data: pageState.finance.license_details },
      { type: pagesActionType.UPDATE_PERSONAL_DETAILS, data: pageState.finance.personal_details },
      { type: pagesActionType.UPDATE_PERSONAL_FINANCE_DETAILS, data: pageState.finance.personal_finance_details },
      { type: pagesActionType.UPDATE_RESIDENCE_DETAILS, data: pageState.finance.residence_details },
    ];

    const financingTabErrors = childTabs.filter(tab => !tab.data.success);
    if (financingTabErrors.length > 0) {
      financingTabErrors.forEach(tab => {
        pageDispatch({
          type: tab.type,
          payload: {
            success: false,
            error: true
          }
        });
      });
      return true;
    }

    return false;
  }

  return (
    <PageWrap theme={theme} className='page-wrap'>
      <Grid theme={theme} container item spacing={{ xs: 3, lg: 10 }}>
        <Grid theme={theme} item xs={12} lg={7.5}>
          <PageHeader
            title={PLACEHOLDERS.REVIEW_ORDER_TITLE}
            subTitle={PLACEHOLDERS.REVIEW_ORDER_SUB_TITLE}
          />
          <Box theme={theme} mt={5} className='payment-breakdown'>
            <PaymentBreakDownGridContent
              theme={theme}
              order_info={order_info}
              default_currency_symbol={defaultCurrency}
              sortedOptionsArray={order_info?.order_options?.sort((a, b) => a.installation_mode.localeCompare(b.installation_mode))}
            />
          </Box>
          <Box theme={theme} mt={3}>
            <Button
              className={`${TaggingClasses.REVIEW_ORDER}-submit`}
              id='submitOrderID'
              theme={theme}
              fullWidth
              primary
              text={PLACEHOLDERS.REVIEW_ORDER_SUBMIT_ORDER}
              onClick={handleSubmitOrderClick}
              disabled={saveRequestLodaing || updateRequestLodaing}
            />
          </Box>
        </Grid>
        <Grid theme={theme} item xs={12} lg={4.5}>
          <Box theme={theme} p={{ xs: '40px 24px', md: 0 }}>
            <Typography theme={theme} component={'h4'} variant='h4' mb={3}>{PLACEHOLDERS.REVIEW_ORDER_WHATS_NEXT}</Typography>
            <StepperProgress
              stepItems={[
                {
                  title: PLACEHOLDERS.REVIEW_ORDER_SUBMIT_YOUR_ORDER,
                  subtitle: PLACEHOLDERS.REVIEW_ORDER_CHECK_ENSURE_SUBMIT_DOUBLE_CHECK,
                  active: true,
                },
                {
                  title: PLACEHOLDERS.REVIEW_ORDER_ORDER_RECEIVED,
                  subtitle: PLACEHOLDERS.REVIEW_ORDER_REVIEW_FINALIZE_SUBMIT_CREDIT
                },
                {
                  title: PLACEHOLDERS.REVIEW_ORDER_FINALISE_PURCHASE,
                  subtitle: PLACEHOLDERS.REVIEW_ORDER_SIGN_CONTRACTS_PAY
                },
                {
                  title: PLACEHOLDERS.REVIEW_ORDER_RECEIVE_YOUR_VEHICLE,
                  subtitle: PLACEHOLDERS.REVIEW_ORDER_PAYMENT_FINALISE_APPOINTMENT_KEYS
                }
              ]}
            />
          </Box>
        </Grid>
      </Grid>
      {openPopup && (
        <CreditRequestDisclaimerPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          snackbardispatch={snackbardispatch}
          vin={vehicle?.vin}
        />
      )}

      <Snackbar
        theme={theme}
        message={snackbarState.message === PLACEHOLDERS.ORDER_SAVING_IN_PROGRESS ?
          <Box theme={theme} display={'flex'}>
            <Box theme={theme} width="30px">
              <CircleLoader theme={theme} size='xs' />
            </Box> {snackbarState.message}
          </Box> : snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      />
    </PageWrap>
  )
}
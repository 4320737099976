import { useEffect, useState } from 'react'
import { FC } from 'react'
import { useTheme } from '@mui/material'
import { AddressLayout, Grid, PhoneInput } from '@ntpkunity/controls'
import { useGetCountries, useGetStates } from '@apis/dealer-workqueue.service'
import { accessToken } from '@helpers/constants'
import { useStoreContext } from '@store/storeContext'
import { Controller } from 'react-hook-form'

interface IAddressComponentProps {
  isGoogleEnabled: boolean
  form: any
  addressValue?: string
  errorProps?: any
  setIsAddressDirty?: any
  moveIn?: boolean
  control?: any
  showContactNumber?: boolean
  contactError?: string
  hideMoveInHelper?: boolean
}

const AddressDetailsComponent: FC<IAddressComponentProps> = ({
  isGoogleEnabled,
  form,
  addressValue,
  setIsAddressDirty,
  errorProps,
  moveIn = true,
  control,
  showContactNumber = false,
  contactError,
  hideMoveInHelper = false
}) => {
  const theme = useTheme()
  const { setValue, watch } = form
  const { mutate: getCountry, data: countriesServiceData } = useGetCountries()
  const { mutate: getStates, data: statesServiceData } = useGetStates()
  const { states } = useStoreContext()

  const [statesData, setStatesData] = useState([])
  const [countriesData, setCountriesData] = useState([])
  const [dealerCountry, setDealerCountry] = useState<Array<any>>([])

  useEffect(() => {
    getCountry()
    getStates()
  }, [])

  useEffect(() => {
    setCountriesData(countriesServiceData)
    setStatesData(statesServiceData)
  }, [countriesServiceData, statesServiceData])

  useEffect(() => {
    setDealerCountry(
      countriesData?.filter(
        (x: any) => x?.id == states?.dealDesk?.dealerProfile?.dealer_address?.country_id
      )
    )
  }, [countriesData, states?.dealerProfileData])

  return (
    <>
      {dealerCountry?.length > 0 && statesData?.length > 0 ? (
        <>
          <AddressLayout
            addressVerificationRequired={isGoogleEnabled}
            hideAddressButton={!isGoogleEnabled}
            theme={theme}
            state={{
              data: statesData,
              selectedItemObject: { text: 'name', value: 'name' },
              controlName: 'state_name'
            }}
            countries={dealerCountry}
            validationProps={errorProps}
            isContactRequired={false}
            value={watch(`${addressValue}`)}
            verifyAddressURL={`${process.env.INTEGRATION_API_GATEWAY}/api/verify-address/`}
            token={JSON.parse(localStorage.getItem(accessToken) as string)?.access_token}
            dealerCode={states?.dealerInfo?.dealer_code}
            setIsAddressDirty={setIsAddressDirty}
            onChange={(e: any) => {
              setValue(`${addressValue}`, e)
              setValue(`${addressValue}${'.state_name'}`, e?.state_name)
              setValue(`${addressValue}${'.state_id'}`, e?.state_id)
            }}
            showCountryDropdown={false}
            disableCounty={isGoogleEnabled}
            moveInRequired={moveIn}
            hideMoveInHelper={hideMoveInHelper}
          ></AddressLayout>
          {showContactNumber && (
            <Grid theme={theme} item xs={12} mt={3}>
              <Controller
                name="agent_phone"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    theme={theme}
                    type={'text'}
                    {...field}
                    label="Contact Number"
                    fullWidth
                    phoneCountryCode={dealerCountry?.[0]?.country_code ?? 'US'}
                    error={contactError}
                  />
                )}
              />
            </Grid>
          )}
        </>
      ) : null}
    </>
  )
}
export default AddressDetailsComponent

import { Button, Image } from "@src/components/atoms";
import { Col, Modal, Row } from "antd";
import Vehicle from "../../../../shared/assets/img/vehicle-modal.png";
import "./remove-deal-modal.scss";

export const RemoveDealModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleClose}
      footer={false}
      centered
      width={1144}
      className="deal-modal"
    >
      <Row>
        <Col xs={24} md={12}>
          <Image src={Vehicle} alt="image" aspectRatio="2:3" />
        </Col>
        <Col xs={24} md={12}>
          <div className="deal-modal__content">
            <div>
              <h3 className="text--h3">
                Are you sure you want to remove the deal you’ve built for this
                [20XX BMW Model Name]?
              </h3>
              <p className="text--body-1">
                Deleting this deal will remove it from your saved deals. Select
                cancel if you prefer to save it.
              </p>
            </div>
            <div className="deal-modal__cta">
              <Button type="primary" htmlType={"button"} text="Remove deal" />
              <Button type="default" htmlType={"button"} text="Cancel" />
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

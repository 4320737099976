import { Modal as AntModal } from "antd";
import clsx from "clsx";
import { ModalProps } from "./modal.props";
import "../../../styles/components/_modal.scss";

export const Modal = ({
  open,
  title = "",
  onClose,
  children,
  footer = false,
  closable = true,
  size,
  className,
  centered = true,
  keyboard,
  maskClosable,
  subtitle,
  content,
  footerSticky = false,
  headerSticky = false,
}: ModalProps) => {
  return (
    <div className="modal-wrapper">
      <AntModal
        className={clsx(className, {
          "modal-wrapper__modal": true,
          "modal-wrapper__modal--size-compact": size == "compact",
          "modal-wrapper__modal--no-footer": footer == false,
          "modal-wrapper__modal--no-content": content == false,
          "modal-wrapper__modal--sticky-header":
            headerSticky == true && footerSticky == false,
          "modal-wrapper__modal--sticky-footer":
            headerSticky == false && footerSticky == true,
          "modal-wrapper__modal--sticky-header-footer":
            headerSticky == true && footerSticky == true,
          "modal-wrapper__modal--sticky-footer-description":
            footerSticky == true,
        })}
        closable={closable}
        open={open}
        onClose={onClose}
        footer={false}
        getContainer={false}
        centered={centered}
        closeIcon={
          /* jsx-a11y/no-static-element-interactions */
          <span
            className="bmw-icon bmw-icon--before data-icon"
            data-icon="close"
            role="button"
            tabIndex={0}
            onClick={onClose}
          ></span>
        }
        keyboard={keyboard}
        maskClosable={maskClosable}
      >
        <div className="modal-wrapper__modal-content scroll">
          <div className="modal-content__header">
            {title && <div className="modal-content__title">{title}</div>}
          </div>
          <div className="modal-content__body scroll">
            {subtitle && (
              <div className="modal-content__subtitle">{subtitle}</div>
            )}
            <div className="modal-content__content">{children}</div>
          </div>
          {footer && <div className="modal-content__footer">{footer}</div>}
        </div>
      </AntModal>
    </div>
  );
};

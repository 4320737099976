import { IApplicationState } from "@src/types";

export const initialState: IApplicationState = {
  // UI State
  ui: {
    theme: {},
    openChat: false,
    collapseState: false,
  },

  // User Information
  user: {
    isUserLoggedIn: !!localStorage.getItem("access_token"),
    email: "",
    customer: {
      first_name: "",
      middle_name: "",
      last_name: "",
      customer_type: "",
      date_of_birth: "",
      ssn: "",
      mobile_number: "",
      home_number: "",
      preferred_contact: "",
      reference_id: "",
      identifier: "",
      email: "",
      income_info: {
        employment_type: "",
        name: null,
        phone: null,
        employed_since: "",
        occupation: null,
        annual_employment_income: null,
        annual_other_income: 0,
        source_of_other_income: "",
      },
      financial_profile: {
        residence: "",
        monthly_payment: 0,
        bankruptcy: false,
      },
      license_info: {
        first_name: "",
        last_name: "",
        status: null,
        license_number: "",
        is_pdf_417: false,
        issue_date: null,
        expiry_date: "",
        date_of_birth: "",
        is_selfie_verified: null,
      },
      payments_info: [],
      customer_addresses: {
        previous: {
          address_line_1: "",
          address_line_2: "",
          state_name: "",
          zip_code: "",
          county: "",
          city: "",
          contact_number: null,
          verified: false,
          move_in_date: "",
          is_garaging: true,
          identifier: "",
        },
        mailing: {
          address_line_1: "",
          address_line_2: "",
          state_name: "",
          zip_code: "",
          county: "",
          city: "",
          contact_number: null,
          verified: false,
          move_in_date: "",
          is_garaging: true,
          identifier: "",
        },
        garaging: {
          address_line_1: "",
          entity_type: null,
          address_line_2: "",
          state_name: "",
          zip_code: "",
          county: "",
          city: "",
          contact_number: null,
          verified: false,
          move_in_date: "",
          is_garaging: false,
          identifier: "",
        },
        billing: {
          entity_type: null,
          address_line_1: "",
          address_line_2: "",
          state_name: "",
          zip_code: "",
          county: "",
          city: "",
          contact_number: null,
          verified: true,
          move_in_date: null,
          is_garaging: false,
          identifier: "",
        },
        drivingLicense: {
          address_line_1: null,
          address_line_2: null,
          state_name: "",
          zip_code: null,
          county: null,
          city: null,
          contact_number: null,
          verified: false,
          move_in_date: null,
          is_garaging: false,
          identifier: "",
        },
        employment: {
          address_line_1: null,
          address_line_2: null,
          state_name: null,
          zip_code: null,
          county: null,
          city: null,
          contact_number: null,
          verified: null,
          move_in_date: null,
          is_garaging: false,
          identifier: "",
        },
      },
    },
  },
  states: [],
  employmentTypes: ["Employed", "Self Employed", "Retired", "Not Employed"],
  // Tenant Information
  tenant: {
    tenantId: 0,
    tenantName: "",
    slug: "",
    email: "",
    companyCode: "",
    dealerCode: "",
    mileageUnit: "",
    company_id: 0,
    defaultRadius: 0,
    min_months_stay_at_address: null,
    currency: {
      defaultCurrency: 0,
      defaultCurrencySymbol: "",
      defaultCurrencyCode: "",
    },
  },
  lender: {
    defaultLender: {
      id: 0,
      name: "",
      default_lender: false,
    },
    lenders: [],
  },
  order: {
    status: "",
    customer_info: {
      email: "",
      reference_id: "",
    },
    finance_type: "",
    company_provider: [],
    Insurance_Inforation: {
      identifier: "",
      agent_phone: "",
      insurer_name: "",
      order_reference_id: "",
      created_by: "",
      updated_by: "",
    },
    dealer_id: 0,
    reference_number: "",
  },
  dealer: {
    dealer_code: "",
  },
  // Configuration
  configuration: {
    language: { placeholders: "" },
    defaultCurrency: 0,
    defaultCurrencySymbol: "",
    defaultCurrencyCode: "",
    defaultRadius: 0,
    mileageUnit: "",
    urlOrigin:
      process.env.NODE_ENV === "development"
        ? "checkout-dev.bmwdr.io"
        : window.location.origin.replace(/^https?:\/\//, ""),
  },

  // Links and Social Media
  links: {
    headerLinks: [],
    footerLinks: [],
    socialMediaIcons: {},
  },
};

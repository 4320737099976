import { useTheme } from '@mui/material'
import { PageWrap } from './inventory.style'
import { LeadKey, user_session } from '@constants'
import { useCustomerByEmail, useGetCurrencies, useGetDealerProfileByDealerCode, useUpdateLead } from '@hooks'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { QueryKeys } from '@constants'
import { useQueryClient } from 'react-query'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { PricingQuestionnairePopup } from 'libraries/pricing-questionnaire-popup/pricing-questionnaire-popup.component'
import { useAppContext } from '@app/context-provider'
import { Tags, PageType, TaggingId, TaggingClasses } from 'constants/enums';
import { useWorkflowContext } from '@app/router-workflow'
import { mapPayload } from 'helpers/lead'
import {AssetSelectionControl} from "@ntpkunity/controls-common";
export const InventoryPage = () => {
  // using useParams to get the tenant from the url
  const { tenant } = useParams()
  const { mutate: createTags } = useTags();
  const theme = useTheme()
  const queryClient = useQueryClient();
  const setting = JSON.parse(localStorage.getItem("settings"))
  const { state: appState, dispatch: appDispatch } = useAppContext()
  const { mutate: updateLead } = useUpdateLead(appState?.slug);
  const { navigateNext } = useWorkflowContext()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const dealer_profile = JSON.parse(localStorage.getItem("dealer_information"))
  const PLACEHOLDERS = appState.language.placeholders;
  const { data: currencyData } = useGetCurrencies(appState?.slug);
  const [popUpValue, setPopUpValue] = useState(true);
  const [currency, setCurrency] = useState<string>("")
  const dealerCode = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code ?? 0;
  const dealerProfile: any = queryClient.getQueryData([QueryKeys.DEALER_PROFILE, dealerCode])

  const customerData: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL);
  const userEmail = JSON.parse(localStorage.getItem("settings"))?.email
  const { data: customer } = useCustomerByEmail(userEmail, dealer_profile?.dealer?.dealer_id)

  const placeholders = {
    newInventory: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_NEW_INVENTORY,
    allBrands: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_ALL_BRANDS,
    startingAt: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_STARTING_AT,
    msrp: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_MSRP,
    search: PLACEHOLDERS.SEARCH_PLACEHOLDER,
    year: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_YEAR,
    make: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_MAKE,
    vehicle: PLACEHOLDERS.VIN_TEXT,
    price: PLACEHOLDERS.INV_ASSET_LIST_VIEW_CONTROL_PRICE,
    filters: PLACEHOLDERS.FILTERS_TEXT,
    clearAll: PLACEHOLDERS.INV_ASSET_LIST_VIEW_CONTROL_CLEAR_ALL,
    new: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_NEW,
    min: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_MIN,
    max: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_MAX,
    sellingPrice: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_SELLING_PRICE,
    from: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_FROM,
    to: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_TO,
    model: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_MODEL,
    trim: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_TRIM,
    makeModelTrim: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_MAKE_MODEL_TRIM,
    vin: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_VIN,
    stock: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_STOCK,
    stockNumber: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_STOCK_NUMBER,
    resetFilters: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_RESET_FILTERS,
    applyFilters: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_APPLY_FILTERS,
    preOwned: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_PRE_OWNED,
    certifiedPreOwned: PLACEHOLDERS.INV_ASSET_FILTER_CONTROL_CERTIFIED_PRE_OWNED,
    recommendedText: PLACEHOLDERS.INV_ASSET_LIST_VIEW_CONTROL_RECOMMENDED,
    resultsFor: PLACEHOLDERS.INV_ASSET_LIST_VIEW_CONTROL_RESULTS_FOR,
    placeholder: PLACEHOLDERS.INV_ASSET_LIST_VIEW_CONTROL_SEARCH,
    location: PLACEHOLDERS.LOCATION_TEXT,
    edit: PLACEHOLDERS.EDIT_TEXT,
    sortBy: PLACEHOLDERS.INVENTORY_ASSET_LIST_SORT_BY,
    noDataFound: PLACEHOLDERS.NO_DATA_IS_FOUND_TEXT,
    selectContinue: PLACEHOLDERS.BUTTON_TEXT_SELECT_CONTINUE,
    button: PLACEHOLDERS.INVENTORY_ASSET_LIST_BUTTON,
    zipCodeCalculatePricing: PLACEHOLDERS.ZIP_CODE_CALCULATE_PRICING,
    zipCode: PLACEHOLDERS.ZIP_CODE,
    zipCodeRequired: PLACEHOLDERS.ZIP_CODE_REQUIRED,
    zipCodeLengthRequired: PLACEHOLDERS.ZIP_CODE_LENGTH_5,
    zipCodeEnter: PLACEHOLDERS.ZIP_CODE_ENTER,
    zipCodeDealerDistance: PLACEHOLDERS.ZIP_CODE_SELECT_DEALERSHIP,
    noDealershipFound: PLACEHOLDERS.ZIP_CODE_NO_DEALERSHIP_FOUND,
    noDealershipAvailable: PLACEHOLDERS.ZIP_CODE_NO_DEALERSHIP_AVAILABLE,
    milesAway: PLACEHOLDERS.ZIP_CODE_MILES_AWAY,
    assetDetails: PLACEHOLDERS.INVENTORY_ASSET_DETAILS,
    vehicleFeaturesSpecifications: PLACEHOLDERS.INVENTORY_ASSET_DETAILS_VEHICLE_FEATURES_SPECIFICATIONS_FIELD_LABEL,
    driveTrain: PLACEHOLDERS.INVENTORY_ASSET_DETAILS_DRIVE_TRAIN_FIELD_LABEL,
    engine: PLACEHOLDERS.INVENTORY_ASSET_DETAILS_ENGINE_FIELD_LABEL,
    exteriorColor: PLACEHOLDERS.INVENTORY_ASSET_DETAILS_EXTERIOR_COLOR_FIELD_LABEL,
    interiorColor: PLACEHOLDERS.INVENTORY_ASSET_DETAILS_INTERIOR_COLOR_FIELD_LABEL,
    transmissionType: PLACEHOLDERS.INVENTORY_ASSET_DETAILS_TRANSMISSION_TYPE_FIELD_LABEL,
    ascending: PLACEHOLDERS.ASCENDING_TEXT,
    descending: PLACEHOLDERS.DESCENDING_TEXT,
    reset: PLACEHOLDERS.RESET_TEXT,
    milage: PLACEHOLDERS.MILAGE_TEXT,
    noResultsFound: PLACEHOLDERS.NO_RESULTS_FOUND_TEXT,
    assetUnavailableLookForOthers: PLACEHOLDERS.ASSET_UNAVAILABLE_LOOK_FOR_OTHER_ASSET,
    sellingPriceHighToLow: PLACEHOLDERS.INVENTORY_ASSET_LIST_SELLING_PRICE_HIGH_TO_LOW,
    sellingPriceLowToHigh: PLACEHOLDERS.INVENTORY_ASSET_LIST_SELLING_PRICE_LOW_TO_HIGH,
    miltesText: PLACEHOLDERS.MILES_TEXT,
    vinNumber: PLACEHOLDERS.VIN_NUMBER_TEXT,
    zipCodeTellUs: PLACEHOLDERS.ZIP_CODE_TELL_US,
    zipCodeRange: PLACEHOLDERS.ZIP_CODE_RANGE,
    priceForYou: PLACEHOLDERS.VEHICLE_PRICE_FOR_YOU,
    horsepower: PLACEHOLDERS.HORSEPOWER_TEXT,
    vehicleDetails: PLACEHOLDERS.VEHICLE_DETAILS_TEXT,
    sortbydefault: PLACEHOLDERS.DEFAULT_TEXT
  }
  useEffect(() => {
    const event: Event = {
      session: user_session,
      slug: tenant,
      event_name: Tags.LANDING_ON_INVENTORY_SCREEN,
      user_id: user_profile?.user_id,
      page_type: PageType.InventoryImpression
    }
    createTags(event)
  }, [])
  const event_calling = (val: string, obj?: object) => {
    const event: Event = {
      session: user_session,
      slug: tenant,
      event_name: val,
      user_id: user_profile?.user_id,
      page_type: PageType.InventoryImpression,
      data: obj ? obj : {}
    }
    createTags(event)
    if(val == 'Apply Filters'){
      let lead = mapPayload(dealer_profile?.dealer?.dealer_id,tenant,customerData?.reference_id,LeadKey.Vehicle_Search,obj)
      updateLead(lead);
    }
  }

  const DealerCallback = (dealer_id:number)=>{
    if(dealer_id){
      let lead = mapPayload(dealer_id, tenant, customerData?.reference_id, LeadKey.Vehicle_Search,undefined)
      lead = mapPayload(dealer_id,tenant,customerData?.reference_id,LeadKey.Customer_Detail,{'email':setting?.email})
      updateLead(lead);
    }
  }
  useEffect(() => {
    const tenant_configuration: any = queryClient.getQueryData(QueryKeys.TENANT_CONFIGURATION);
    if (Array.isArray(currencyData)) {
      const defaultCurrency = currencyData?.find(
        (currency: any) => currency.id === tenant_configuration?.data?.default_currency
      )?.symbol;
      setCurrency(defaultCurrency);
    }
  }, [currencyData]);

  const data = JSON.parse(localStorage.getItem('workflow') || '{}')

  const onNextStep = (vin: string) => {
    navigateNext('', { vin: vin })
  }

  return (
    <PageWrap theme={theme} className="page-wrap">
      {dealerCode === 0 ?
        <PricingQuestionnairePopup
          companyId={appState?.tenant}
          open={popUpValue}
          closeDialog={true}
          setPopUpValue={setPopUpValue}
          popupText={PLACEHOLDERS.ZIP_CODE_CALCULATE_PRICING} />
        :
        <>
          <AssetSelectionControl
            taggingClass={TaggingClasses.PAYMENT}
            theme={theme}
            dealerCode={dealerCode}
            defaultCurrency={currency}
            onClick={() => { queryClient.invalidateQueries(QueryKeys.GET_SETUP_DATA)}}
            onListViewClick={onNextStep}
            DealerCallback={DealerCallback}
            accessToken={localStorage.getItem("access_token")}
            company_Id={appState?.tenant}
            placeholders={placeholders}
            eventCallback={event_calling}
            dealerWebsite={dealerProfile?.website}
            onFilterData={function (data: string, showFilterTag: boolean): void {
            }}
            slug={appState?.slug}
          />
        </>
      }

    </PageWrap>
  );

}
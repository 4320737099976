import { Button, Input, Radio, Select } from "@src/components/atoms";
import "./financial-information.scss";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";
import { Phone } from "@src/components/atoms/phone-input/phone-input.component";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { financialProfileValidator } from "@src/validators";
import {
  useCustomerUpdate,
  useUpdateCustomerFinancialProfile,
} from "@src/hooks";
import { Customer } from "@src/types/customer";
import { updateCustomer } from "@src/context/app-actions";
import {
  calculateDateDifference,
  cleanIncomeInfo,
} from "@src/helpers/appHelper";
import dayjs from "dayjs";

export const FinancialInformation = ({
  stepThreeDataReceivedEvent,
  stateOptions,
  employmentTypes,
  customer,
  appDispatch,
  dealerCode,
}: {
  stepThreeDataReceivedEvent: () => void;
  employmentTypes;
  stateOptions;
  customer: Customer;
  appDispatch;
  dealerCode;
}) => {
  const { customer_addresses, reference_id, income_info, financial_profile } =
    customer;
  const { mutate: updateCustomerData, isPending: updateCustomerLoader } =
    useCustomerUpdate({
      reference_id: reference_id,
      dealer_code: dealerCode,
    });
  const {
    mutate: updateCustomerFinancialProfile,
    isPending: updateCustomerFinancialProfileLoader,
  } = useUpdateCustomerFinancialProfile({
    reference_id: reference_id,
    dealer_code: dealerCode,
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(financialProfileValidator),
    defaultValues: {
      ...income_info,
      address: { ...customer_addresses.employment, address_type: "Employment" },
      financial_profile: financial_profile,
    },
  });

  const employed = watch("employment_type") === "Employed";

  const onSubmit = (values) => {
    const { financial_profile, employed_since, ...rest } = values;

    updateCustomerData(
      {
        ...rest,
        employed_since: dayjs(employed_since).format("YYYY-MM-DD"),
        type: "/employment-info",
      },
      {
        onSuccess: (data) => {
          const {
            address_type,
            created_at,
            created_by,
            updated_at,
            updated_by,
            deleted_at,
            deleted_by,
            entity_type,
            ...rest
          } = data.address;

          appDispatch(
            updateCustomer({
              ...customer,
              income_info: cleanIncomeInfo(data),
              customer_addresses: { ...customer_addresses, employment: rest },
            })
          );

          stepThreeDataReceivedEvent();
        },
        onError: (error) => {
          console.error("Error fetching customer:", error);
        },
      }
    );
    updateCustomerFinancialProfile(financial_profile, {
      onSuccess: ({ bankruptcy, monthly_payment, residence }) => {
        appDispatch(
          updateCustomer({
            ...customer,
            financial_profile: {
              bankruptcy,
              monthly_payment,
              residence,
            },
          })
        );
      },
      onError: (error) => {
        console.error("Error fetching customer:", error);
      },
    });
  };

  return (
    <div className="checkout">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="checkout-form-header checkout-form-header--padding">
          <h3 className="checkout-title text-secondary text--subsection-2">
            03
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Financial Information.
          </h3>
        </div>
        <p className="financial-checkout-subtitle text--body-2">
          Tell us about your current employment situation. This information
          helps us verify your identity and personalize your payment terms.
        </p>
        <div className="financial-occupation-wrapper">
          <div>
            <div className="form-inner-field-container checkout-column-wrapper">
              {employed && (
                <Controller
                  name="occupation"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      id="financial-information--occupation"
                      placeholder="Occupation"
                      label="Occupation"
                      error={error}
                      type="text"
                      {...field}
                    />
                  )}
                />
              )}
              <Controller
                name="employment_type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    id="employment-type"
                    options={employmentTypes.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    error={error}
                    placeholder="Select"
                    label="Employment type"
                  />
                )}
              />

              {employed && (
                <>
                  <Controller
                    name="annual_employment_income"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        {...field}
                        type="text"
                        error={error}
                        prefix="$"
                        id="financial-information--annual_income"
                        placeholder="$0.00"
                        label="Annual income"
                      />
                    )}
                  />

                  <Controller
                    name="employed_since"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Datepicker
                        id="start_date"
                        placeholder="Choose"
                        label="Employed Since"
                        format="MMM DD, YYYY"
                        error={error}
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="employment_duration"
                    control={control}
                    disabled
                    render={({ field: { value, ...rest } }) => (
                      <Input
                        {...rest}
                        value={calculateDateDifference(watch("employed_since"))}
                        id="financial-information--duration_employment"
                        placeholder="Duration"
                        label="Duration of employment"
                      />
                    )}
                  />
                </>
              )}
              <Controller
                name="annual_other_income"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="financial-information--annual_income"
                    placeholder="$0.00"
                    error={error}
                    prefix="$"
                    type="text"
                    label="Annual Other income"
                    {...field}
                  />
                )}
              />
              <Controller
                name="source_of_other_income"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    error={error}
                    id="financial-information--annual_income"
                    placeholder="$0.00"
                    label="Source of other income"
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="financial-employer-container">
          <p className="financial-subtitle text--body-2">
            Please provide your employment information.
          </p>

          <div>
            {employed && (
              <div className="form-inner-field-container checkout-column-wrapper">
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      id="financial-information--employer_name"
                      placeholder="Name"
                      error={error}
                      label="Employer name"
                    />
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Phone
                      {...field}
                      country="us"
                      formState={fieldState}
                      label="Employer phone number"
                    />
                  )}
                />
              </div>
            )}
          </div>
          {["Employed", "Self Employed"].includes(watch("employment_type")) && (
            <>
              <div className="form-inner-field-container">
                <Controller
                  name="address.address_line_1"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      error={error}
                      id="financial-information--address1"
                      placeholder="Street address"
                      label="Address 1"
                    />
                  )}
                />
              </div>

              <div className="form-inner-field-container">
                <Controller
                  name="address.address_line_2"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      error={error}
                      id="financial-information--address2"
                      placeholder="Apartment, suite, unit, building, floor"
                      label="Address 2 (optional)"
                      value={field.value || ""}
                    />
                  )}
                />
              </div>

              <div className="form-inner-field-container checkout-column-wrapper">
                <Controller
                  name="address.city"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      id="financial-information--city"
                      placeholder="City"
                      label="City"
                      error={error}
                    />
                  )}
                />

                <Controller
                  name="address.state_name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      id="financial-information--state"
                      placeholder="Select"
                      label="State"
                      options={stateOptions}
                      error={error}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="address.zip_code"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      id="financial-information--zip_code"
                      placeholder="ZIP Code"
                      error={error}
                      label="ZIP Code (optional)"
                    />
                  )}
                />
              </div>
            </>
          )}
          <div className="form-inner-field-container form-inner-additional-icome">
            <div className="form-inner--add-plus-button">
              <i className="form-inner--add-plus-button-icon" />
              <span className="text--cta-2">Add additional income</span>
            </div>
          </div>
        </div>

        <hr className="checkout-hr" />

        <div className="financial-residence-container">
          <p className="text--body-2 financial-residence-text-own">
            Please provide your residence status.
          </p>
          <div className="financial-radio-group">
            <Controller
              name="financial_profile.residence"
              control={control}
              render={({ field: { value, onChange, ...rest } }) => (
                <>
                  <Radio
                    {...rest}
                    id="radio--mortgage"
                    defaultValue="Mortgage"
                    checked={value === "Mortgage"}
                    onChange={() => onChange("Mortgage")}
                    label="Mortgage"
                  />
                  <Radio
                    {...rest}
                    id="radio--rent"
                    checked={value === "Renting"}
                    onChange={() => onChange("Renting")}
                    defaultValue="Renting"
                    label="Renting"
                  />
                  <Radio
                    {...rest}
                    id="residence-with-relatives"
                    onChange={() => onChange("With Relatives")}
                    defaultValue="With Relatives"
                    checked={value === "With Relatives"}
                    label="With Relatives"
                  />
                  <Radio
                    {...rest}
                    id="radio--own_free_clear"
                    onChange={() => onChange("Personal Ownership")}
                    checked={value === "Personal Ownership"}
                    defaultValue="Own Free & Clear"
                    label="Own Free & Clear"
                  />
                </>
              )}
            />
          </div>
          {["Mortgage", "Renting"].includes(
            watch("financial_profile.residence")
          ) && (
            <div className="financial-monthly-container">
              <div className="form-inner-field-container checkout-column-wrapper">
                <Controller
                  name="financial_profile.monthly_payment"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id={"input-wdchixq"}
                      placeholder="$0.00"
                      label="Monthly payments"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
        <div className="financial-residence-container">
          <p className="text--body-2 financial-residence-text-two">
            Have you ever applied for bankruptcy?
          </p>
          <div className="financial-residence-choice-container">
            <div className="financial-radio-group">
              <Controller
                name="financial_profile.bankruptcy"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <>
                    <Radio
                      {...rest}
                      id="yesCheckbox"
                      checked={value}
                      defaultValue="Yes"
                      label="Yes"
                    />
                    <Radio
                      {...rest}
                      id="noCheckbox"
                      checked={!value}
                      defaultValue="No"
                      label="No"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </div>

        <div className="financial-cta-wrapper">
          <Button
            type="default"
            isLoading={
              updateCustomerFinancialProfileLoader || updateCustomerLoader
            }
            htmlType="submit"
            text="Save & continue"
            fixed
            fullWidth
          />
        </div>
      </form>
    </div>
  );
};

import { Radio } from "@src/components/atoms/radio/radio.component";
import "./financial-services.scss";
import CardLogo from "../../../shared/assets/img/cardLogo.png";
import { FinancialServiceCardSelect } from "@src/components/atoms";

export const FinancialServiceCard = () => {
  return (
    <>
      <div className="offer-group">
        <div className="offer-container">
          <FinancialServiceCardSelect
            logo={CardLogo}
            title={"BMW Financial Services"}
            subTitle={
              "Tailored lease and finance options from the people who know BMW best."
            }
            price={" $604.01"}
            downCash={"$5,000"}
            apr={"5.9% APR"}
            term={"60 month finance term"}
            selected={true}
            hasCheckbox={
              <Radio
                label="Select"
                name="financialserviceRadio"
                id="financial-service-radio1"
                htmlFor="financial-service-radio"
              />
            }
          />
        </div>
        <div className="offer-container">
          <FinancialServiceCardSelect
            title={"Your third-party offer by Chase Bank"}
            price={" $604.01"}
            downCash={"$5,000"}
            apr={"5.9% APR"}
            term={"60 month finance term"}
            hasCheckbox={
              <Radio
                label="Select"
                name="financialserviceRadio"
                id="financial-service-radio2"
                htmlFor="financial-service-radio"
              />
            }
          />
        </div>
        <div className="offer-container">
          <FinancialServiceCardSelect
            title={"Your third-party offer by Citizen Bank"}
            price={" $604.01"}
            downCash={"$5,000"}
            apr={"5.9% APR"}
            term={"60 month finance term"}
            hasCheckbox={
              <Radio
                label="Select"
                name="financialserviceRadio"
                id="financial-service-radio3"
                htmlFor="financial-service-radio"
              />
            }
          />
        </div>
      </div>
    </>
  );
};

import { RegisterOptions } from "react-hook-form";

export const validatePhoneNumber = (
  inputNumber: string,
  country: any,
  phoneLength: number,
  isDirty?: boolean
) => {
  if (isDirty) {
    if (
      inputNumber &&
      inputNumber?.replace(country.dialCode, "")?.trim() === ""
    ) {
      return false;
    } else if (inputNumber.length < phoneLength) {
      return false;
    }
    return true;
  }
  return false;
};
export const formValidation = (
  name: string,
  required: boolean,
  matchPattern: boolean = false,
  passwordRegex: RegExp = new RegExp(""),
  regexMessage: string = ""
): RegisterOptions => {
  return {
    required: {
      value: required,
      message: `${name} is required`,
    },
    validate: {
      matchesPattern: (value: string | undefined) => {
        if (matchPattern && value && !passwordRegex.exec(value)) {
          return `${regexMessage}`;
        }
        return true;
      },
    },
  };
};

import * as yup from "yup";

export const addressValidator = yup.object().shape({
  mailing: yup.object({
    address_line_1: yup.string().required("Address Line 1 is required"),
    address_line_2: yup.string().nullable(),
    state_name: yup.string().required("State is required"),
    zip_code: yup
      .string()
      .matches(/^[0-9]{5}$/, "Invalid ZIP code")
      .required("ZIP code is required"),
    county: yup.string().required("County is required"),
    city: yup.string().required("City is required"),
    move_in_date: yup.date().required("Move-in date is required"),
  }),
  billingSameAsMailing: yup.boolean(),
  garagingSameAsMailing: yup.boolean(),
  billing: yup.object().when("billingSameAsMailing", {
    is: false,
    then: () =>
      yup.object({
        address_line_1: yup.string().required("Address line 1 is required"),
        address_line_2: yup.string().nullable(),
        state_name: yup.string().required("State is required"),
        zip_code: yup
          .string()
          .matches(/^[0-9]{5}$/, "Invalid ZIP code")
          .required("Zip code is required"),
        county: yup.string().nullable(),
        city: yup.string().required("City is required"),
      }),
  }),
  garaging: yup.object().when("garagingSameAsMailing", {
    is: false,
    then: () =>
      yup.object({
        address_line_1: yup.string().required("Address line 1 is required"),
        address_line_2: yup.string().nullable(),
        state_name: yup.string().required("State is required"),
        zip_code: yup
          .string()
          .matches(/^[0-9]{5}$/, "Invalid ZIP code")
          .required("Zip code is required"),
        city: yup.string().required("City is required"),
      }),
  }),
});

export const INSURANCE_PROVIDER = "/insurance-companies";
export const EP_ADD_INSURANCE_DETAILS = "configure/insurance";
export const EP_UPDATE_INSURANCE_DETAILS =
  "customer/update-insurance-information";
export const TRADE_IN_MODEL_YEARS = "dms/get-trade-in-year";
export const SEND_ORDER_LINK = "customer/send-order-link?reference_number=";
export const DMS_BASE_URL = process.env.DMS_BASE_URL;
export const CONFIG_BASE_URL = process.env.CONFIG_BASE_URL;
export const DEAL_INFO = "/order/order_information";
export const CUSTOMER = "customer";
export const GET_USER_REFERENCE = "dms/customers/reference-id?email=";
export const TRADE_IN_MAKES = "dms/get-trade-in-make";
export const SAVE_ORDER_QUOTATION = "dms/configure/save-quotation";
export const TRADE_IN_MODELS = "dms/get-trade-in-model";
export const TRADE_IN_TRIMS = "dms/get-trade-in-trim";
export const GET_CONFIG_TRADE_IN_PROVIDER_LOGO =
  "config/configbucket/providers";
export const DMS_FRAUD_CHECK = "/customer/fraud-check";
export const EP_SUBMIT_ORDER = "/submit-order";
export const DOMAIN = "/company/by-tenant-domain";
export const GET_LENDER_BY_SLUG = "config/company/by-tenant-slug/";
export const EP_DMS_GET_STATES = "/state";
export const CONFIG_GET_RATES = "get-rates";
export const DMS_CALCULATE_ORDER_QUOTATION = "dms/quote/calculation/V2";
export const DMS_INTEGRATION_BY_PROVIDER_NAME =
  "dms/integration/by_provider_name";
export const CONFIG_DEALER_PROFILE = "/dealer/profile";
export const GET_DMS_SETUP_DATA = "dms/get-setup-data";
export const GET_DMS_ALL_PROGRAMS = "config/programs/all/";
export const DEFAULT_LENDER_CONFIGURATION = "default/lender";
export const VEHICLE = "config/vehicle";
export const GET_CURRENCIES = "currencies";
export const CUSTOMER_EMAIL_CHECK = "dms/check-customer-by-email";
export const DMS_FNI_PRODUCT = "config/financial-insurances/filter";
export const GET_ALL_STATES = "config/state";
export const VEHICLE_DETAIL = "config/vehicle-detail";
export const GET_VEHICLE_DETAIL = "dms/get-vehicle-detail-by-vin/";
export const GET_TRADE_IN_VEHICLE = "dms/get-trade-in-vehicle_detail";
export const GET_TRADE_IN_VEHICLE_VALUE = "get-tradein-vehiclevalue";
export const GET_DEALER_FEES = "config/dealer/fee/dealer-code";
export const DMS_DEALER_PROFILE = "config/dealer/profile";
export const DMS_DEALER_PREFERENCE_BY_DEALER_CODE =
  "config/dealer/preference-by-dealer-code";
export const DMS_CALCULATE_NFA = "dms/quote/calculate-nfa";
export const UMS_DMS_CALL = "signup/dms";
export const GET_ACCESSORIES_BY_MODEL = "config/dealer/option-model-name";
export const GET_FNI_MONTHLY_PAYMENT_IMAPACT =
  "dms/quote/calculation/monthlyImpact";
export const RESEND_OTP = "user/resend-link";
export const VERIFY_OTP = "user/verify-url/";
export const SAVE_USER_INFO = "user/set-user-profile";
export const THEME_CONFIGURATION = "config/theme-builder";
export const GENERATE_VISITOR_LEAD = "dms/lead";
export const DEALERS = "/dealers";
export const LENDER_MULTIPLE = "/lender/dealer-lenders";

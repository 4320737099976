import { Button } from "@src/components/atoms/button/button.component";
import { PricingEstimatesSteps } from "../../pricing-estimates-steps/pricing-estimates-steps.component";
import "./finance-lease-pricing-drawer.scss";
export const FinanceLeasePricingDrawer = () => {
  return (
    <>
      <div className="fl-pricing-drawer-wrap">
        <div className="fl-pricing-drawer-wrap--intro">
          <h4 className="text--subsection-1">
            Get a more precise estimate for monthly payments.
          </h4>
        </div>
        <PricingEstimatesSteps
          stepCount={"01."}
          title="Unlock personalized pricing."
          description="A BMW ID is required to ensure your details and credit score are securely saved. Please sign in or register to continue."
          iconName="lock_unlocked"
          content={
            <div className="action-area--group">
              <Button htmlType={"button"} text="Sign in" type="default" />
              <Button htmlType={"button"} text="Register" />
            </div>
          }
        />
        <PricingEstimatesSteps
          stepCount={"02."}
          title="Get a more accurate APR %."
          iconName="lock_locked"
          disabled
        />
      </div>
    </>
  );
};

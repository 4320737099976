import { Button } from "@src/components/atoms";
import "./checkout-header.scss";

import Logo from "../../shared/assets/img/logo.jpg";

export const CheckoutHeader = () => {
  return (
    <header className="checkout-header">
      <div className="checkout-header-left">
        <img className="checkout-header-logo" src={Logo} alt="BMW Logo" />
        <div className="checkout-header-texts">
          <p className="text--body-1">BMW of Manhattan</p>
          <p className="text--body-2">Digital Retailing Platform</p>
        </div>
      </div>
      <div className="checkout-header-right">
        <div className="checkout-header-user-container">
          <Button
            linkButton
            withIcon
            htmlType={"button"}
            text="Need help?"
            dataIcon="request_for_contact"
          />
        </div>
        <div className="checkout-header-user-container">
          <Button
            linkButton
            withIcon
            htmlType={"button"}
            text="Christopher"
            dataIcon="person"
          />
          <i className="checkout-header-chevron-down bmw-icon bmw-icon--before"></i>
        </div>
        <Button htmlType="button" onlyIcon dataIcon="exit" type="default" />
      </div>
    </header>
  );
};

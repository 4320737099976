import React, { FC, useState, useContext, useEffect, useReducer } from 'react'
import { Divider, useTheme } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useUpdateCustomerContact } from 'hooks'
import { Box, Button, Icon, Typography, Switch, Snackbar, CircleLoader } from '@ntpkunity/controls'
import { QuestionStack } from '@libraries'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { getDealerCode } from '../../../helpers/index'
import { AppContext } from '@app/context-provider'
import AddressDetailsComponent from './address-detail'
import { IFormInputs, IResidenceDetailsComponentProps } from 'interfaces'
import { pagesActionType, useJourneyLayoutContext } from 'layouts/journey-layout/journey-layout-context-provider'
import { FinanceTab, QueryKeys } from '@constants'
import { useGetIntegrationByProviderName } from 'hooks/order-management'
import { IntegrationProvider, LenderIntegrationType } from 'constants/providers'
import {useFormContext} from "../../../pages/(order-management)/order/form-context-provider";
import {getMoveInDate, Months} from "../../../helpers/methods";
import { useOrderContext } from 'pages/(order-management)/order/order-context-provider'
import { SnackbarUtility } from '@utilities'
import { useQueryClient } from 'react-query'
import { Stack } from '@components'
import { TaggingClasses } from 'constants/enums'


export const ResidenceDetailsComponent: FC<IResidenceDetailsComponentProps> = ({ contactData, customerReferenceId, setTab, form, isGaraging, setIsGaraging }) => {


    const theme = useTheme()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders
    const { calculateQuotation } = useOrderContext()
    const company_id = appState.state.tenant
    const { mutate: getProvider} = useGetIntegrationByProviderName( IntegrationProvider.GOOGLE,
      LenderIntegrationType.ADDRESS_VALIDATION,company_id, appState?.state?.slug );
    const { dispatch: pageDispatch } = useJourneyLayoutContext()
    const { mutate: updateCustomerByReferenceId,isLoading: updateCustomerByReferenceIdIsLoading } = useUpdateCustomerContact()
    const [isAddressDirty, setIsAddressDirty] = useState(false)
    const [isgaragingVerified, setIsGaragingVerified] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    const [billingVerified, setBillingVerified] = useState(false)
    const [dealerCode, setDealerCode] = useState(null)
    const {formStates, resetFormState} = useFormContext()
    const [isGoogleEnabled, setIsGoogleEnabled] = useState(false)
    const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
    const queryClient = useQueryClient();
    const [previous,setPrevious] = useState(false)


    const {
        handleSubmit,
        formState: { errors},
        getValues,
        setValue,
        trigger,
        clearErrors
    } = form

    const fillGargingAddress = (e: any) => {
        if (e.target.checked) {
            clearErrors('garaging_address')
            setIsGaraging(true)
            setValue("mail_address.is_garaging", true)
        } else {
            setIsGaraging(false)
            setValue("mail_address.is_garaging", false)
        }
    }

    useEffect(() => {
        if (getValues('garaging_address')?.verified) {
            setIsGaragingVerified(true)
        }
    }, [getValues('garaging_address')])
    useEffect(() => {
        if(company_id)
       { getProvider(
          {
            provider_name: IntegrationProvider.GOOGLE,
            integration_type: LenderIntegrationType.ADDRESS_VALIDATION,
            company_id:company_id
          },
          {
            onSuccess(response: any) {
              setIsGoogleEnabled(response?.is_active)
            }
          }
        )}
      }, [company_id])

    useEffect(() => {
        setDealerCode(getDealerCode())
        const prevAddress = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Previous');
        setPrevious(!!prevAddress)

    }, [contactData])

    useEffect(() => {
        const move_in_duration = getValues('mail_address')?.move_in_duration
        if(move_in_duration  && appState?.state?.min_months_stay_at_address){
            const months = Months(move_in_duration)
            setPrevious(months < appState?.state?.min_months_stay_at_address)
        }
        if (getValues('mail_address')?.verified) {
            setIsVerified(true)
        }
        if (getValues('Billing')?.verified) {
            setBillingVerified(true)
        }
    }, [getValues('mail_address'), getValues('Billing')])

    const onSubmit = (data: IFormInputs) => {
        if(!isGoogleEnabled){
            setValue("mail_address.verified", true)
            setValue("Billing.verified", true)
            setValue("prev_address.verified", true)
        }

        if( !getValues('mail_address.is_garaging')){
            trigger('garaging_address')
        }
        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Saving residence details'))
        if(!previous)
            setValue('prev_address', null);
        const addresses = []
        const mailAddress = getValues('mail_address')
        const PrevAddress = getValues('prev_address')
        const garagingAddress = getValues('garaging_address')
        const carParkingAddress = getValues('Billing')
        if (mailAddress) {
            mailAddress.address_type = 'Mailing'
            if (!dealerCode) {
                mailAddress.verified = false
            }
            addresses.push({
                ...mailAddress,
                move_in_date: mailAddress?.move_in_duration
                    ? getMoveInDate(mailAddress?.move_in_duration)
                    : null,
                is_garaging: isGaraging,
            })

        }

        if (garagingAddress && !isGaraging) {
            garagingAddress.address_type = 'Garaging'
            if (!dealerCode) {
                garagingAddress.verified = false
            }
            addresses.push({
                ...garagingAddress
            })
        }

        if (carParkingAddress) {
            carParkingAddress.address_type = 'Billing'
            if (!dealerCode) {
                carParkingAddress.verified = false
            }
            addresses.push({
                ...carParkingAddress
            })
        }
        if (PrevAddress) {
            PrevAddress.address_type = 'Previous'
            if (!dealerCode) {
                PrevAddress.verified = false
            }
            addresses.push({
                ...PrevAddress,
                move_in_date: PrevAddress?.move_in_duration
                ? getMoveInDate(PrevAddress?.move_in_duration)
                : null,
            })
        }

        const newData = {
            customer_addresses: addresses
        }

        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Updating residence details')),
        setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 8000);


        updateCustomerByReferenceId(

            {
                ...newData,
                reference_id: customerReferenceId
            },
            {
                async onSuccess ()  {
                    resetFormState('residence')
                    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Residence details saved')),
                    await queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_BY_EMAIL);
                    calculateQuotation()

                    setTab(FinanceTab['License-Details'])
                    pageDispatch({
                        type: pagesActionType.UPDATE_RESIDENCE_DETAILS,
                        payload: {
                            success: true,
                            error: false
                        }
                    })

                }
            }

        )
    }

    useEffect(() => {
        if (Object.keys(errors).length) {
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Error while residence details saving')),
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); });

            pageDispatch({
                type: pagesActionType.UPDATE_RESIDENCE_DETAILS,
                payload: {
                    success: false,
                    error: true
                }
            })
        }
    }, [errors])

    return (
        <>
            <Stack paddingMd={0} paddingXs={0}>
            <QuestionStack
                title={<>
                    <Box theme={theme} className='testing' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography theme={theme} className='title' component={'h4'} variant='h4'>{PLACEHOLDERS.FINANCING_CURRENT_RESIDENCE_QUESTION}</Typography>
                        {isVerified && isGoogleEnabled ? (
                            <Box theme={theme} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Typography theme={theme} component={'p'} variant='body2' className='fw-medium text-muted' display={{ xs: 'none', sm: 'none', md: 'block'}}>{PLACEHOLDERS.FINANCING_ADDRESS_VERIFIED}</Typography>
                                <Icon name="GreenCheckIcon" />
                            </Box>
                        ) : null}
                    </Box>
                </>}
                description={<>
                    <AddressDetailsComponent
                        form={form}
                        isGoogleEnabled={isGoogleEnabled}
                        dealerCode={dealerCode}
                        addressValue={'mail_address'}
                        setIsAddressDirty={setIsAddressDirty}
                        moveIn={true}
                        errorProps={errors.mail_address}
                    />
                </>}
                dColumn
            />
            {previous ? (
                <>
                <Box theme={theme} pl={3} pr={3}>
                    <Divider />
                </Box>
                    <QuestionStack
                        title={<>
                            <Box theme={theme} className='testing' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography theme={theme} className='title' component={'h4'} variant='h4'>{PLACEHOLDERS.PREVIOUS_ORDER}</Typography>
                                {isVerified && isGoogleEnabled? (
                                    <Box theme={theme} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Typography theme={theme} component={'p'} variant='body2' className='fw-medium text-muted' display={{ xs: 'none', sm: 'none', md: 'block' }}>{PLACEHOLDERS.FINANCING_ADDRESS_VERIFIED}</Typography>
                                        <Icon name="GreenCheckIcon" />
                                    </Box>
                                ) : null}
                            </Box>
                        </>}
                        description={<>
                            <AddressDetailsComponent
                                form={form}
                                isGoogleEnabled={isGoogleEnabled}
                                dealerCode={dealerCode}
                                addressValue={'prev_address'}
                                setIsAddressDirty={setIsAddressDirty}
                                moveIn={true}
                                errorProps={errors.prev_address}
                            />
                        </>}
                        dColumn
                    />
                </>
                ) : null}
            </Stack>
            <QuestionStack
                marginTopMd={3}
                marginTopXs={3}
                title={<>
                    <Box theme={theme} className='testing' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography theme={theme} className='title' component={'h4'} variant='h4'>{PLACEHOLDERS.FINANCING_BILLING_ADDRESS_QUESTION}</Typography>
                        {billingVerified && isGoogleEnabled ? (
                            <Box theme={theme} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Typography theme={theme} component={'p'} variant='body2' className='fw-medium text-muted' display={{ xs: 'none', sm: 'none', md: 'block'}}>{PLACEHOLDERS.FINANCING_ADDRESS_VERIFIED}</Typography>
                                <Icon name="GreenCheckIcon" />
                            </Box>
                        ) : null}
                    </Box>
                </>}
                description={<AddressDetailsComponent
                    form={form}
                    isGoogleEnabled={isGoogleEnabled}
                    dealerCode={dealerCode}
                    addressValue={'Billing'}
                    setIsAddressDirty={setIsAddressDirty}
                    moveIn={false}
                    errorProps={errors.Billing}
                />
                }
                dColumn
            />
            <QuestionStack
                marginTopXs={3}
                title={<>
                    <Box theme={theme} className='testing' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography theme={theme} className='title' component={'h4'} variant='h4' >{PLACEHOLDERS.FINANCING_CAR_PARKING_LOCATION_QUESTION}</Typography>
                        {!isGaraging && isgaragingVerified && isGoogleEnabled? (
                            <Box theme={theme} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Typography theme={theme} component={'p'} variant='body2' className='fw-medium text-muted' display={{ xs: 'none', sm: 'none', md: 'block'}}>{PLACEHOLDERS.FINANCING_ADDRESS_VERIFIED}</Typography>
                                <Icon name="GreenCheckIcon" />
                            </Box>
                        ) : null}
                    </Box>
                </>}
                actionArea={<><Switch theme={theme} label={PLACEHOLDERS.FINANCING_SAME_AS_CURRENT_ADDRESS} switchEnabled={isGaraging} onChange={(e: any) => fillGargingAddress(e)} varient='basic' /></>}
                description=
                {!isGaraging ? (
                    <AddressDetailsComponent
                        form={form}
                        isGoogleEnabled={isGoogleEnabled}
                        dealerCode={dealerCode}
                        addressValue={'garaging_address'}
                        setIsAddressDirty={setIsAddressDirty}
                        moveIn={false}
                        errorProps={errors.garaging_address}
                    />
                ) : null}
                dColumn
            />
            {formStates?.['residence']?.isDirty || isAddressDirty ? (
                <Box theme={theme} mt={3}>
                    <Button
                    className={`${TaggingClasses.FINANCING}-residence-save`} 
                    theme={theme} 
                    primary 
                    type="submit"
                    disabled={updateCustomerByReferenceIdIsLoading}
                    text={
                    <>
                    {updateCustomerByReferenceIdIsLoading && <CircleLoader theme={theme} size="xs" />}
                    {PLACEHOLDERS.FINANCING_SAVE_AND_CONTINUE}

                    </>}
                    onClick={handleSubmit(onSubmit)} fullWidth />
                </Box>
            ) : null}
            <Snackbar
            theme={theme}
            message={<Box theme={theme} display={'flex'}>
            <Box theme={theme} width="30px">
            <CircleLoader theme={theme} size='xs' />
            </Box> {snackbarState.message}
        </Box> }
            open={snackbarState.open}
            onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
        />
        </>
    )
};

import { Button, FeedbackLabel, Image } from "@src/components/atoms";
import "./deals-info.scss";
import { Col, Row } from "antd";
import Vehicle from "../../../../shared/assets/img/dr-start-vehicle.png";
import { useState } from "react";
import { RemoveDealModal } from "../remove-deal-modal/remove-deal-modal.component";

const dealsData = [
  {
    id: 1,
    model: "2024 Lorem Ipsum Model",
    vin: "WBA7E2C51JG741556",
    status: "[Status]",
    statusType: "success",
    lease: "$0,000.00",
    price: "$000,000.00",
    term: "00 months",
    dueAtSigning: "$000,000",
    lastUpdated: "--/--/--",
    disable: false,
  },
  {
    id: 2,
    model: "2024 Lorem Ipsum Model",
    vin: "WBA7E2C51JG741556",
    status: "[Status]",
    statusType: "success",
    lease: "$0,000.00",
    price: "$000,000.00",
    term: "00 months",
    dueAtSigning: "$000,000",
    lastUpdated: "--/--/--",
    disable: false,
  },
  {
    id: 3,
    model: "2024 Lorem Ipsum Model",
    vin: "WBA7E2C51JG741556",
    status:
      "Apologies, this vehicle has been sold. Browse additional inventory or contact your dealer for more support.",
    statusType: "error",
    lease: "$0,000.00",
    price: "$000,000.00",
    term: "00 months",
    dueAtSigning: "$000,000",
    lastUpdated: "--/--/--",
    disable: true,
  },
];

export const DealsInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <div className="deals-info">
        <Row gutter={[24, { xs: 64, sm: 48, md: 48, lg: 32, xl: 64 }]}>
          {dealsData.map((deal) => (
            <Col key={deal.id} xs={24} md={12} lg={24}>
              <Row gutter={[24, 24]} align="middle">
                <Col xs={24} lg={8}>
                  <div className="deals-info__vehicle">
                    <div className={deal.disable ? "disbale" : "enable"}>
                      <Image src={Vehicle} alt="image" aspectRatio="16:9" />
                    </div>
                    <div className="deals-info-vehicle__inner">
                      <h6 className="text--body-1">{deal.model}</h6>
                      <p className="text--label text-muted deal-info__number">
                        VIN: {deal.vin}
                      </p>
                      <FeedbackLabel
                        type={
                          deal.statusType as
                            | "success"
                            | "warning"
                            | "error"
                            | "primary"
                        }
                        text={deal.status}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} lg={16}>
                  <Row
                    gutter={[24, 24]}
                    align="middle"
                    className="deals-info__border"
                  >
                    <Col xs={24} xl={16}>
                      <ul className="deals-info__price">
                        <li>
                          <p className="text--body-2 text-muted">
                            Estimated lease
                          </p>
                          <h6 className="text--body-1 fw-bold">
                            {deal.lease}
                            <span className="fw-normal">/month</span>
                          </h6>
                        </li>
                        <li>
                          <p className="text--body-2 text-muted">Your price</p>
                          <h6 className="text--body-1 fw-bold ">
                            {deal.price}
                          </h6>
                        </li>
                        <li>
                          <p className="text--body-2 text-muted">Term</p>
                          <h6 className="text--body-1 fw-bold">{deal.term}</h6>
                        </li>
                        <li>
                          <p className="text--body-2 text-muted">
                            Due at signing
                          </p>
                          <h6 className="text--body-1 fw-bold">
                            {deal.dueAtSigning}
                          </h6>
                        </li>
                      </ul>
                    </Col>
                    <Col xs={24} xl={8}>
                      <div className="deal-info__cta">
                        <div className="text-center">
                          <Button
                            type="default"
                            htmlType={"button"}
                            text="Continue building deal"
                          />
                          <p className="text--label text-muted">
                            Last updated: {deal.lastUpdated}
                          </p>
                        </div>
                        <Button
                          type="link"
                          htmlType={"button"}
                          onClick={showModal}
                          onlyIcon
                          dataIcon="trash_can"
                        ></Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </div>
      <RemoveDealModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { http } from "../utilities";
import { AppError } from "single-spa";
import {
  CONFIG_BASE_URL,
  EP_DMS_GET_STATES,
  CONFIG_DEALER_PROFILE,
} from "../constants/routes";
import { State } from "@src/types";

export const useGetDealerProfile = (): {
  mutate: UseMutateFunction<any, AppError, { dealerCode: number }>;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<any, AppError, { dealerCode: number }>({
    mutationFn: async ({ dealerCode }) => {
      const response = await http(CONFIG_BASE_URL).get(
        `${CONFIG_DEALER_PROFILE}/${dealerCode}`
      );
      return response.data;
    },
  });
  return { mutate, error: error?.message };
};

export const useGetStates = (): any => {
  const { mutate, error } = useMutation<State[], AppError>({
    mutationFn: async () => {
      const response = await http(CONFIG_BASE_URL).get(EP_DMS_GET_STATES);
      return response.data;
    },
  });
  return { mutate, error: error?.message };
};

import "./insurance-information.scss";
import { Col, Row } from "antd";
import { Phone } from "../../atoms/phone-input/phone-input.component";
import { AutoComplete } from "@src/components/atoms/autocomplete/autocomplete.component";
import { useGetInsuranceProvider } from "@src/hooks";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { updateInsuraneProviderOptions } from "@src/context/app-actions";
import { useModal } from "react-modal-hook";
import { useAppContext } from "@src/context/app-context";
import { NoInsuranceModal } from "@src/components/molecules";

export const InsuranceInfo = ({ form }: any) => {
  const {
    state: {
      tenant: { company_id },
      order,
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { watch, control, setValue } = form;
  const { company_provider } = order;
  const [showModal, hideModal] = useModal(
    () => <NoInsuranceModal hide={hideModal} />,
    []
  );

  const handleSelect = (value: string) => {
    const selectedOption = company_provider.find(
      (option) => option.insurer_name === value
    );
    if (selectedOption) {
      setValue("agent_phone", selectedOption.agent_phone);
      setValue("insurer_name", selectedOption.insurer_name);
    }
  };

  useEffect(() => {
    if (!watch("insurer_name")) {
      setValue("agent_phone", null);
    }
  }, [watch("insurer_name")]);

  return (
    <div className="insurance-information">
      <div className="insurance-information-info-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            02
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Insurance information.
          </h3>
        </div>
      </div>
      <div className="insurance-information-title-container">
        <span className="insurance-information-description text--body-2">
          State and federal regulations require all drivers to be insured.
        </span>
        <span
          className="insurance-information-description--link text--body-2"
          onClick={() => showModal()}
          role="button"
          tabIndex={0}
        >
          I don’t have insurance
        </span>
      </div>
      <div className="insurance-information-input-container">
        <div className="insurance-information-input-wrapper">
          <Row gutter={[16, { xs: 20, sm: 20, md: 20 }]}>
            <Col xs={24} sm={24} md={12} lg={24} xl={12}>
              <Controller
                name="insurer_name"
                control={control}
                rules={{ required: "Insurance provider is required" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <AutoComplete
                      options={company_provider.map((option) => ({
                        value: option.insurer_name,
                        label: option.insurer_name,
                      }))}
                      placeholder="Select an option"
                      label="Insurance provider's name"
                      onSelect={(newValue) => {
                        handleSelect(newValue);
                      }}
                      error={error}
                      filterOption={(inputValue, option) =>
                        option!.value
                          .toUpperCase()
                          .includes(inputValue.toUpperCase())
                      }
                      {...field}
                    />
                  </>
                )}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={24} xl={12}>
              <Controller
                name="agent_phone"
                control={control}
                render={({ field, fieldState }) => (
                  <Phone
                    country="us"
                    label="Insurance contact number"
                    placeholder=""
                    formState={fieldState}
                    {...field}
                  />
                )}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

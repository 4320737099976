import { Button as AntButton } from "antd";
import clsx from "clsx";
import { ButtonWrap } from "./button.style";
import { ButtonProps } from "./button.props";

export const Button = ({
  id,
  className,
  text,
  type,
  size,
  block,
  icon,
  danger,
  onDark,
  disabled,
  htmlType,
  btnDefaultOutline,
  fixed,
  onClick,
  linkButton,
  withIcon,
  dataIcon,
  isIconEnd,
  onlyIcon,
  fullWidth,
  isLoading,
  loadingIconPosition,
}: ButtonProps) => {
  return (
    <ButtonWrap
      className={clsx({
        "button-wrap": true,
        "button-wrap--full-width": fullWidth === true,
      })}
    >
      <AntButton
        className={clsx(className, {
          button: true,
          "button--btn-outline": btnDefaultOutline,
          "button--btn-fixed": fixed,
          "button--btn-small": size === "small",
          "button--btn-disabled": disabled || isLoading,
          "button--btn-secondary": type === "default",
          "button--link-button": linkButton,
          "icon--after-text": isIconEnd === true,
          "button--btn-dark": onDark === true,
          "button--only-icon": onlyIcon === true,
          "button--full-width": fullWidth === true,
        })}
        id={id}
        type={type}
        size={size}
        block={block}
        icon={icon}
        disabled={disabled}
        danger={danger}
        htmlType={htmlType}
        onClick={onClick}
        loading={isLoading}
        iconPosition={loadingIconPosition}
      >
        {withIcon && !onlyIcon && (
          <div
            className="bmw-icon bmw-icon--before data-icon"
            data-icon={dataIcon}
          />
        )}
        {onlyIcon && !isLoading && (
          <div
            className="bmw-icon bmw-icon--before data-icon"
            data-icon={dataIcon}
          />
        )}
        {!onlyIcon && <span className="text--cta-2">{text}</span>}
      </AntButton>
    </ButtonWrap>
  );
};
